import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { ApplicationState } from 'types';
import entities from './entities';
import funeralSites from './funeralSites';
import graveTypes from './graveTypes';
import products from './products';
import proposal from './proposal';
import services from './services';
import tracking from './tracking';

export default (history: History) =>
  combineReducers<ApplicationState>({
    entities,
    funeralSites,
    graveTypes,
    products,
    proposal,
    router: connectRouter(history),
    services,
    tracking,
  });
