import { fetchFuneralSite } from 'actions/funeralSites';
import { Text, Card, Hr } from 'components';
import Collapse from 'components/Collapse';
import config from 'config';
import { googleMap } from 'data';
import { useCMSSelector, useDispatch, useSelector } from 'hooks';
import { isEmpty, take } from 'lodash';
import { cdn, styled, mq } from 'mymoria-ui/utils';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { FuneralSite, iconTypes, FuneralType } from 'types';
import FuneralSiteItem from './FuneralSiteItem';

const {
  googleMaps: { recommendationStaticIcons: staticIcons },
} = config;

interface Props {
  funeralType: FuneralType;
  originFuneralSite?: string;
  handleFuneralSiteChange: (iconType: iconTypes, funeralSite?: FuneralSite) => void;
}

const FuneralGrid = styled.div`
  display: table;
`;

const StyledCollapse = styled(Collapse)`
  && {
    margin-bottom: 0rem;

    & > div:not(:first-of-type) {
      img {
        width: 20px;
        height: 20px;
        padding: 0 0.5rem;
      }
    }

    & > div:nth-of-type(3) {
      margin-top: 1.2rem;

      ${mq({
        display: ['none', 'flex'],
      })}
    }
  }
`;

const StyledCard = styled(Card)`
  && {
    padding: 0.5rem;
    ${mq({ margin: ['0.5rem 0 0', '1rem 0 0'] })};
  }
`;

const RecommendationBox: FunctionComponent<Props> = ({
  handleFuneralSiteChange,
  funeralType,
  originFuneralSite,
}) => {
  const funeralSiteEntities = useSelector(({ entities }) => entities.funeralSites);
  const dispatch = useDispatch();
  const [selectedFuneralSite, setSelectedFuneralSite] = useState<string | iconTypes>();
  const { Locations } = useCMSSelector(({ FuneralSites }) => FuneralSites[funeralType]);

  useEffect(() => {
    if (!originFuneralSite) {
      setSelectedFuneralSite('nearBy');
    }
  }, [originFuneralSite]);

  return (
    <StyledCard>
      <StyledCollapse
        isInitiallyCollapsed
        trigger={
          <Text textAlign="center" fontWeight="extraBold">
            {googleMap.recommendationBox.title}
          </Text>
        }
      >
        <FuneralGrid>
          {staticIcons
            .filter(item => item.funeralType === funeralType)
            .map(({ id, title, picture }) => (
              <FuneralSiteItem
                key={`icon-${id}`}
                onClick={() => {
                  setSelectedFuneralSite(id);
                  handleFuneralSiteChange(id);
                }}
                title={title}
                selected={selectedFuneralSite === id}
                staticIcon
                picture={picture}
              />
            ))}
          {(funeralType === 'tree' ? take(Locations, 2) : Locations).map(
            (
              { value: { Headline, Tag, FuneralSiteId, TeaserImage: { path = '' } = {} } }: any,
              index: any,
            ) => {
              return (
                <FuneralSiteItem
                  title={Headline}
                  tag={Tag}
                  picture={cdn('cms', path)}
                  key={index}
                  onClick={async () =>
                    handleFuneralSiteChange(
                      null,
                      isEmpty(funeralSiteEntities[FuneralSiteId])
                        ? await dispatch(fetchFuneralSite(FuneralSiteId))
                        : funeralSiteEntities[FuneralSiteId],
                    )
                  }
                />
              );
            },
          )}
        </FuneralGrid>
        <Hr my={[1, 1]} />
      </StyledCollapse>
    </StyledCard>
  );
};

export default React.memo<FunctionComponent<Props>>(RecommendationBox);
