import { fetchProduct } from 'actions/products';
import { NotFound, ProductDetails, Section, DataRowProps } from 'components';
import config from 'config';
import * as t from 'data';
import { formatPrice } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { Grid } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page, templates } from 'templates';
import { categoriesMap } from './utils';

interface URLParams {
  id: string;
  category: string;
}

type ProductProperty = 'color' | 'size' | 'weight';

type SupportedProperties = {
  name: ProductProperty;
  label: string;
};

const supportedProperties: SupportedProperties[] = [
  { label: t.global.productDetails.color, name: 'color' },
  { label: t.global.productDetails.size, name: 'size' },
  { label: t.global.productDetails.weight, name: 'weight' },
];

const CatalogProductDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams<URLParams>();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const product = useSelector(({ entities }) => entities.products[params.id]);
  const category = categoriesMap[params.category];

  useEffect(() => {
    if (!product || !product.translations.properties) {
      setIsLoading(true);
      dispatch(fetchProduct(params.id, config.defaultPricebook))
        .catch(() => {
          addToast(t.errors.general);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [product, params.id, dispatch, addToast]);

  const details: DataRowProps[] = [];

  supportedProperties.forEach(({ name, label }) => {
    if ((product?.translations.properties || {})[name]) {
      details.push({ label, value: (product.translations.properties || {})[name] });
    }
  });
  const goBackUrl = `/katalog/${params.category}`;

  if (product?.articleNumber) {
    details.push({
      label: t.global.products.articleNumber,
      value: product.articleNumber,
    });
  }

  const placeholder = product?.placeholder ? [product.placeholder] : [];

  return (
    <Page {...templates.catalogPageTemplate} isLoading={isLoading} withHeader={false}>
      <Section
        onGoBack={
          category &&
          handlePush(goBackUrl, {
            restoreScrollPosition: true,
          })
        }
        goBackLabel={category && t.global.backToCategory[category]}
      >
        {isError || !category || !product ? (
          <NotFound />
        ) : (
          <Grid padding={['0', null, '0 24px', '0 32px']}>
            <ProductDetails
              name={product.translations.shortname}
              price={formatPrice(product.price, { gross: false })}
              description={product.translations.description}
              pictures={product.pictures.length > 0 ? product.pictures : placeholder}
              details={details}
              detailsLabel={t.global.products.productDetails}
            />
          </Grid>
        )}
      </Section>
    </Page>
  );
};

export default CatalogProductDetails;
