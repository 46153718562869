import { Product, Service } from 'types';
import { ProductItem, ServiceItem } from 'types/api';

/**
 * Parse items returned by API along redux store and application logic.
 *
 * @param item ProductItem | ServiceItem
 * @returns Product | Service
 */
export const parseItems = (item: ProductItem | ServiceItem): Product | Service => {
  const { sku, id, ...rest } = item;

  return {
    ...rest,
    id: sku,
    product2Id: id,
  };
};
