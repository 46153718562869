import { trackUserInteraction } from 'actions/tracking';
import { CustomOfferItemPreviewV2, Icon } from 'components';
import * as t from 'data';
import { formatPrice } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { isOfferEditable } from 'reducers/proposal';

const BasicProductPreview: FunctionComponent<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const isEditable = useSelector(isOfferEditable());
  const selectedProduct = useSelector(({ entities }) => entities.products[id]);

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Product', selectedProduct.translations.shortname));
    push(`${url}/products/${selectedProduct.category}`);
  };

  if (!selectedProduct) {
    return null;
  }

  return (
    <CustomOfferItemPreviewV2
      id={id}
      key={id}
      selected={isEditable}
      isEditable={isEditable}
      name={selectedProduct.translations.shortname}
      price={selectedProduct.price}
      priceFormatter={formatPrice}
      image={selectedProduct.pictures[0] || selectedProduct.placeholder}
      onClick={isEditable ? handleEdit : undefined}
      description={selectedProduct.translations.description}
      quantity={selectedProduct.quantity}
      actionLabel={t.global.labels.edit}
      actionIcon={<Icon.Pencil />}
    />
  );
};

export default BasicProductPreview;
