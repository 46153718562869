import { styled, mq } from 'mymoria-ui';
import { Flex, Heading, Text } from 'mymoria-ui/components';
import theme from 'mymoria-ui/styles/theme';
import React, { ReactNode } from 'react';
import CurrentScreenIndicator from './CurrentScreenIndicator';

const { space } = theme;

interface AnimationScreenProps {
  headline: string;
  description: string;
  asset: string;
  element?: ReactNode;
  indicator?: {
    currentIndex: number;
    screensCount: number;
  };
}

const AnimationScreen = ({
  headline,
  description,
  asset,
  indicator,
  element,
}: AnimationScreenProps) => (
  <Container
    flex={1}
    margin={['unset', null, '0 auto auto']}
    maxWidth={1070}
    flexDirection={['column', null, 'row-reverse']}
    overflow="hidden"
  >
    <Flex
      flex={[2, 6, 3]}
      flexDirection="column"
      margin={[0, null, `${space[4]} ${space[7]} ${space[7]} 0`]}
      minHeight={['unset', null, 350]}
    >
      <Image src={asset} alt={`${headline}-image`} />
      {indicator && (
        <CurrentScreenIndicator
          length={indicator.screensCount}
          currentIndex={indicator.currentIndex}
          mt={7}
          display={['none', null, 'flex']}
        />
      )}
    </Flex>
    <Flex
      flex={[1, null, 2]}
      width={[1, null, 1 / 2]}
      flexDirection="column"
      alignItems={['center', 'unset']}
      justifyContent={['unset', null, 'center']}
      my={[4, 5]}
      px={[3, 6, 13]}
    >
      <Heading.Heading3 fontWeight="extraBold" mb="small" textAlign={['center', 'unset']}>
        {headline}
      </Heading.Heading3>
      <Text mb={[4, 3]} textAlign={['center', 'unset']}>
        {description}
      </Text>
      {element}
      {indicator && (
        <CurrentScreenIndicator
          length={indicator.screensCount}
          currentIndex={indicator.currentIndex}
          mt={7}
          display={['flex', null, 'none']}
        />
      )}
    </Flex>
  </Container>
);

const Container = styled(Flex)(({ theme: { colors, radii } }) => ({
  background: colors.secondary,
  borderRadius: radii[0],
  boxShadow: '0 2px 28px 0 rgba(0, 0, 0, 0.17)',
}));

const Image = styled.img(
  ({ theme: { radii } }) =>
    mq({
      borderRadius: ['unset', null, radii[1]],
      minHeight: ['100%', null, '80%'],
    }),
  {
    flexGrow: 1,
    height: 0,
    objectFit: 'cover',
  },
);

export default AnimationScreen;
