import { fetchGraveTypes } from 'actions/graveTypes';
import { setFunnelData, fetchProposalAdditionalData, createProposal } from 'actions/proposal';
import { trackFunnelData, trackCreationError } from 'actions/tracking';
import { AnimationLoader, AnimationScreen } from 'components/FunnelAnimationScreen';
import PrefetchImage from 'components/PrefetchImage';
import * as t from 'data';
import { contactDetailsSchema, offerParamsSchema, getQueryParams } from 'helpers';
import { useDispatch, useSelector, useTrackingLocation } from 'hooks';
import { concat, isEmpty, map } from 'lodash';
import { mq, styled } from 'mymoria-ui';
import { Button, Flex } from 'mymoria-ui/components';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { ContactDetails, Proposal } from 'types';
import { sprintf, parseDataLayerError, isAxiosError } from 'utils';
import { mixed } from 'yup';

const NUM_OF_SCREENS = t.funnelWaitingScreens.steps.length;

const Page = styled(Flex)(
  ({ theme: { space } }) =>
    mq({
      padding: [space[3], space[6], `${space[7]} ${space[6]}`],
    }),
  {
    background: 'linear-gradient(to bottom, #edd5db, rgba(255, 245, 219, 0))',
    flexGrow: 1,
  },
);

const PrimaryButton = styled(Button)(({ theme: { fontSizes, fontWeights, colors } }) => [
  {
    '&&': {
      '&:hover': {
        background: 'linear-gradient(90deg, #c3cbd9, #a5aebf)',
        color: colors.secondary,
      },
      background: 'linear-gradient(90deg, #c8d9f0 -99%, #153255 94%)',
      borderRadius: 35,
      fontSize: fontSizes.large,
      fontWeight: fontWeights.bold,

      height: 74,
    },
  },
  mq({ width: ['100%', 'fit-content'] }),
]);

interface LocationState {
  fromFunnel?: boolean;
  contactDetails?: ContactDetails;
}

const OfferCreator: FunctionComponent<RouteComponentProps<{}, {}, LocationState>> = ({
  history,
  location: { search, state },
}) => {
  useTrackingLocation(t.global.animation.title);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [isOfferLoaded, setIsOfferLoaded] = useState(false);
  const { current: isFromFunnel } = useRef(!!state?.fromFunnel);
  const { current: funnelContactDetails } = useRef(state?.contactDetails);
  const id = useSelector(({ proposal }) => proposal.id);
  const [currentScreenIndex, setCurrentSScreenIndex] = useState(0);
  const isLastScreen = currentScreenIndex === NUM_OF_SCREENS;

  const handleScreenButtonClick = () => setCurrentSScreenIndex(prev => prev + 1);

  const createOffer = useCallback(
    (contactDetails?: ContactDetails) =>
      dispatch(fetchProposalAdditionalData())
        .then(({ payload }) => {
          const {
            entities: { services, products, funeralSites },
            result: { proposal },
          } = payload;

          const items = concat(
            [],
            map(
              proposal.basicServices,
              id => `${services[id].translations.shortname} für ${proposal.city}`,
            ),
            map(proposal.optionalServices, id => services[id].translations.shortname),
            map(proposal.basicProducts, id => t.global.categoryList[products[id].category]),
            map(proposal.optionalProducts, id => t.global.categoryList[products[id].category]),
          );

          if (funeralSites && funeralSites[proposal.funeralSite]) {
            items.push(
              sprintf(t.global.animation.funeralSiteTitle, {
                title: funeralSites[proposal.funeralSite].title,
              }),
            );
          }

          if (!isEmpty(contactDetails) && contactDetails) {
            return Promise.all([
              dispatch(createProposal(contactDetails, proposal.salesforce?.pricebookId || '')),
              dispatch(fetchGraveTypes()),
            ]);
          }
        })
        .catch(error => {
          if (isAxiosError(error)) {
            dispatch(trackCreationError(parseDataLayerError(error)));
          }
          setIsError(true);
        })
        .finally(() => setIsOfferLoaded(true)),
    [dispatch],
  );

  useEffect(() => {
    if (isOfferLoaded && !isError) {
      history.replace(`/offer/${id || 'create'}`);
    }
  }, [history, isOfferLoaded, id, isError]);

  useEffect(() => {
    if (currentScreenIndex !== 0) {
      return;
    }

    if (isFromFunnel) {
      createOffer(funnelContactDetails);
    } else {
      try {
        const funnelParams = getQueryParams<Proposal>(search, offerParamsSchema);
        const contactDataParams = getQueryParams<ContactDetails>(
          search,
          contactDetailsSchema.shape({ terms: mixed().strip(true) }),
        );

        /* it's temporary solution until the new funnel is added */
        if (funnelParams.funeralSite && funnelParams.funeralPlan) {
          Object.assign(funnelParams, {
            graveType: funnelParams.funeralPlan === 'basic' ? 'SU' : 'SB',
          });
        }

        dispatch(setFunnelData({ ...funnelParams, ...contactDataParams }));
        dispatch(trackFunnelData({ ...funnelParams, ...contactDataParams }));

        createOffer(contactDataParams);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error.message);
        setIsError(true);
      }
    }
  }, [
    dispatch,
    isFromFunnel,
    history,
    search,
    funnelContactDetails,
    createOffer,
    currentScreenIndex,
  ]);

  return (
    <Page>
      {isError && (
        <AnimationScreen
          {...t.funnelWaitingScreens.error}
          element={
            <PrimaryButton
              key="primary-button-error"
              block
              variant="secondary"
              onClick={() => window.location.reload()}
            >
              {t.funnelWaitingScreens.error.buttonLabel}
            </PrimaryButton>
          }
        />
      )}
      {!isError &&
        (isLastScreen ? (
          <AnimationScreen {...t.funnelWaitingScreens.loading} element={<AnimationLoader />} />
        ) : (
          <AnimationScreen
            {...t.funnelWaitingScreens.steps[currentScreenIndex]}
            key={`primary-button-${currentScreenIndex}`}
            element={
              <PrimaryButton block variant="secondary" onClick={handleScreenButtonClick}>
                {t.funnelWaitingScreens.steps[currentScreenIndex].buttonLabel}
              </PrimaryButton>
            }
            indicator={{ currentIndex: currentScreenIndex, screensCount: NUM_OF_SCREENS }}
          />
        ))}
      {currentScreenIndex + 1 < NUM_OF_SCREENS && (
        <PrefetchImage
          alt={`AnimationScreenImage-${currentScreenIndex + 1}`}
          src={t.funnelWaitingScreens.steps[currentScreenIndex + 1].asset}
        />
      )}
    </Page>
  );
};

export default OfferCreator;
