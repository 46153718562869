import { CustomOfferItemPreviewV2 } from 'components';
import { formatPrice } from 'helpers';
import { useBasicServices, useSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { isOfferEditable } from 'reducers/proposal';

const BasicServicePreview: FunctionComponent = () => {
  const basicService = useBasicServices();
  const isEditable = useSelector(isOfferEditable());

  if (!basicService) {
    return null;
  }

  return (
    <CustomOfferItemPreviewV2
      id={basicService.id}
      selected={isEditable}
      isEditable={false}
      name={basicService.translations.shortname}
      image={basicService.pictures[0] || basicService.placeholder}
      price={basicService.price}
      priceFormatter={formatPrice}
      description={basicService.translations.description}
      quantity={basicService.quantity}
    />
  );
};

export default BasicServicePreview;
