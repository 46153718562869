import { History } from 'history';
import React, { useLayoutEffect, useCallback, ReactNode } from 'react';

export interface StepProps<T = string> {
  step: T;
  steps: T[];
  next: () => void;
  back: () => void;
  push: (id: T) => void;
  replace: (id: T) => void;
  onStepLoad: (...args: any) => any;
}

interface Props<T extends string> {
  steps: T[];
  basename: string;
  history: History;
  onStepLoad?: (args: Omit<StepProps<T>, 'onStepLoad'>) => any;
  children: (props: StepProps<T>) => ReactNode;
  fallback: string;
}

const isStep = <T extends string>(name: string, steps: readonly T[]): name is T => {
  return steps.includes(name as any);
};

const StepWizard = <T extends string>({
  steps,
  basename,
  history,
  children,
  onStepLoad,
  fallback,
}: Props<T>) => {
  const stepFromHistory = history.location.pathname.replace(`${basename}/`, '');
  const currentStep = isStep(stepFromHistory, steps) ? stepFromHistory : null;

  const push = (id: string) => {
    history.push(`${basename}/${id}`);
  };

  const next = () => {
    const nextStep = steps[steps.findIndex(step => step === currentStep) + 1] || fallback;
    push(nextStep);
  };

  const back = () => {
    const previousStep = steps[steps.findIndex(step => step === currentStep) - 1] || fallback;
    push(previousStep);
  };

  const replace = useCallback(
    (id: string) => history.replace(`${basename}/${id}`),
    [basename, history],
  );

  const onStepLoadHandler = () => {
    if (!onStepLoad) return undefined;

    return onStepLoad({ back, next, push, replace, step: currentStep as T, steps });
  };

  useLayoutEffect(() => {
    if (!currentStep) {
      replace(fallback);
    }
  }, [basename, currentStep, fallback, history, replace, stepFromHistory, steps]);

  const renderChildren = (args: StepProps<T>) => {
    if (typeof children === 'function') {
      return children(args);
    }

    return null;
  };

  return (
    <>
      {currentStep
        ? renderChildren({
            back,
            next,
            onStepLoad: onStepLoadHandler,
            push,
            replace,
            step: currentStep,
            steps,
          })
        : null}
    </>
  );
};

export default StepWizard;
