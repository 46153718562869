import { changeContactUsInformation } from 'actions/proposal';
import { Heading, ActionButton, Loader } from 'components';
import config from 'config';
import { FunnelButton } from 'containers/Funnel';
import { global } from 'data';
import { contactUsOptions } from 'forms/options';
import { useSelector, useDispatch, useWizard } from 'hooks';
import { useToast } from 'mymoria-ui/hooks';
import { styled, css } from 'mymoria-ui/utils';
import React, { useState, ChangeEvent } from 'react';
import { sprintf } from 'utils';
import NoteComponent from '../../NoteComponent';

const { additionalInfoMaxLength } = config;

const StyledButton = styled(FunnelButton)(
  ({ theme: { fontWeights } }) => css`
    border-radius: 12px;
    font-weight: ${fontWeights.bold};
  `,
);

const Hint = styled.aside(
  ({ theme: { colors, space, fontSizes } }) => css`
    color: ${colors.red};
    font-size: ${fontSizes.small};
    padding: ${space[1]} ${space.large};
  `,
);

interface Props {
  onBackClick: () => void;
}

const Options = ({ onBackClick }: Props) => {
  const dispatch = useDispatch();
  const { goNextPage } = useWizard();
  const { addToast } = useToast();
  const proposalAdditionalInfo = useSelector(({ proposal }) => proposal.additionalInfo);
  const proposalContactUsType = useSelector(({ proposal }) => proposal.contactUsType);

  const [formValues, setFormValues] = useState({
    contactUsType: proposalContactUsType,
    contactUsTypeError: false,
    isLoading: false,
    textarea: proposalAdditionalInfo,
    textareaError: false,
  });

  const validateTextArea = (value?: string) => (value?.length || 0) > additionalInfoMaxLength;

  const handleSubmit = () => {
    const isTextAreaError = validateTextArea(formValues.textarea);

    setFormValues({
      ...formValues,
      textareaError: validateTextArea(formValues.textarea),
    });

    if (isTextAreaError) {
      return;
    }

    if (formValues.contactUsType) {
      setFormValues({ ...formValues, isLoading: true });

      dispatch(
        changeContactUsInformation({
          additionalInfo: formValues.textarea,
          contactUsType: formValues.contactUsType,
        }),
      )
        .then(() => {
          setFormValues({ ...formValues, isLoading: false });
          goNextPage();
        })
        .catch(({ message }) => {
          setFormValues({ ...formValues, isLoading: false });
          addToast(message);
        });
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value) {
      setFormValues({ ...formValues, textarea: value, textareaError: validateTextArea(value) });
    } else {
      setFormValues({ ...formValues, textarea: '' });
    }
  };

  return (
    <>
      {formValues.isLoading && <Loader overlay />}
      <Heading.Heading3 fontWeight="extraBold" mb="2rem" textAlign="center">
        {global.contactUs.contact.optionsTitle}
      </Heading.Heading3>
      {contactUsOptions.map(({ label, value }) => (
        <StyledButton
          key={value}
          mb="small"
          selected={value === formValues.contactUsType}
          block
          onClick={() => setFormValues({ ...formValues, contactUsType: value })}
        >
          {label}
        </StyledButton>
      ))}
      {formValues.contactUsTypeError && <Hint>{global.validators.required}</Hint>}
      <NoteComponent
        rows={4}
        label={global.contactUs.contact.textAreaTitle}
        placeholder={global.contactUs.contact.textAreaPlaceholder}
        value={formValues.textarea}
        onChange={handleOnChange}
        error={sprintf(global.validators.max, { max: additionalInfoMaxLength })}
        validation={formValues.textareaError}
        maxLength={additionalInfoMaxLength}
      />
      <ActionButton mt="extraLarge" block onClick={handleSubmit}>
        {global.contactUs.contact.submit}
      </ActionButton>
      <FunnelButton mt="tiny" outline block onClick={onBackClick}>
        {global.contactUs.contact.back}
      </FunnelButton>
    </>
  );
};

export default Options;
