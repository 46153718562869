import {
  addOfferItem,
  removeOfferItem,
  removeOfferMultiSelectedItem,
  switchSeaBasicService,
} from 'actions/proposal';
import { trackUserInteraction } from 'actions/tracking';
import * as t from 'data';
import { formatPrice, multiProducts } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { pick, find } from 'lodash';
import { Icon, CustomOfferItemPreviewV2 } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { isOfferEditable } from 'reducers/proposal';
import { OfferItemType, Product } from 'types';

interface OptionalItemPreviewProps {
  id: string;
  type: OfferItemType;
}

const OptionalItemPreview: FunctionComponent<OptionalItemPreviewProps> = ({ id, type }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const isEditable = useSelector(isOfferEditable());
  const { addToast } = useToast();
  const selectedItemsIds = useSelector(
    ({ proposal }) => proposal[type === 'services' ? 'optionalServices' : 'optionalProducts'],
  );
  const allItems = useSelector(({ entities }) => entities[type]);
  const selectedItems = pick(allItems, selectedItemsIds);
  const mostRecentItem = useSelector(({ entities }) => entities[type][id]);

  // check if any of selected proposal item match with one of the defaults
  const foundSelectedOptionalItem = find(selectedItems, entity => entity.id === mostRecentItem.id);
  const item = foundSelectedOptionalItem || mostRecentItem;

  if (!item) {
    return null;
  }

  const category = type === 'services' ? undefined : (item as Product).category;
  const isSelected = !!foundSelectedOptionalItem;
  const isMultiProduct = category ? multiProducts.includes(category) : false;
  const itemIdentifier = item.id;

  const isNotLastItem =
    category &&
    Object.values(selectedItems).filter(item => (item as Product).category === category).length > 1;

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Product', item.translations.shortname));
    push(`${url}/products/${category}`);
  };

  const handleSelect = () => {
    try {
      if (funeralType === 'sea' && itemIdentifier === 'sea-addon-1') {
        return dispatch(isSelected ? switchSeaBasicService(true) : switchSeaBasicService(false));
      }

      return isSelected
        ? isNotLastItem
          ? dispatch(removeOfferMultiSelectedItem(itemIdentifier, type))
          : dispatch(removeOfferItem(itemIdentifier, type, category))
        : dispatch(addOfferItem(itemIdentifier, type));
    } catch {
      return addToast(t.errors.general);
    }
  };

  return (
    <CustomOfferItemPreviewV2
      id={itemIdentifier}
      name={item.translations.shortname}
      price={item.price}
      priceFormatter={formatPrice}
      image={item.pictures[0] || item.placeholder}
      selected={isEditable && isSelected}
      isEditable={isEditable}
      onSelect={isEditable ? handleSelect : undefined}
      onClick={isEditable && type === 'products' ? handleEdit : undefined}
      description={item.translations.description}
      actionIcon={isMultiProduct ? <Icon.Plus /> : <Icon.Pencil />}
      actionLabel={
        category && isMultiProduct ? t.offer.editProduct[category] : t.global.labels.edit
      }
      quantity={item.quantity}
    />
  );
};

export default OptionalItemPreview;
