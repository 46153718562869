import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootReducer from 'reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { googleTagMiddleware } from './middlewares';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'redux-store',
  storage: storageSession,
  whitelist: ['proposal', 'rollout', 'tracking'],
};

export default () => {
  const initialState = {};
  const middlewares = [routerMiddleware(history), thunk, googleTagMiddleware];
  const enhancers = [];

  if (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'stage-angebot.mymoria.de'
  ) {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window as any;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);

  return { persistor, store };
};
