import StepWizard, { StepProps } from 'components/StepWizard';
import { UserLocationNearbyProvider } from 'context';
import * as t from 'data';
import { STEP_TO_TRACKING_NAME } from 'helpers/funnelSteps';
import { useSelector, useBusinessAccount, usePhoneDirectory, useTrackingLocation } from 'hooks';
import { css, getValidPhoneFormat, mq, styled } from 'mymoria-ui';
import { Col, Row, Icon, StepProgressBar } from 'mymoria-ui/components';
import { ContactDesktop } from 'mymoria-ui/components/Header';
import React, { FunctionComponent, useRef, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { FunnelStep } from 'types';
import FunnelHeader from './FunnelHeader';
import { useStepValidation } from './hooks';
import { Address, FunnelFooter, FunnelMain, MobileBackButton } from './layout';
import {
  ConcernTypeStep,
  RelationshipStep,
  PlaceStep,
  FuneralTypeStep,
  FuneralPlanStep,
  ContactDetailsStep,
} from './steps';
import DeceasedLocationStep from './steps/DeceasedLocationStep';
import { ConcernTypeTitle, RelationShipTitle } from './steps/Titles';
import ContactDetailsTitle from './steps/Titles/ContactDetailsTitle';
import DeceasedLocationTitle from './steps/Titles/DeceasedLocationTitle';
import FuneralPlanTitle from './steps/Titles/FuneralPlanTitle';
import FuneralTypeTitle from './steps/Titles/FuneralTypeTitle';
import PlaceStepTitle from './steps/Titles/PlaceStepTitle';

const TRANSITION_TIME = 400;
export const ANIMATION_DELAY = 800;

export type FunnelStepProps = StepProps<FunnelStep>;

const STEPS_COMPONENTS_MAP: Record<
  FunnelStep,
  { component: FunctionComponent<FunnelStepProps>; title: FunctionComponent }
> = {
  character: { component: FuneralPlanStep, title: FuneralPlanTitle },
  'concern-type': { component: ConcernTypeStep, title: ConcernTypeTitle },
  data: { component: ContactDetailsStep, title: ContactDetailsTitle },
  'deceased-location': { component: DeceasedLocationStep, title: DeceasedLocationTitle },
  place: { component: PlaceStep, title: PlaceStepTitle },
  relationship: { component: RelationshipStep, title: RelationShipTitle },
  type: { component: FuneralTypeStep, title: FuneralTypeTitle },
};

const Slide = styled.div<{ duration: number }>(
  ({ duration }) => css`
    width: 100%;

    &[class$='enter-active'] > div:nth-of-type(1) {
      transition: all ${duration}ms ease;
    }

    &[class$='exit-active'] > div:nth-of-type(1) {
      transform: translateX(-100px);
      opacity: 0;
      transition: all ${duration}ms cubic-bezier(1, 0.5, 0.4, 1);
    }

    &[class$='enter-done'] > div:nth-of-type(1) {
      transform: translateX(0px);
      opacity: 1;
    }

    &[class$='enter'] > div:nth-of-type(1) {
      opacity: 0;
      ${mq({ transform: ['translateX(80px)', null, 'translateX(250px)'] })};
    }

    &[class$='exit'] > div:nth-of-type(1) {
      opacity: 1;
    }
  `,
);

const Fade = styled.div<{ duration: number }>(
  ({ duration }) => css`
    &[class$='enter-active'] > div:nth-of-type(1) {
      transition: all ${duration}ms ease;
    }

    &[class$='exit-active'] > div:nth-of-type(1) {
      opacity: 0;
      transition: all ${duration}ms cubic-bezier(1, 0.5, 0.4, 1);
    }

    &[class$='enter-done'] > div:nth-of-type(1) {
      opacity: 1;
    }

    &[class$='enter'] > div:nth-of-type(1) {
      opacity: 0;
    }

    &[class$='exit'] > div:nth-of-type(1) {
      opacity: 1;
    }
  `,
);

const renderComponent = (Component: FunctionComponent<any>, props?: {}) => <Component {...props} />;

const Funnel = ({ history, match }: RouteComponentProps<{ step: FunnelStep }>) => {
  const steps = useSelector(({ proposal }) => proposal.steps);
  const { pathname } = useLocation();
  const { phone, phoneText } = usePhoneDirectory();
  const { phone: opcoPhone } = useBusinessAccount();
  useTrackingLocation(STEP_TO_TRACKING_NAME[match.params.step], 'funnelpage');

  // todo:
  //    currently funnel is sharing the same proposal reducer as the edit page
  //    it works but it's not technically the same and it should be splitted up
  //    into separate stores

  const titleRef = useRef<HTMLDivElement>(null);
  const stepRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (match.params.step === 'concern-type') {
      //scroll and show navbar
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [match.params.step]);

  const hideNavbar = () => {
    if (match.params.step !== 'concern-type') {
      const mainElement = document.querySelector('main');

      if (mainElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: mainElement.offsetTop,
        });
      }
    }
  };

  return (
    <>
      <FunnelHeader />
      <UserLocationNearbyProvider>
        <FunnelMain>
          <StepWizard
            steps={steps}
            basename={match.url.replace(`/${match.params.step}`, '')}
            history={history}
            fallback="intro"
            onStepLoad={useStepValidation}
          >
            {({ step, back, ...wizardProps }) => (
              <>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={`${pathname}-title`}
                    nodeRef={titleRef}
                    timeout={TRANSITION_TIME}
                    onEntered={hideNavbar}
                  >
                    <Fade ref={titleRef} duration={TRANSITION_TIME}>
                      {renderComponent(STEPS_COMPONENTS_MAP[step].title)}
                    </Fade>
                  </CSSTransition>
                </SwitchTransition>
                <Col width={['100%', 324]} my={6}>
                  <StepProgressBar step={steps.indexOf(step) + 1} stepsNumber={steps.length + 1} />
                </Col>
                <Row
                  marginLeft={['unset', null, step === 'data' ? '0' : '-6.5rem']}
                  width={1}
                  maxWidth={[600, null, 700, 800]}
                  marginBottom="2rem"
                >
                  {step === 'data' ? null : (
                    <Col mt="3rem" mr="5rem" px={0} display={['none', null, 'flex']}>
                      <Icon.FunnelBackIcon width={24} height={46} cursor="pointer" onClick={back} />
                    </Col>
                  )}
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={`${pathname}-content`}
                      nodeRef={stepRef}
                      timeout={TRANSITION_TIME}
                      classNames="page"
                    >
                      <Slide ref={stepRef} duration={TRANSITION_TIME}>
                        {renderComponent(STEPS_COMPONENTS_MAP[step].component, {
                          step,
                          ...wizardProps,
                        })}
                      </Slide>
                    </CSSTransition>
                  </SwitchTransition>
                </Row>
                <MobileBackButton>
                  <Icon.FunnelMobileBackButton
                    width={14}
                    height={21}
                    cursor="pointer"
                    onClick={back}
                  />
                </MobileBackButton>
              </>
            )}
          </StepWizard>
          <Address>
            <ContactDesktop
              label={t.global.header.phoneText}
              phone={
                getValidPhoneFormat({
                  formatType: 'national',
                  phone: opcoPhone || phoneText,
                  withSlash: true,
                }) || t.global.header.phoneNumber
              }
              phoneHref={opcoPhone || phone}
            />
          </Address>
        </FunnelMain>
        <FunnelFooter links={t.footer.secondaryLinks} copyright={t.footer.copyright} />
      </UserLocationNearbyProvider>
    </>
  );
};

export default Funnel;
