import { ReactComponent as ConcernTypeIcon } from 'assets/stepIcons/concern_type_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import React from 'react';

const ConcernTypeTitle = () => (
  <StepTitle
    icon={<ConcernTypeIcon />}
    hint={t.funnel.concernType.hint}
    title={t.funnel.concernType.title}
  />
);

export default ConcernTypeTitle;
