import { styled } from 'mymoria-ui';
import { Col } from 'mymoria-ui/components';
import { LayoutProps, SpaceProps } from 'mymoria-ui/interfaces';
import React from 'react';

const Dot = styled.div<{ selected?: boolean }>(({ theme: { space, colors }, selected }) => ({
  background: selected ? colors.primary : 'none',
  border: `solid 2px ${colors.primary}`,
  borderRadius: '50%',
  boxSizing: 'border-box',
  height: space[1],
  margin: '0 3px',
  width: space[1],
}));

interface CurrentScreenIndicatorProps extends SpaceProps, LayoutProps {
  length: number;
  currentIndex: number;
}

const CurrentScreenIndicator = ({
  length,
  currentIndex,
  ...props
}: CurrentScreenIndicatorProps) => (
  <Col justifyContent={['flex-start', null, 'center']} {...props}>
    {[...Array(length).keys()].map((_, index) => (
      <Dot key={`indicator-dot-${index}`} selected={index === currentIndex} />
    ))}
  </Col>
);

export default CurrentScreenIndicator;
