import { trackUserInteraction } from 'actions/tracking';
import {
  Caption,
  Markdown,
  Text,
  CustomOfferItemPreviewV2,
  CustomOfferItemPreviewV2Props,
  Icon,
} from 'components';
import { global, offer } from 'data';
import { formatPrice, getFuneralSitePrice } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { isEmpty } from 'lodash';
import { styled } from 'mymoria-ui';
import { cdn, css } from 'mymoria-ui/utils';
import { useRouteMatch } from 'react-router-dom';
import { getFuneralSiteById } from 'reducers/entities';
import { isOfferEditable } from 'reducers/proposal';
import OfferPageCard from './OfferPageCard';

const StyledMarkdown = styled(Markdown)`
  ul {
    margin: 0 0 0 1rem;
  }
  li {
    padding-bottom: 0;
  }
`;

const StyledCard = styled(OfferPageCard)(
  ({ title }) => css`
    //TODO: Can we have another card variant?
    && {
      padding: 1.5rem 0;
    }

    ${title &&
    `
        & > div:first-of-type {
          padding: 0 1.5rem;
          margin-bottom: 1rem;
        }
      `}
  `,
);

const funeralSiteCardCommonProps: CustomOfferItemPreviewV2Props = {
  deselectIcon: <Icon.Pencil />,
  deselectLabel: global.labels.edit,
  selectIcon: <Icon.Pencil />,
  selectLabel: global.labels.edit,
};

const FuneralSiteCard = () => {
  const graveType = useSelector(({ proposal }) => proposal.graveType);
  const funeralSiteId = useSelector(({ proposal }) => proposal.funeralSite);
  const graveSites = useSelector(({ proposal }) => proposal.customizations?.graveSites);
  const funeralSite = useSelector(getFuneralSiteById(funeralSiteId));
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const customFuneralSite = useSelector(({ proposal }) => proposal.customFuneralSite);
  const state = useSelector(({ proposal }) => proposal.state);
  const graveTypeEntity = useSelector(({ entities }) => entities.graveTypes[graveType]);
  const isEditable = useSelector(isOfferEditable());
  const { push } = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Funeral site', funeralType));
    push(`${match.url}/funeral-site`);
  };

  if (!isEmpty(funeralSite) && state !== 'customized') {
    const funeralSitePrice = getFuneralSitePrice(funeralSite, graveType);

    return (
      <StyledCard
        title={offer.offerDetails.funeralSiteTitle[funeralType]}
        status={funeralSitePrice > 0 ? 'completed' : 'optional'}
        statusLabel={global.status[funeralSitePrice > 0 ? 'selected' : 'notIncluded']}
      >
        <CustomOfferItemPreviewV2
          id={funeralSiteId}
          selected={funeralSitePrice > 0 ? true : false}
          price={funeralSitePrice > 0 ? funeralSitePrice : undefined}
          priceFormatter={formatPrice}
          name={funeralSite.title}
          image={cdn('www', `/img/funeralSite/${funeralType}.png`)}
          onSelect={isEditable ? handleEdit : undefined}
          {...funeralSiteCardCommonProps}
        >
          {funeralSitePrice > 0 ? (
            <>
              {graveTypeEntity && (
                <StyledMarkdown source={graveTypeEntity.translations.description} />
              )}
              <Caption py={2}>{offer.offerDetails.funeralSiteCaption}</Caption>
            </>
          ) : (
            <Text my={3}>{offer.offerDetails.noFuneralSitePrice}</Text>
          )}
        </CustomOfferItemPreviewV2>
      </StyledCard>
    );
  }

  if (state === 'customized' && !isEmpty(graveSites)) {
    return (
      <StyledCard
        title={offer.offerDetails.funeralSiteTitle[funeralType]}
        status="completed"
        statusLabel={global.status['selected']}
      >
        {graveSites!.map(graveSite => (
          <CustomOfferItemPreviewV2
            id={funeralSiteId}
            key={graveSite.product2Id}
            selected={graveSite.price > 0 ? true : false}
            price={graveSite.price > 0 ? graveSite.price : undefined}
            priceFormatter={formatPrice}
            name={graveSite.translations.shortname}
            image={cdn('www', `/img/funeralSite/${funeralType}.png`)}
            onSelect={isEditable ? handleEdit : undefined}
            {...funeralSiteCardCommonProps}
          >
            <>
              {graveSite.translations.description && (
                <StyledMarkdown source={graveSite.translations.description} />
              )}
              <Caption py={2}>{offer.offerDetails.funeralSiteCaption}</Caption>
            </>
          </CustomOfferItemPreviewV2>
        ))}
      </StyledCard>
    );
  }

  if (state !== 'customized' || customFuneralSite) {
    return (
      <StyledCard
        title={offer.offerDetails.noFuneralTitle}
        status={customFuneralSite ? 'completed' : 'incompleted'}
        statusLabel={global.status[customFuneralSite ? 'notIncluded' : 'open']}
      >
        <CustomOfferItemPreviewV2
          isEditable={isEditable}
          image="/img/map.png"
          onSelect={handleEdit}
          shortDescription={customFuneralSite || offer.offerDetails.noFuneralDescription}
          {...funeralSiteCardCommonProps}
        />
      </StyledCard>
    );
  }
  return null;
};

export default FuneralSiteCard;
