import { offer as t } from 'data';
import { formatPrice } from 'helpers';
import { useSelector } from 'hooks';
import { css, styled } from 'mymoria-ui';
import { CustomOfferItemPreviewV2 } from 'mymoria-ui/components';
import React from 'react';
import { sprintf } from 'utils';
import BasicProductPreview from './BasicProductPreview';
import BasicServicePreview from './BasicServicePreview';
import OfferPageCard from '../OfferPageCard';

const StyledCard = styled(OfferPageCard)(
  ({ title }) => css`
    //TODO: Can we have another card variant?
    && {
      padding: 1.5rem 0;
    }

    ${title &&
    `
        & > div:first-of-type {
          padding: 0 1.5rem;
          margin-bottom: 1rem;
        }
      `}
  `,
);

const BasicItemsCard = () => {
  const { number, state, basicServices, basicProducts, extras } = useSelector(
    ({ proposal }) => proposal,
  );
  const items = [
    ...basicServices.map(id => <BasicServicePreview key={id} />),
    ...(extras || [])
      .filter(({ category }) => category === 'serviceDiscount')
      .map(
        ({
          id,
          translations: { shortname, description },
          price,
          pictures,
          quantity,
          placeholder,
        }) => (
          <CustomOfferItemPreviewV2
            id={id}
            key={id}
            selected
            name={shortname}
            price={price}
            priceFormatter={formatPrice}
            image={pictures[0] || placeholder}
            shortDescription={description}
            quantity={quantity}
          />
        ),
      ),
    ...basicProducts.map(id => <BasicProductPreview key={id} id={id} />),
  ];

  if (items.length === 0) {
    return null;
  }

  return (
    <StyledCard
      title={sprintf(
        state ? t.offerDetails.servicesTitle : t.offerDetails.createModeServicesTitle,
        { number },
      )}
    >
      {items}
    </StyledCard>
  );
};

export default BasicItemsCard;
