import { ReactComponent as ContactDetailsIcon } from 'assets/stepIcons/contact_details_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import { useSelector } from 'hooks';
import React from 'react';
import { sprintf } from 'utils';

const ContactDetailsTitle = () => {
  const city = useSelector(({ proposal }) => proposal.city);
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const funeralPlan = useSelector(({ proposal }) => proposal.funeralPlan);

  const character = funeralPlan === 'high' ? 'begleitete' : 'unbegleitete';

  const title = sprintf(
    funeralType === 'sea' ? t.funnel.contactDetails.titleSea : t.funnel.contactDetails.title,
    {
      character: character || '',
      city: city,
      funeralType: t.form.funeralType[funeralType],
    },
  );

  return (
    <StepTitle icon={<ContactDetailsIcon />} title={title} hint={t.funnel.contactDetails.hint} />
  );
};

export default ContactDetailsTitle;
