import { noop } from 'lodash';
import React, { createContext, useState, ReactNode } from 'react';

interface ContextProps {
  activePageIndex: number;
  steps: number;
  goNextPage: () => void;
  goPrevPage: () => void;
}

interface WizardProps {
  steps: number;
  children: ReactNode;
}

const WizardDefaultValues: ContextProps = {
  activePageIndex: 0,
  goNextPage: noop,
  goPrevPage: noop,
  steps: 0,
};

export const WizardContext = createContext<ContextProps>(WizardDefaultValues);

const Wizard = (props: WizardProps) => {
  const [activePageIndex, setActivePageIndex] = useState(0);

  const goNextPage = () => {
    setActivePageIndex(index => index + 1);
  };

  const goPrevPage = () => {
    setActivePageIndex(index => index - 1);
  };

  const context = {
    activePageIndex,
    goNextPage,
    goPrevPage,
    steps: props.steps,
  };

  return <WizardContext.Provider value={context}>{props.children}</WizardContext.Provider>;
};

export default Wizard;
