import { setFunnelData } from 'actions/proposal';
import { trackFunnelData } from 'actions/tracking';
import { ReactComponent as BurialIcon } from 'assets/burial.svg';
import { ReactComponent as CremationIcon } from 'assets/cremation.svg';
import { ReactComponent as SeaIcon } from 'assets/sea.svg';
import { ReactComponent as TreeIcon } from 'assets/tree.svg';
import { Col, Legend, Row, Text, Box, Caption } from 'components';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { css, styled } from 'mymoria-ui';
import { FunnelRadioButtonGroup, FunnelRadioButton } from 'mymoria-ui/components/Button';
import React, { FunctionComponent } from 'react';
import { FuneralType } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  white-space: normal;
  overflow-wrap: anywhere;

  & svg {
    width: 64px;
    height: 64px;
  }
`;

const StyledLegend = styled(Legend)(
  ({ theme: { fontSizes, colors, space } }) => css`
    text-align: center;
    font-weight: normal;
    font-size: ${fontSizes.tiny};
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
    margin-top: ${space.extraTiny};
    text-transform: lowercase;
  `,
);

const funeralTypeToIcon = {
  burial: <BurialIcon />,
  cremation: <CremationIcon />,
  sea: <SeaIcon />,
  tree: <TreeIcon />,
};

const StyledRadioButton = styled(FunnelRadioButton)`
  && {
    &:hover {
      & > .tag {
        transform: scale(1.08);
        transition: 0.3s;
        opacity: 1;
      }
    }
  }
`;

const FuneralTypeStep: FunctionComponent<FunnelStepProps> = ({ next, push, onStepLoad }) => {
  const dispatch = useDispatch();
  const funeralSite = useSelector(({ proposal }) => proposal.funeralSite);
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  onStepLoad();

  const handleSubmit = async (selectedFuneralType: FuneralType) => {
    // If funeralSite and funeralType were passed in url and user selected different funeralType
    // If funeralSite was passed in url without funeralType
    const shouldClearFuneralSite = funeralSite ? funeralType !== selectedFuneralType : false;

    const data = {
      funeralType: selectedFuneralType,
      ...(shouldClearFuneralSite && { funeralSite: undefined }),
    };

    dispatch(setFunnelData(data));
    dispatch(trackFunnelData(data));
    await sleep(ANIMATION_DELAY);
    if (funeralType === 'sea') {
      push('character');
    } else {
      next();
    }
  };

  return (
    <Row flexWrap="wrap" width={[1, null, '100%']}>
      <FunnelRadioButtonGroup
        name="funeralType"
        defaultValue={funeralType}
        onChange={e => handleSubmit(e.target.value as FuneralType)}
      >
        {t.funnel.funeralType.options.map(({ value, label, description, legend }) => (
          <Col key={value} width={[1, 1 / 2]} py={1} px={[0, 1]} height="150px">
            <StyledRadioButton value={value} padding="16px 4px">
              <StyledWrapper>
                <Box minWidth={84} minHeight={[70, 84]} height={70} p={0}>
                  {funeralTypeToIcon[value as FuneralType]}
                </Box>
                <div>
                  <Text fontWeight="bold" fontSize="normal" lineHeight="18px" textAlign="left">
                    {label}
                  </Text>
                  <Caption
                    mt={1}
                    maxWidth={['75%', '100%']}
                    lineHeight="normal"
                    fontSize="tiny"
                    textAlign="left"
                  >
                    {description}
                  </Caption>
                  {legend && (
                    <StyledLegend color="white" className="tag">
                      {legend}
                    </StyledLegend>
                  )}
                </div>
              </StyledWrapper>
            </StyledRadioButton>
          </Col>
        ))}
      </FunnelRadioButtonGroup>
    </Row>
  );
};

export default FuneralTypeStep;
