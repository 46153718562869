import { FunnelStep, ProposalState } from 'types';

export const STEP_TO_FUNNEL_KEY: {
  [key in Exclude<FunnelStep, 'data'>]: keyof ProposalState | 'skip';
} = {
  character: 'funeralPlan',
  'concern-type': 'concernType',
  'deceased-location': 'deceasedLocation',
  place: 'city',
  relationship: 'relationship',
  type: 'funeralType',
};

export const STEP_TO_TRACKING_NAME: Record<FunnelStep, string> = {
  character: 'create.character',
  'concern-type': 'create.concerns',
  data: 'create.data',
  'deceased-location': 'create.deceased-location',
  place: 'create.place',
  relationship: 'create.relationship',
  type: 'create.type',
};
