import { Text, ContactUsModal } from 'components';
import { mail, person, phone as phoneIcon } from 'components/Icons';
import * as t from 'data';
import { useHistory, useSelector } from 'hooks';
import { styled } from 'mymoria-ui';
import { Row, EditButton, ActionButton, Icon } from 'mymoria-ui/components';
import { useModal } from 'mymoria-ui/hooks';
import theme from 'mymoria-ui/styles/theme';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getCustomerFullName, getCustomerPhone, isOfferEditable } from 'reducers/proposal';
import DownloadPDFButton from './DownloadPDFButton';
import OfferPageCard from './OfferPageCard';

const StyledText = styled(Text)`
  text-overflow: ellipsis;
`;

const SpeechBubble = styled(Icon.SpeechBubble)`
  vertical-align: middle;
  margin-right: 0.5rem;
`;

interface BasicInformationCardProps {
  isMobileVersion?: boolean;
}

const BasicInformationCard = ({ isMobileVersion }: BasicInformationCardProps) => {
  const { url } = useRouteMatch();
  const { handlePush } = useHistory();
  const name = useSelector(getCustomerFullName);
  const email = useSelector(({ proposal }) => proposal.email);
  const phone = useSelector(getCustomerPhone);
  const isEditable = useSelector(isOfferEditable());
  const state = useSelector(({ proposal }) => proposal.state);

  const {
    showModal: showContactUsModal,
    hideModal: hideContactUsModal,
    Modal: ContactUsModalHook,
  } = useModal();

  const data = [
    { icon: person, value: name },
    { icon: mail, value: email },
    { icon: phoneIcon, value: phone },
  ];

  return (
    <>
      <OfferPageCard title={t.offer.generalInformation.title} variant="small">
        <>
          {data.map(({ icon, value }) => (
            <Row key={icon} mb="normal" alignItems="center">
              <img src={icon} alt={icon} width={26} height={26} />
              <StyledText fontWeight="bold" letterSpacing={0.78} ml="normal" overflow="hidden">
                {value}
              </StyledText>
            </Row>
          ))}
          {isEditable && (
            <EditButton onClick={handlePush(`${url}/contact-details`)}>
              {t.global.labels.edit}
            </EditButton>
          )}
        </>
        {isMobileVersion && (
          <>
            <DownloadPDFButton block mt="small">
              {t.offer.offerSummary.downloadPDFButtonTitle}
            </DownloadPDFButton>
          </>
        )}
      </OfferPageCard>
      {!isMobileVersion && (
        <>
          {state === 'postcheckout' && (
            <ActionButton mt="large" block onClick={showContactUsModal}>
              <SpeechBubble />
              {t.offer.offerSummary.contactUsButtonTitle}
            </ActionButton>
          )}
          <DownloadPDFButton block my="small">
            {t.offer.offerSummary.downloadPDFButtonTitle}
          </DownloadPDFButton>
        </>
      )}
      <ContactUsModalHook
        justifyContent="unset"
        height={['100%', 'unset']}
        maxHeight="100%"
        borderRadius={['unset', `${theme.radii[1]}px`]}
      >
        <ContactUsModal hideModal={hideContactUsModal} />
      </ContactUsModalHook>
    </>
  );
};

export default BasicInformationCard;
