import { push, goBack, replace, goForward, go } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const action = useCallback(
    (path: string, state?: any) => dispatch(push(path, state)),
    [dispatch],
  );
  const handleAction = useCallback(
    (path: string, state?: any) => () => action(path, state),
    [action],
  );

  const goAction = useCallback((index: number) => dispatch(go(index)), [dispatch]);
  const handleGoAction = useCallback((index: number) => () => goAction(index), [goAction]);

  const goBackAction = useCallback(() => dispatch(goBack()), [dispatch]);
  const handleGoBackAction = useCallback(() => goBackAction(), [goBackAction]);

  const goForwardAction = useCallback(() => dispatch(goForward()), [dispatch]);
  const handleGoForwardAction = useCallback(() => goForwardAction(), [goForwardAction]);

  const replaceAction = useCallback(
    (path: string, state?: any) => dispatch(replace(path, state)),
    [dispatch],
  );
  const handleReplaceAction = useCallback(
    (path: string, state?: any) => () => replaceAction(path, state),
    [replaceAction],
  );

  return {
    go: goAction,
    goBack: goBackAction,

    goForward: goForwardAction,
    handleGo: handleGoAction,

    handleGoBack: handleGoBackAction,
    handleGoForward: handleGoForwardAction,

    handlePush: handleAction,
    handleReplace: handleReplaceAction,

    push: action,
    replace: replaceAction,
  };
};
