import { ReactComponent as RelationshipIcon } from 'assets/stepIcons/relationship_step_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import React from 'react';

const RelationShipTitle = () => (
  <StepTitle
    icon={<RelationshipIcon />}
    hint={t.funnel.relationship.hint}
    title={t.funnel.relationship.title}
  />
);

export default RelationShipTitle;
