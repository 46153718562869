import { Icon } from 'components';
import config from 'config';
import { useBusinessAccount } from 'hooks';
import { styled } from 'mymoria-ui';
import React from 'react';

const OpcoLogo = styled.img({ maxHeight: '55px', width: 'auto' });

const IntroHeader = () => {
  const { logoUrl: opcoLogo, name: opcoName } = useBusinessAccount();
  return opcoLogo ? (
    <OpcoLogo src={opcoLogo} alt={opcoName} />
  ) : (
    <Icon.MymoriaLogo href={config.web.url} />
  );
};

export default IntroHeader;
