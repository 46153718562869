import { useWizard } from 'hooks';
import React, { ReactNode, Children } from 'react';

interface Props {
  children: ReactNode;
}

const WizardPages = (props: Props) => {
  const { activePageIndex } = useWizard();
  const pages = Children.toArray(props.children);
  const currentPage = pages[activePageIndex];
  return <>{currentPage}</>;
};

export default WizardPages;
