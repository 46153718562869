import { Heading, Button } from 'components';
import { global } from 'data';
import { styled, css } from 'mymoria-ui/utils';
import React from 'react';
import { ContactUsWizardStepsType } from 'types';

const StyledButton = styled(Button)(
  ({ theme: { fontWeights } }) => css`
    border-radius: 12px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 16, 0.15);
    font-weight: ${fontWeights.bold};
    height: 60px;
    line-height: 25px;
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
  `,
);

interface Props {
  setWizardType: (WizardType: ContactUsWizardStepsType) => void;
}

const Intro = (props: Props) => (
  <>
    <Heading.Heading3 fontWeight="extraBold" mb="2rem" textAlign="center">
      {global.contactUs.intro.title}
    </Heading.Heading3>
    <StyledButton mb="1rem" block onClick={() => props.setWizardType('Callback')}>
      {global.contactUs.intro.callbackButton}
    </StyledButton>
    <StyledButton block onClick={() => props.setWizardType('Contact')}>
      {global.contactUs.intro.contactButton}
    </StyledButton>
  </>
);

export default Intro;
