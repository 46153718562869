import { parseQueryParams } from 'helpers';
import { memoize } from 'lodash';
import noop from 'lodash/noop';
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useLocation } from 'react-router-dom';
import { PhoneDirectory } from 'types';
import { api } from 'utils';

const fetchPhoneDirectoryForCity = (city: PhoneDirectory['city']) =>
  api.get<PhoneDirectory>('/phonedirectory', { params: { city } }).then(({ data }) => data);

const memoizedFetchPhoneDirectoryForCity = memoize(fetchPhoneDirectoryForCity);

interface PhoneProviderProps extends PhoneDirectory {
  fetchCity: typeof fetchPhoneDirectoryForCity;
  setPhoneDirectory: Dispatch<SetStateAction<PhoneDirectory>>;
}

const initPhoneDirectory = {} as PhoneProviderProps;
export const PhoneDirectoryContext = createContext<PhoneProviderProps>(initPhoneDirectory);

const PhoneDirectoryProvider: FunctionComponent = ({ children }) => {
  const [phoneDirectory, setPhoneDirectory] = useState<PhoneDirectory>(initPhoneDirectory);
  const { search } = useLocation();

  useEffect(() => {
    const { city } = parseQueryParams(search, 'city');
    const config = city ? { params: { city } } : {};
    if (phoneDirectory.city && typeof city === 'undefined') {
      return;
    }

    api
      .get('/phonedirectory', config)
      .then(result => setPhoneDirectory(result.data))
      .catch(noop);
  }, [phoneDirectory.city, search]);

  return (
    <PhoneDirectoryContext.Provider
      value={{
        ...phoneDirectory,
        fetchCity: memoizedFetchPhoneDirectoryForCity,
        setPhoneDirectory,
      }}
    >
      {children}
    </PhoneDirectoryContext.Provider>
  );
};

export default PhoneDirectoryProvider;
