import { FuneralType, ProductCategory } from 'types';

type CategoriesMap = {
  [key: string]: ProductCategory | undefined;
};
type FuneralTypesMap = {
  [key: string]: FuneralType;
};

export const categoriesMap: CategoriesMap = {
  besonderes: 'grave_goods',
  blumen: 'flower',
  erinnerungsschmuck: 'keepsake',
  grabkreuze: 'graveCross',
  karten: 'card',
  saerge: 'coffin',
  traueranzeigen: 'notice',
  urnen: 'urn',
};
export const funeralTypesMap: FuneralTypesMap = {
  baumbestattung: 'tree',
  erdbestattung: 'burial',
  feuerbestattung: 'cremation',
  seebestattung: 'sea',
};
