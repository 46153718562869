import { ReactComponent as FuneralPlanIcon } from 'assets/stepIcons/character_step_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import { useSelector } from 'hooks';
import React from 'react';

const FuneralPlanTitle = () => {
  const { funeralType } = useSelector(({ proposal }) => proposal);
  const title = funeralType === 'sea' ? t.funnel.funeralPlan.seaTitle : t.funnel.funeralPlan.title;
  return (
    <StepTitle
      icon={<FuneralPlanIcon />}
      hint={t.funnel.funeralPlan.hint[funeralType]}
      title={title}
    />
  );
};

export default FuneralPlanTitle;
