import { Head, NotFoundPage, CatalogProductDetails, CatalogProductsList } from 'components';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import FunnelRouter from './FunnelRouter';
import OfferCreator from './OfferCreator';
import OfferPage from './OfferPage';

const App = () => {
  const { pathname, search } = useLocation();

  return (
    <>
      <Head />
      <Switch>
        <Redirect from="/:url*(/+)" to={{ pathname: pathname.slice(0, -1), search }} />
        <Route path="/create" component={FunnelRouter} />
        <Route path="/offer/prepare" component={OfferCreator} />
        <Route path="/offer/:id" component={OfferPage} />
        <Route path="/katalog/:category/:id" component={CatalogProductDetails} />
        <Route path="/katalog/:category" component={CatalogProductsList} />
        <Redirect from="/" to={{ pathname: '/create', search }} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default App;
