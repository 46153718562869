import { FieldConfig, useField } from 'formik';
import Input, { InputProps } from 'mymoria-ui/components/Form/Input';
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';

const InputField: FunctionComponent<Omit<FieldConfig, 'validate'> & InputProps> = ({
  onChange,
  validation = true,
  ...props
}) => {
  const [field, meta] = useField(props);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      onChange && onChange(e);
    },
    [field, onChange],
  );

  // if input is prefilled and has no error allow validation other wise check error state
  const prefilled = (field.value && !meta.error) || !!meta.error;

  return (
    <Input
      {...field}
      {...props}
      onChange={handleChange}
      validation={validation && meta.touched && prefilled}
      error={meta.touched ? meta.error : ''}
    />
  );
};

export default InputField;
