import { useBrowserBackButton } from 'mymoria-ui/hooks';
import React, { createContext, FunctionComponent, useCallback, useState } from 'react';
import { ProductCategory } from '../types';

export type FunnelMode = 'edit' | 'create' | null;
export type FunnelSection = null;
export type FunnelManualSection = 'FUNERAL_SITE' | null;
export interface FunnelOptions {
  [field: string]: any;
  category?: ProductCategory;
}

interface FunnelData {
  mode: FunnelMode;
  section: FunnelSection;
  manualSection: FunnelManualSection;
  options: FunnelOptions;
  setManualOptions: (section: FunnelManualSection, options?: FunnelOptions) => void;
  openCreateFunnel: (section: FunnelSection, options?: FunnelOptions) => void;
  openEditFunnel: (section: FunnelSection, options?: FunnelOptions) => void;
  closeFunnel: () => void;
}

export const FunnelContext = createContext<FunnelData>({} as FunnelData);

const FunnelProvider: FunctionComponent = ({ children }) => {
  const [mode, setMode] = useState<FunnelMode>(null);
  const [section, setSection] = useState<FunnelSection>(null);
  const [manualSection, setManualSection] = useState<FunnelManualSection>(null);
  const [options, setOptions] = useState({});
  const { addBackButtonListener, removeBackButtonListener } = useBrowserBackButton();

  const closeFunnelProc = useCallback(() => {
    setMode(null);
    setSection(null);
    setManualSection(null);
    setOptions({});
  }, [setMode, setSection, setManualSection, setOptions]);

  const openFunnel = useCallback(
    (mode: FunnelMode) => (section: FunnelSection, options?: FunnelOptions) => {
      setMode(mode);
      setSection(section);

      addBackButtonListener(closeFunnelProc);

      if (options) {
        setOptions(prevOptions => ({ ...prevOptions, ...options }));
      }
    },
    [addBackButtonListener, closeFunnelProc],
  );

  const openCreateFunnel = useCallback(
    (section, options) => openFunnel('create')(section, options),
    [openFunnel],
  );

  const setManualOptions = useCallback(
    (section, options) => {
      setManualSection(section);
      setOptions(prevOptions => ({ ...prevOptions, ...options }));
    },
    [setManualSection, setOptions],
  );

  const openEditFunnel = useCallback(
    (section, options) => openFunnel('edit')(section, options),
    [openFunnel],
  );

  const closeFunnel = useCallback(async () => {
    closeFunnelProc();
    removeBackButtonListener();
  }, [closeFunnelProc, removeBackButtonListener]);

  const contextValue: FunnelData = {
    closeFunnel,
    manualSection,
    mode,
    openCreateFunnel,
    openEditFunnel,
    options,
    section,
    setManualOptions,
  };

  return <FunnelContext.Provider value={contextValue}>{children}</FunnelContext.Provider>;
};

export default FunnelProvider;
