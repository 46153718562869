import actions from 'actions';
import { map, merge } from 'lodash';
import { ProductsState } from 'types';
import { createReducer } from 'typesafe-actions';

const {
  fetchProposal,
  fetchProposalAdditionalData,
  changeProduct,
  addOfferItem,
  changeBasicInformation,
  removeOfferItem,
  removeOfferMultiSelectedItem,
} = actions.proposal;

const { fetchAlternativeProducts } = actions.products;

const initialState: ProductsState = {
  alternatives: {
    all: {},
    burial: {},
    cremation: {},
    sea: {},
    tree: {},
  },
  basic: [],
  defaults: {
    basic: [],
    optional: [],
  },
  optional: [],
};

const updateCollection = (collection: string[], removedId: string, addedId: string) =>
  map(collection, id => (id === removedId ? addedId : id));

const removeFromCollection = (collection: string[], removedId: string) =>
  collection.filter(id => id !== removedId);

export default createReducer(initialState)
  .handleAction(
    [fetchProposal, fetchProposalAdditionalData, changeBasicInformation],
    (state, { payload }) => ({ ...state, ...payload.result.products }),
  )
  .handleAction(fetchAlternativeProducts, (state, { payload: { result } }) => ({
    ...state,
    alternatives: merge({ ...state.alternatives }, result),
  }))
  .handleAction(changeProduct, (state, { payload: { addedId, removedId } }) => ({
    ...state,
    basic: updateCollection(state.basic, removedId, addedId),
    optional: updateCollection(state.optional, removedId, addedId),
  }))
  .handleAction(addOfferItem, (state, { payload: { id, isMulti } }) => ({
    ...state,
    ...(isMulti ? { optional: [...state.optional, id] } : {}),
  }))
  .handleAction(removeOfferItem, (state, { payload }) => ({
    ...state,
    ...(payload.defaultProductId && {
      optional: updateCollection(state.optional, payload.id, payload.defaultProductId),
    }),
  }))
  .handleAction(removeOfferMultiSelectedItem, (state, { payload }) => ({
    ...state,
    optional: removeFromCollection(state.optional, payload.id),
  }));
