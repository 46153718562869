import { CardBanner, WideBanner } from 'components';
import * as t from 'data';
import { useBusinessAccount, useCMSSelector } from 'hooks';
import { styled } from 'mymoria-ui/utils';
import React from 'react';

const StyledCardBanner = styled(CardBanner)`
  margin-bottom: 0;
  border-radius: 0;

  img {
    width: 150px;
  }
`;

const CustomerCareBanner = () => {
  const { Title, Content } = useCMSSelector(({ OfferPageInfoboxes }) => OfferPageInfoboxes['4']);
  const handleClick = () => window.open(t.offer.customerCare.phoneHref, '_self');
  const { representative } = useBusinessAccount();

  return (
    <>
      <WideBanner
        display={['none', 'none', 'flex']}
        title={Title}
        content={Content}
        onClick={handleClick}
        buttonLabel={t.offer.customerCare.phoneText}
        teamImage="/img/team.png"
        backgroundImage="/img/circle.png"
      />
      <StyledCardBanner
        display={['flex', 'flex', 'none']}
        title={Title}
        content={Content}
        buttonLabel={t.offer.customerCare.phoneText}
        onClick={handleClick}
        avatarSrc={representative?.url}
        rounded
        avatarCaption={
          representative?.name ? `**${representative?.name}** ${representative?.title}` : ''
        }
      />
    </>
  );
};

export default CustomerCareBanner;
