import { fetchGraveTypes } from 'actions/graveTypes';
import { fetchProposal } from 'actions/proposal';
import {
  LoadingPage,
  NotFoundPage,
  OfferBasicInformationPage,
  OfferContactDetailsPage,
  OfferCustomizedPage,
  OfferEditPage,
  OfferFuneralSitePage,
  OfferProductPage,
  OfferProductsPage,
} from 'components';
import { useDispatch, useSelector, useTrackingLocation } from 'hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { isOfferLoaded } from 'reducers/entities';
import { OfferState } from 'types';

type Params = {
  id: string;
};

const OfferPageContainer: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(({ proposal }) => proposal.state);
  const isPreloaded = useSelector(isOfferLoaded);
  const { search } = useLocation();

  useTrackingLocation('create.offer', 'offer-page');

  useEffect(() => {
    if (!isPreloaded) {
      setIsLoading(true);
      Promise.all([dispatch(fetchProposal(match.params.id)), dispatch(fetchGraveTypes())])
        .catch(err => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, match.params.id, isPreloaded, search]);

  if (error) {
    return <NotFoundPage />;
  }

  const renderOfferPage = (state: OfferState) =>
    state === 'customized' ? <OfferCustomizedPage /> : <OfferEditPage />;

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Switch>
      <Route exact path={match.path} render={() => renderOfferPage(state)} />
      <Route path={`${match.path}/contact-details`} component={OfferContactDetailsPage} />
      <Route path={`${match.path}/basic-information`} component={OfferBasicInformationPage} />
      <Route path={`${match.path}/funeral-site`} render={() => <OfferFuneralSitePage />} />
      <Route path={`${match.path}/products/:category/:id`} component={OfferProductPage} />
      <Route path={`${match.path}/products/:category`} component={OfferProductsPage} />
    </Switch>
  );
};

export default OfferPageContainer;
