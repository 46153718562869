import config from 'config';
import { includes, map, partition, reject, toString, concat, sortBy } from 'lodash';
import { Customizations, FuneralPlan, FuneralType, ProductCategory } from 'types';
import { ProductItem } from 'types/api';
import { api, customize, getAxiosData } from 'utils';

const { mandatoryProductCategories, productsOrder } = config;

const SERVICE_ROUTE = 'productsv2';

interface DefaultProductsResponse {
  basic: ProductItem[];
  optional: ProductItem[];
}

const sort = (products: ProductItem[]) =>
  sortBy(products, ({ category }) => productsOrder[category]);

const split = (products: ProductItem[]) =>
  partition<ProductItem>(products, ({ category }) =>
    includes(mandatoryProductCategories, category),
  );

const parse = (selectedProducts: ProductItem[], allProducts: ProductItem[]) => {
  const [selectedBasicProducts, selectedOptionalProducts] = split(selectedProducts);
  const [allBasicProducts, allOptionalProducts] = split(allProducts);

  return {
    all: {
      basic: allBasicProducts,
      defaults: {
        basic: allBasicProducts,
        optional: sort(allOptionalProducts),
      },
      optional: sort(allOptionalProducts),
    },
    selected: {
      basicProducts: selectedBasicProducts,
      optionalProducts: selectedOptionalProducts,
    },
  };
};

const fetch = (sku: string, pricebookId: string) =>
  api
    .get<ProductItem>(`/${SERVICE_ROUTE}/${sku}`, { params: { pricebookId } })
    .then(({ data }) => data);

const fetchDefault = (pricebookId: string, plan: FuneralPlan, type: FuneralType) =>
  api
    .get<DefaultProductsResponse>(`/${SERVICE_ROUTE}/default`, {
      params: { plan, pricebookId, type },
    })
    .then(({ data: { basic, optional } }) => ({
      basic,
      optional,
    }));

const parseProductsAlongStore = (
  selectedProducts: ProductItem[],
  defaultProducts: DefaultProductsResponse,
  customizations?: Customizations,
) => {
  const [selectedBasicProducts, selectedOptionalProducts] = split(
    customize(selectedProducts, customizations?.products),
  );

  const [defaultsBasic, defaultsOptional] = split(
    customize(concat(defaultProducts.basic, defaultProducts.optional), customizations?.products),
  );

  // reject suggested product if user already selected one for given category
  const productsDeselected = reject(defaultsOptional, ({ category }) =>
    includes(map(selectedOptionalProducts, 'category'), category),
  );

  return {
    all: {
      basic: selectedBasicProducts,
      defaults: {
        basic: defaultsBasic,
        optional: sort(defaultsOptional),
      },
      optional: sort(concat(productsDeselected, selectedOptionalProducts)),
    },
    selected: {
      basicProducts: selectedBasicProducts,
      optionalProducts: selectedOptionalProducts,
    },
  };
};

interface interfaceFetchSelected {
  pricebookId: string;
  plan: FuneralPlan;
  type: FuneralType;
  SKUs: string[];
  customizations?: Customizations;
}

const fetchSelected = ({ pricebookId, plan, type, SKUs, customizations }: interfaceFetchSelected) =>
  Promise.all([
    SKUs.length > 0
      ? api
          .get<ProductItem[]>(`/${SERVICE_ROUTE}`, {
            params: { SKUs: toString(SKUs), pricebookId },
          })
          .then(getAxiosData)
      : [],
    fetchDefault(pricebookId, plan, type),
  ]).then(([selectedProducts, defaultProducts]) => {
    const [selectedBasicProducts, selectedOptionalProducts] = split(
      customize(selectedProducts, customizations?.products),
    );

    const [defaultsBasic, defaultsOptional] = split(
      customize(concat(defaultProducts.basic, defaultProducts.optional), customizations?.products),
    );

    // reject suggested product if user already selected one for given category
    const productsDeselected = reject(defaultsOptional, ({ category }) =>
      includes(map(selectedOptionalProducts, 'category'), category),
    );

    return {
      all: {
        basic: selectedBasicProducts,
        defaults: {
          basic: defaultsBasic,
          optional: sort(defaultsOptional),
        },
        optional: sort(concat(productsDeselected, selectedOptionalProducts)),
      },
      selected: {
        basicProducts: selectedBasicProducts,
        optionalProducts: selectedOptionalProducts,
      },
    };
  });

const fetchAll = (params: {
  pricebookId?: string;
  category: ProductCategory;
  type?: FuneralType;
  plan?: FuneralPlan;
}) => api.get<ProductItem[]>(`/${SERVICE_ROUTE}/find`, { params }).then(({ data }) => data);

export default {
  fetch,
  fetchAll,
  fetchDefault,
  fetchSelected,
  parse,
  parseProductsAlongStore,
  split,
};
