import { fetchFuneralSite } from 'actions/funeralSites';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { css, mq, styled } from 'mymoria-ui';
import { Caption, Card, Col, Heading, Icon, Loader, Row, Text } from 'mymoria-ui/components';
import PlaceSearch, { Place } from 'mymoria-ui/components/Form/PlaceSearch';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { getFuneralSiteById } from 'reducers/entities';
import { FuneralType, FuneralSite, iconTypes, FuneralPlan } from 'types';
import { sprintf } from 'utils';
import FuneralSiteDetails from './FuneralSiteDetails';
import FuneralSiteGraves from './FuneralSiteGraves';
import RecommendationBox from './RecommendationBox/index';

type InfoBoxMode = 'search' | 'graves' | 'details';

interface FuneralSiteInfoBoxProps {
  funeralType: FuneralType;
  funeralPlan: FuneralPlan;
  onClose: () => void;
  onPlaceChange: (place: Place) => void;
  originFuneralSite?: string;
  onGraveTypeChange: (value: string) => void;
  selectedGraveType?: string;
  onFuneralSiteDelete: () => void;
  selectedFuneralSite?: string;
  onRecommendationSelect: (iconType?: iconTypes, funeralSite?: FuneralSite) => void;
  treeClosestFuneralSite?: FuneralSite;
}

const StyledCard = styled(Card)<{ mode: InfoBoxMode }>(
  ({ mode, theme: { components, colors } }) => css`
    && {
      margin: 0.5rem 0.5rem 0;
      padding: 12px;
      position: absolute;
      box-sizing: border-box;
      overflow-y: ${mode === 'search' ? 'initial' : 'auto'};
      min-height: 80px;
    }

    #icon-close {
      cursor: pointer;
      top: 1rem;
      right: 1rem;

      & > path {
        fill: ${colors.primary};
      }
    }

    ${mq({
      backgroundColor: [
        mode === 'search' ? 'transparent' : components.cards.backgroundColor,
        components.cards.backgroundColor,
      ],
      borderRadius: [0, components.cards.borderRadius],
      bottom: [mode === 'search' ? 'unset' : 0, 'unset'],
      boxShadow: ['none', components.cards.boxShadow],
      left: [0, '2.5rem'],
      maxHeight: ['none', '85%'],
      right: [0, 'unset'],
      top: [0, '2.5rem'],
      width: ['unset', 300, 400, 500],
    })}
  `,
);

const StyledPlaceSearch = styled(PlaceSearch)`
  margin: 0;
`;

const FuneralSiteInfoBox: FunctionComponent<FuneralSiteInfoBoxProps> = ({
  funeralType,
  funeralPlan,
  onClose,
  onPlaceChange,
  onGraveTypeChange,
  selectedGraveType,
  selectedFuneralSite,
  onFuneralSiteDelete,
  onRecommendationSelect,
  treeClosestFuneralSite,
  originFuneralSite,
}) => {
  const dispatch = useDispatch();
  const funeralSite = useSelector(getFuneralSiteById(selectedFuneralSite || ''));
  const [mode, setMode] = useState<InfoBoxMode>('search');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedFuneralSite) {
      setMode('search');
    } else if (originFuneralSite === selectedFuneralSite) {
      setMode('details');
    } else {
      setMode('graves');
    }
  }, [originFuneralSite, selectedFuneralSite]);

  useLayoutEffect(() => {
    if (selectedFuneralSite && !funeralSite && selectedFuneralSite !== treeClosestFuneralSite?.id) {
      setIsLoading(true);
      dispatch(fetchFuneralSite(selectedFuneralSite))
        .catch(err => setError(err.message))
        .finally(() => setIsLoading(false));
    }
  }, [selectedFuneralSite, funeralSite, dispatch, treeClosestFuneralSite]);

  const handleGraveTypeEdit = () => {
    setMode('graves');
  };

  const handleFuneralSiteDelete = () => {
    onFuneralSiteDelete();
    setMode('search');
  };

  return (
    <StyledCard mode={mode}>
      {mode === 'search' ? (
        <>
          <StyledPlaceSearch label={t.global.forms.place} onChange={onPlaceChange} />
          {funeralType !== 'burial' && (
            <RecommendationBox
              originFuneralSite={originFuneralSite}
              funeralType={funeralType}
              handleFuneralSiteChange={(iconType, funeralSite) => {
                onRecommendationSelect(iconType, funeralSite);
              }}
            />
          )}
        </>
      ) : (
        <Row flexDirection="column" justifyContent="space-between">
          {isLoading && <Loader delay={750} />}
          {error && <Text>{error}</Text>}
          {!isLoading && !error && funeralSite && (
            <>
              <Row>
                <Col width={1}>
                  <Heading.Heading3 pr={6}>{funeralSite.title}</Heading.Heading3>
                  <Caption>{`${funeralSite.address.street}, ${funeralSite.address.plz} ${funeralSite.address.city}`}</Caption>
                </Col>
                <Icon.Close id="icon-close" onClick={onClose} />
              </Row>
              {mode === 'graves' && (
                <FuneralSiteGraves
                  funeralSite={funeralSite}
                  funeralType={funeralType}
                  funeralPlan={funeralPlan}
                  graveType={selectedGraveType}
                  onChange={onGraveTypeChange}
                />
              )}
              {mode === 'details' && (
                <FuneralSiteDetails
                  funeralSite={funeralSite}
                  funeralType={funeralType}
                  graveType={selectedGraveType}
                  onEdit={handleGraveTypeEdit}
                  onDelete={handleFuneralSiteDelete}
                />
              )}
              <Col width={1}>
                {funeralSite.organisation?.name && (
                  <Caption mt={2}>
                    {sprintf(t.googleMap.infoBox.organisationName, {
                      source: funeralSite.organisation?.name,
                    })}
                  </Caption>
                )}
              </Col>
            </>
          )}
        </Row>
      )}
    </StyledCard>
  );
};

export default FuneralSiteInfoBox;
