import { ReactComponent as FuneralTypeIcon } from 'assets/stepIcons/funeral_type_step.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import { useSelector } from 'hooks';
import React from 'react';
import { sprintf } from 'utils';

const FuneralTypeTitle = () => {
  const concernType = useSelector(({ proposal }) => proposal.concernType);
  const relationship = useSelector(({ proposal }) => proposal.relationship);

  const title =
    relationship === 'myself'
      ? t.funnel.funeralType.title.provision
      : sprintf(t.funnel.funeralType.title[concernType], {
          relationship:
            t.relationship[
              concernType === 'occured'
                ? 'akkusativeWithPossessivePronounBusinessPerspective'
                : 'nominativeWithPossessivePronounBusinessPerspective'
            ][relationship],
        });

  return <StepTitle icon={<FuneralTypeIcon />} hint={t.funnel.funeralType.hint} title={title} />;
};

export default FuneralTypeTitle;
