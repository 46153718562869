import { changeContactUsInformation } from 'actions/proposal';
import { Heading, Text, ActionButton, Loader, Col } from 'components';
import config from 'config';
import { FunnelButton } from 'containers/Funnel';
import { global, funnel, relationship } from 'data';
import { useSelector, useDispatch, useWizard } from 'hooks';
import { useToast } from 'mymoria-ui/hooks';
import { styled, css } from 'mymoria-ui/utils';
import React, { useState, ChangeEvent } from 'react';
import { DeceasedLocation } from 'types';
import { sprintf } from 'utils';
import NoteComponent from '../NoteComponent';

const { additionalInfoMaxLength } = config;

const Hint = styled.aside(
  ({ theme: { colors, space, fontSizes } }) => css`
    color: ${colors.red};
    font-size: ${fontSizes.small};
    padding: ${space[1]} ${space.large};
  `,
);

interface Props {
  onBackClick: () => void;
}

const Options = ({ onBackClick }: Props) => {
  const dispatch = useDispatch();
  const { goNextPage } = useWizard();
  const { addToast } = useToast();
  const proposalAdditionalInfo = useSelector(({ proposal }) => proposal.additionalInfo);
  const proposalDeceasedLocation = useSelector(({ proposal }) => proposal.deceasedLocation);
  const relationShip = useSelector(({ proposal }) => proposal.relationship);

  const [formValues, setFormValues] = useState({
    deceasedLocation: proposalDeceasedLocation,
    deceasedLocationError: false,
    isLoading: false,
    textarea: proposalAdditionalInfo,
    textareaError: false,
  });

  const validateTextArea = (value?: string) => (value?.length || 0) > additionalInfoMaxLength;

  const handleSubmit = () => {
    const isTextAreaError = validateTextArea(formValues.textarea);

    setFormValues({
      ...formValues,
      deceasedLocationError: !formValues.deceasedLocation,
      textareaError: isTextAreaError,
    });

    if (isTextAreaError) {
      return;
    }

    if (formValues.deceasedLocation) {
      setFormValues({ ...formValues, isLoading: true });

      dispatch(
        changeContactUsInformation({
          additionalInfo: formValues.textarea,
          deceasedLocation: formValues.deceasedLocation,
        }),
      )
        .then(() => {
          setFormValues({ ...formValues, isLoading: false });
          goNextPage();
        })
        .catch(({ message }) => {
          setFormValues({ ...formValues, isLoading: false });
          addToast(message);
        });
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value) {
      setFormValues({ ...formValues, textarea: value, textareaError: validateTextArea(value) });
    } else {
      setFormValues({ ...formValues, textarea: '' });
    }
  };

  return (
    <>
      {formValues.isLoading && <Loader overlay />}
      <Heading.Heading3 fontWeight="extraBold" mb="2rem" textAlign="center">
        {global.contactUs.occured.optionsTitle}
      </Heading.Heading3>
      <Text mt="extraLarge" mb="small" fontWeight="bold" textAlign="center">
        {sprintf(global.contactUs.occured.deceasedLocationTitle, {
          relationship:
            relationShip !== 'myself' &&
            relationship.nominativeWithPossessivePronounBusinessPerspective[relationShip],
        })}
      </Text>
      <Col display="flex" flexWrap="wrap" mb="extraLarge">
        {funnel.deceasedLocation.deceasedLocations.map(({ value, label }) => (
          <Col key={value} width={[1, 1, 1 / 2]} my={1}>
            <FunnelButton
              type="button"
              selected={value === formValues.deceasedLocation}
              onClick={() =>
                setFormValues({
                  ...formValues,
                  deceasedLocation: value as DeceasedLocation,
                  deceasedLocationError: false,
                })
              }
            >
              {label}
            </FunnelButton>
          </Col>
        ))}
      </Col>
      {formValues.deceasedLocationError && <Hint>{global.validators.required}</Hint>}
      <NoteComponent
        rows={4}
        placeholder={global.contactUs.contact.textAreaPlaceholder}
        label={global.contactUs.contact.textAreaTitle}
        value={formValues.textarea}
        onChange={handleOnChange}
        error={sprintf(global.validators.max, { max: additionalInfoMaxLength })}
        validation={formValues.textareaError}
        maxLength={additionalInfoMaxLength}
      />
      <ActionButton mt="extraLarge" block onClick={handleSubmit}>
        {global.contactUs.contact.submit}
      </ActionButton>
      <FunnelButton mt="tiny" outline block onClick={onBackClick}>
        {global.contactUs.callback.back}
      </FunnelButton>
    </>
  );
};

export default Options;
