import { form } from 'data';
import { CallbackTime, Salutation, ContactUsTypes } from 'types';

interface Option<T> {
  value: T;
  label: string;
  hintText?: string;
}

export const salutationOptions: Option<Salutation>[] = [
  { label: form.salutation.sir, value: 'sir' },
  { label: form.salutation.madame, value: 'madame' },
];

export const callbackTimeOptions: Option<CallbackTime>[] = [
  { label: form.callbackTime.morning, value: 'morning' },
  { label: form.callbackTime.lunchtime, value: 'lunchtime' },
  { label: form.callbackTime.afternoon, value: 'afternoon' },
  { label: form.callbackTime.evening, value: 'evening' },
];

export const callbackTimeOptionsExtended: Option<CallbackTime>[] = [
  { label: form.callbackTime.asap, value: 'asap' },
  { label: form.callbackTime.morning, value: 'morning' },
  { label: form.callbackTime.lunchtime, value: 'lunchtime' },
  { label: form.callbackTime.afternoon, value: 'afternoon' },
  { label: form.callbackTime.evening, value: 'evening' },
];

export const contactUsOptions: Option<ContactUsTypes>[] = [
  { label: form.contactUs.call, value: 'call' },
  { label: form.contactUs.whatsapp, value: 'whatsapp' },
  { label: form.contactUs.email, value: 'email' },
];
