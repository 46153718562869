import { Caption, CardProps, Legend, Markdown, Hr, PriceRow } from 'components';
import Collapse from 'components/Collapse';
import * as t from 'data';
import { formatPrice, getFuneralSitePrice } from 'helpers';
import { useCMSSelector, useSelector, useAllOfferItems, useHistory } from 'hooks';
import { css, styled, mq } from 'mymoria-ui';
import { Row, EditButton, Text } from 'mymoria-ui/components';
import React, { FunctionComponent, HTMLAttributes, useState, useRef, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { getFuneralSiteById } from 'reducers/entities';
import { isOfferEditable } from 'reducers/proposal';
import { sprintf } from 'utils';
import InstallmentInfoBox from './InstallmentInfoBox';
import OfferPageCard from '../OfferPageCard';

interface OfferSummeryProps extends CardProps {
  legalInformation?: string;
}

const StyledMarkdown = styled(Markdown)(
  ({ theme: { fontSizes } }) => css`
    margin-top: 1rem;
    p {
      font-size: ${fontSizes[1]};
      padding: 0;
    }

    a {
      display: inline;
    }
  `,
);

const StyledCaption = styled(Caption)`
  font-style: italic;
`;

const StickyPrice = styled(PriceRow)`
  position: sticky;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  background: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 12%);
  top: 0;

  ${mq({
    padding: ['1rem', '1rem 1.5rem'],
  })};
`;

const scrollToCard = (id: string) => {
  const section = document.getElementById(id);
  if (!section) return;

  section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const SummaryCard: FunctionComponent<OfferSummeryProps & HTMLAttributes<HTMLElement>> = ({
  variant = 'small',
  legalInformation,
}) => {
  const number = useSelector(({ proposal }) => proposal.number);
  const price = useSelector(({ proposal }) => proposal.price);
  const state = useSelector(({ proposal }) => proposal.state);
  const funeralSiteId = useSelector(({ proposal }) => proposal.funeralSite);
  const graveSites = useSelector(({ proposal }) => proposal.customizations?.graveSites);
  const graveType = useSelector(({ proposal }) => proposal.graveType);
  const funeralSite = useSelector(getFuneralSiteById(funeralSiteId));
  const funeralSitePrice = getFuneralSitePrice(funeralSite, graveType);
  const discount = useCMSSelector(({ OfferPageSettings }) => +OfferPageSettings?.discount);
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const city = useSelector(({ proposal }) => proposal.city);
  const isEditable = useSelector(isOfferEditable());
  const concernType = useSelector(({ proposal }) => proposal.concernType);
  const relationship = useSelector(({ proposal }) => proposal.relationship);
  const deceasedFirstName = useSelector(({ proposal }) => proposal.deceasedFirstName);
  const deceasedLastName = useSelector(({ proposal }) => proposal.deceasedLastName);
  const deceasedCode = useSelector(({ proposal }) => proposal.deceasedCode);
  const deceasedCity = useSelector(({ proposal }) => proposal.deceasedCity);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { handlePush } = useHistory();
  const { url } = useRouteMatch();
  const offerItems = useAllOfferItems();
  const sortedOfferItems = offerItems.sort((a, b) => {
    if (a.sortOrder && b.sortOrder && state === 'customized') {
      return a.sortOrder - b.sortOrder;
    }
    return 0;
  });

  const customizedSortedItems = [
    ...offerItems.map(item => ({
      id: item.id,
      key: item.name,
      label: item.name,
      quantity: item.quantity,
      sortOrder: item.sortOrder || 0,
      type: 'product',
      value: item.totalPrice ? item.totalPrice : item.price,
    })),
    ...(graveSites?.map(item => ({
      id: funeralSiteId,
      key: item.product2Id,
      label: item.translations.shortname,
      quantity: 1,
      sortOrder: item.sortOrder || 0,
      type: 'graveSite',
      value: formatPrice(item.price),
    })) || []),
  ].sort((a, b) => {
    if (state === 'customized') {
      return a.sortOrder - b.sortOrder;
    }
    return 0;
  });

  const priceRef = useRef<HTMLDivElement>(null);
  const stickyPriceRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);
  const displayedCity = deceasedCode && deceasedCity ? deceasedCity : city;

  const prepareSubtitle = () => {
    if (concernType === 'provision') {
      return t.offer.subTitle.fallback;
    }
    if (deceasedFirstName && deceasedLastName) {
      return sprintf(t.offer.subTitle[concernType].deceasedKnown, {
        deceasedFirstName,
        deceasedLastName,
      });
    }
    if (relationship) {
      return sprintf(t.offer.subTitle[concernType].deceasedUnknown, {
        relationship: t.relationship.genitiveWithPossessivePronounBusinessPerspective[relationship],
      });
    }
    return t.offer.subTitle.fallback;
  };

  useLayoutEffect(() => {
    const DISTANCE = 16; // Distance between total price and hr element
    const scrollHandler = () => {
      const totalPrice = priceRef.current;
      const stickyPrice = stickyPriceRef.current;
      const endOfCard = indicatorRef.current;

      if (totalPrice && stickyPrice && endOfCard) {
        const isCardCollapsed = totalPrice.className.includes('collapsed');
        const endOfCardPosition = endOfCard.getBoundingClientRect().top;
        const totalPricePosition = totalPrice.getBoundingClientRect().top;
        if (isCardCollapsed ? endOfCardPosition < 0 : totalPricePosition <= DISTANCE) {
          stickyPrice.style.display = 'flex';
        } else {
          stickyPrice.style.display = 'none';
        }
      }
    };

    scrollHandler();
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <>
      <StickyPrice
        priceRef={stickyPriceRef}
        large
        label={t.offer.offerSummary.totalPrice}
        value={formatPrice(price)}
      />
      <OfferPageCard variant={variant}>
        {isCollapsed && (
          <PriceRow
            label={t.offer.offerSummary.totalPrice}
            value={formatPrice(price)}
            large
            pb={1}
          />
        )}
        <Text fontSize={['small', 'large']} fontFamily="tertiary" fontWeight="extraBold" pb="4px">
          {sprintf(state ? t.offer.offerSummary.title : t.offer.offerSummary.createModeTitle, {
            number: number ? number : '',
          })}
        </Text>
        <Text fontSize={['small', 'large']} fontFamily="tertiary" fontWeight="medium">
          {prepareSubtitle()}
        </Text>
        <Collapse onCollapse={() => setIsCollapsed(true)} onExpand={() => setIsCollapsed(false)}>
          <Hr />
          {typeof state === 'undefined' && !!discount && (
            <Legend withSeparator>
              {sprintf(t.offer.discount.title, {
                discount,
              })}
            </Legend>
          )}
          {customizedSortedItems.map(item => (
            <PriceRow
              key={item.key}
              mb={1}
              quantity={item.quantity}
              label={item.label}
              value={item.value}
              onClick={() => scrollToCard(item.id)}
            />
          ))}
          {state !== 'customized' && funeralSitePrice ? (
            <PriceRow
              label={t.offer.offerSummary.servicesPlaceOfDelivery}
              value={funeralSitePrice ? formatPrice(funeralSitePrice) : 'extern'}
              mb={1}
              onClick={() => scrollToCard(funeralSiteId)}
            />
          ) : null}
          {sortedOfferItems.length > 0 && <Hr />}
          <PriceRow
            className={`total-price ${isCollapsed ? 'collapsed' : ''}`}
            label={t.offer.offerSummary.totalPrice}
            value={formatPrice(price)}
            large
            pb={5}
            priceRef={priceRef}
          />
          <Row>
            <StyledCaption>{t.offer.offerSummary.includeTax}</StyledCaption>
            <InstallmentInfoBox />
          </Row>
          {funeralSite && <StyledCaption>{t.offer.offerSummary.externalCost}</StyledCaption>}
          {(state === 'postcheckout' || state === 'customized') && (
            <>
              <StyledMarkdown source={legalInformation} />
              {state === 'customized' && (
                <StyledMarkdown mb={3} width={[1, 3 / 4]} source={t.offer.customized.legalText} />
              )}
            </>
          )}
          <StyledCaption>{t.offer.offerSummary.burialFeesNote}</StyledCaption>
          {!state && discount > 0 && (
            <StyledCaption>
              {sprintf(t.offer.discount.footNote, { amount: discount })}
            </StyledCaption>
          )}
        </Collapse>
        <Row justifyContent="space-between" flexWrap="wrap">
          <Markdown letterSpacing={0.78} pr={3}>
            {`${t.offer.generalInformation.offerDetails.city}: **${displayedCity}**`}
          </Markdown>
          <Markdown letterSpacing={0.78}>
            {`${t.offer.generalInformation.offerDetails.funeralType}: **${t.offer.funeralType[funeralType]}**`}
          </Markdown>
        </Row>
        {isEditable && <EditButton mt={4} onClick={handlePush(`${url}/basic-information`)} />}
      </OfferPageCard>
      <div ref={indicatorRef} />
    </>
  );
};

export default SummaryCard;
