import { Markdown, Caption } from 'components';
import { offer } from 'data';
import { styled } from 'mymoria-ui';
import { Button } from 'mymoria-ui/components/Button';
import { useModal } from 'mymoria-ui/hooks';
import React from 'react';

const StyledButton = styled(Button)`
  & > * {
    text-decoration: underline;
  }
`;

const InstallmentInfoBox = () => {
  const { showModal: showInstalmentModal, Modal: InstalmentModalHook } = useModal();

  return (
    <>
      <StyledButton shadow={false} ghost paddingX={1} onClick={showInstalmentModal}>
        <Caption>{offer.offerSummary.instalmentMessage}</Caption>
      </StyledButton>
      <InstalmentModalHook>
        <Markdown source={offer.offerSummary.instalmentModal} />
      </InstalmentModalHook>
    </>
  );
};

export default InstallmentInfoBox;
