import { routerActions as router } from 'connected-react-router';
import { default as funeralSites } from './funeralSites';
import { default as graveTypes } from './graveTypes';
import { default as products } from './products';
import { default as proposal } from './proposal';
import { default as tracking } from './tracking';

export default {
  funeralSites,
  graveTypes,
  products,
  proposal,
  router,
  tracking,
};
