import { Icon, Heading, Markdown } from 'components';
import { global } from 'data';
import { useHistory, useSelector } from 'hooks';
import { styled, css } from 'mymoria-ui/utils';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getCustomerPhone } from 'reducers/proposal';
import { sprintf } from 'utils';

import MobileButton from '../../MobileButton';

const StyledMarkdown = styled(Markdown)(
  ({ theme: { fontWeights } }) => css`
    a {
      text-decoration: underline;
      font-weight: ${fontWeights.bold};
    }
  `,
);

const Result = () => {
  const phone = useSelector(getCustomerPhone);
  const { url } = useRouteMatch();
  const { push } = useHistory();

  return (
    <>
      <Icon.GreenCheck width="40px" height="40px" />
      <Heading.Heading3 mt="normal" fontWeight="extraBold" mb="small" textAlign="center">
        {global.contactUs.callback.result.title}
      </Heading.Heading3>
      <StyledMarkdown
        fontWeight="medium"
        textAlign="center"
        mb="small"
        children={sprintf(global.contactUs.callback.result.msg, {
          customerPhone: phone,
          url: `${url}/contact-details`,
        })}
      />
      <MobileButton
        mt="4rem"
        label={global.contactUs.editInfoButton}
        onClick={() => push(`${url}/contact-details`)}
      />
    </>
  );
};
export default Result;
