import { Proposal } from 'types';

export const FUNNEL_STEPS = [
  'concern-type',
  'relationship',
  'place',
  'deceased-location',
  'type',
  'character',
  'data',
] as const;

export type FunnelStep = (typeof FUNNEL_STEPS)[number];

export interface FunnelAdditionalData {
  skip?: boolean;
  skipIntro?: boolean;
  steps: FunnelStep[];
  extra?: string;
}

export interface FunnelTrackingData extends Partial<Proposal> {
  skipped_steps?: string[];
}
