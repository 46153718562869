import actions from 'actions';
import { isEmpty } from 'lodash';
import { ApplicationState, EntitiesState } from 'types';
import { createReducer } from 'typesafe-actions';

const { fetchGraveTypes } = actions.graveTypes;
const { createProposal, fetchProposal, fetchProposalAdditionalData, changeBasicInformation } =
  actions.proposal;
const { fetchFuneralSite } = actions.funeralSites;
const { fetchProduct, fetchAlternativeProducts } = actions.products;

const initialState: EntitiesState = {
  funeralSites: {},
  graveTypes: {},
  products: {},
  services: {},
};

export default createReducer(initialState)
  .handleAction(
    [createProposal, fetchProposal, fetchProposalAdditionalData, changeBasicInformation],
    (state, { payload: { entities } }) => ({
      ...state,
      funeralSites: entities.funeralSites
        ? { ...state.funeralSites, ...entities.funeralSites }
        : state.funeralSites,
      products: { ...state.products, ...entities.products },
      services: { ...state.services, ...entities.services },
    }),
  )
  .handleAction([fetchProduct, fetchAlternativeProducts], (state, { payload: { entities } }) => ({
    ...state,
    products: { ...state.products, ...entities.products },
  }))
  .handleAction(fetchGraveTypes, (state, { payload: { entities } }) => ({
    ...state,
    graveTypes: { ...state.graveTypes, ...entities.graveTypes },
  }))
  .handleAction(fetchFuneralSite, (state, { payload: funeralSite }) => ({
    ...state,
    funeralSites: { ...state.funeralSites, [funeralSite.id]: funeralSite },
  }));

export const getGraveTypeById =
  (id: string) =>
  ({ entities }: ApplicationState) =>
    entities.graveTypes[id];

export const getFuneralSiteById =
  (id: string) =>
  ({ entities }: ApplicationState) =>
    entities.funeralSites[id];

export const isOfferLoaded = ({ entities, proposal }: ApplicationState) =>
  !isEmpty(entities.products) && !isEmpty(entities.services) && !isEmpty(proposal);
