import config from 'config';
import { get, isArray, isEmpty, find, endsWith, pick, without } from 'lodash';
import { cdn } from 'mymoria-ui/utils';
import {
  FuneralSite,
  Marker as MarkerType,
  Service,
  ProductsState,
  Entity,
  Product,
  ProductCategory,
  TrackingParams,
  TrackingParamsRaw,
} from 'types';
import { ObjectSchema, ValidateOptions } from 'yup';
import { formatPrice } from './formatters';

export * from './formatters';
export * from './validationSchemas';

const { germanTax } = config;

export const findBasicService = (services: { [id: string]: Service }) =>
  find(services, ({ identifier }) => endsWith(identifier, 'service-basic'));

export const formatPostalCode = (code: number) => code.toString().padStart(5, '0');

export const getConsentFromCookies = (
  cookies: {
    [name: string]: any;
  },
  cookieSiteSlug: string,
) => {
  let consent: { [key: string]: boolean } = {};

  if (cookies) {
    for (const [key, value] of Object.entries(cookies)) {
      if (key.startsWith(cookieSiteSlug)) {
        consent[key.replace(cookieSiteSlug, '')] = value === 'true';
      }
    }
  }

  return consent;
};

export const getEstimatedGravePrice = (funeralSite: FuneralSite, graveType: string) => {
  const prices = funeralSite.fees.prices[graveType];

  if (prices?.selling_price) {
    return formatPrice(prices?.selling_price, { gross: true });
  }

  return '';
};

export const getFuneralSitePrice = (funeralSite: FuneralSite, graveType: string = '') =>
  get(funeralSite, `fees.prices[${graveType}].selling_price`, 0) * germanTax;

//todo: fix any
export const getFuneralSitePrices = (
  { selling_price, info_price, base_price } = {} as any,
  sellingOnly = false,
) =>
  selling_price !== null && !isNaN(selling_price) // 0 price is correct value
    ? selling_price * germanTax
    : sellingOnly
    ? null
    : !isNaN(info_price)
    ? info_price
    : base_price;

export const getIconKey = (
  { types: [type] = [] as any },
  isSelected: boolean,
  isOrigined: boolean,
) => {
  if (!type) {
    return '';
  }

  const key = [
    type === 'cremation' ? 'burial' : type,
    isOrigined ? '-origined' : '',
    isSelected ? '-selected' : '',
  ].join('');

  return { url: cdn('www', `/img/markers/${key}.${isSvgSupported() ? 'svg' : 'png'}`) };
};

export const getItemsByCategory = (
  list: Pick<Entity<Product>, string>,
  itemCategory: ProductCategory,
) => Object.values(list).filter(({ category }) => category === itemCategory) || ({} as Product);

export const getOptionalDefaultProductId = (
  allProducts: Entity<Product>,
  products: ProductsState,
  itemCategory?: ProductCategory,
) => {
  const defaultItems = pick(allProducts, products.defaults.optional);

  const defaultProduct =
    Object.values(defaultItems).find(({ category }) => category === itemCategory) ||
    ({} as Product);

  if (!defaultProduct) {
    return undefined;
  }

  return defaultProduct.id;
};

export const getQueryParams = <T extends object>(
  search: string,
  schema: ObjectSchema,
  options?: ValidateOptions,
) => {
  const params = parseQueryParams(search, Object.keys(schema.fields));

  if (isEmpty(params)) {
    return {} as T;
  }

  return schema.validateSync(params, options || { stripUnknown: true }) as T;
};

export const getRootDomain = () => {
  if (window?.location.hostname === 'localhost' || window?.location.hostname === '127.0.0.1') {
    return window?.location.hostname;
  }

  return `.${window?.location.hostname.split('.').slice(-2).join('.')}`;
};

export const getSortedSelectedProducts = (
  alternatives: string[],
  basicProducts: string[],
  optionalProducts: string[],
) => {
  const selectedIDs = alternatives.filter(id =>
    [...basicProducts, ...optionalProducts].includes(id),
  );
  const allNonSelected = without(alternatives, ...selectedIDs);

  return [...selectedIDs, ...allNonSelected];
};

export const isSvgSupported = () =>
  document.implementation.hasFeature('http://www.w3.org/TR/SVG2/feature#GraphicsAttribute', 2.0);

export const parseMarkerInternal = (marker: MarkerType) => ({
  ...marker,
  position: { lat: marker.latitude, lng: marker.longitude },
});

type Params = keyof TrackingParams | TrackingParamsRaw;

export const parseQueryParams = (
  query: string,
  params: string | string[],
  decodeParams: Params[] = [],
) => {
  const queryParams = new URLSearchParams(query);
  const parsedParams: { [key: string]: string } = {};

  (isArray(params) ? params : [params]).forEach(param => {
    const value = queryParams.get(param);

    if (value) {
      // Decode the parameter only if it is in the decodeParams list
      parsedParams[param] = decodeParams.includes(param as Params) ? decodeURI(value) : value;
    }
  });

  return parsedParams;
};
