import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Funnel } from './Funnel';
import { useSkipSteps } from './Funnel/hooks';
import { IntroStep } from './Funnel/steps';

const FunnelRouter: FunctionComponent<RouteComponentProps> = props => {
  useSkipSteps(props);

  return (
    <Switch>
      <Route path={`${props.match.path}/intro`} component={IntroStep} />
      <Route path={`${props.match.path}/:step`} component={Funnel} />
    </Switch>
  );
};

export default FunnelRouter;
