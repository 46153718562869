import { Message, Box } from 'components';
import {
  BasicInformationCard,
  FuneralSiteCard,
  SelectedOptionalItemsCard,
  ContactUsFormStickyButton,
  SummaryCard,
} from 'components/Offer';
import { offer } from 'data';
import { useTrackingLocation } from 'hooks';
import OfferPageLayout from 'layouts/OfferPageLayout';
import React from 'react';
import { Page, templates } from 'templates';

const OfferCustomizedPage = () => {
  useTrackingLocation('customized.offer');

  return (
    <Page {...templates.offerPageTemplate}>
      <OfferPageLayout
        children={{
          mainCol: (
            <>
              <SummaryCard />
              <Message status="error" mx={[1, 0]}>
                {offer.customized.message}
              </Message>
              <Box px={0} display={['block', null, 'none']}>
                <BasicInformationCard isMobileVersion />
              </Box>
              <SelectedOptionalItemsCard customized={true} />
              <FuneralSiteCard />
            </>
          ),
          stickyCol: <BasicInformationCard />,
        }}
      />
      <ContactUsFormStickyButton />
    </Page>
  );
};

export default OfferCustomizedPage;
