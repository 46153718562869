import { setFunnelData } from 'actions/proposal';
import { trackCtaClick, trackFunnelData } from 'actions/tracking';
import { Flex, Row, Text } from 'components';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { styled } from 'mymoria-ui';
import { FunnelRadioButtonGroup, FunnelRadioButton, Button } from 'mymoria-ui/components/Button';
import React, { FunctionComponent, useState } from 'react';
import { DeceasedLocation } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const visibleOptions = 6;
const options = t.funnel.deceasedLocation.deceasedLocations;

const StyledButton = styled(Button)(({ theme: { fontWeights } }) => ({
  fontWeight: fontWeights.bold,
}));

const DeceasedLocationStep: FunctionComponent<FunnelStepProps> = ({ next, onStepLoad }) => {
  const { deceasedLocation } = useSelector(({ proposal }) => proposal);
  const dispatch = useDispatch();
  const [isExtended, setIsExtended] = useState(
    options.findIndex(({ value }) => value === deceasedLocation) >= visibleOptions,
  );
  onStepLoad();

  const handleSubmit = async (deceasedLocation: DeceasedLocation) => {
    dispatch(setFunnelData({ deceasedLocation }));
    dispatch(trackFunnelData({ deceasedLocation }));
    await sleep(ANIMATION_DELAY);
    next();
  };

  return (
    <Row flexWrap="wrap" justifyContent="center" width={1}>
      <FunnelRadioButtonGroup
        name="deceasedLocation"
        defaultValue={deceasedLocation || ''}
        onChange={e => handleSubmit(e.target.value as DeceasedLocation)}
      >
        {(isExtended ? options : options.slice(0, visibleOptions)).map(({ value, label }) => (
          <Flex key={value} width={[1, 1 / 2, null, 1 / 3]} my={1} px={['unset', 1]}>
            <FunnelRadioButton value={value} padding={['1.5rem', '1.75rem 1rem']}>
              <Text
                fontSize="normal"
                fontWeight="bold"
                textAlign="center"
                whiteSpace="break-spaces"
              >
                {label}
              </Text>
            </FunnelRadioButton>
          </Flex>
        ))}
      </FunnelRadioButtonGroup>
      {!isExtended && (
        <Flex width={1} my={2} px={['unset', 1]} fontSize="tiny">
          <StyledButton
            block
            onClick={() => {
              dispatch(trackCtaClick('show_more_locations'));
              setIsExtended(true);
            }}
          >
            {t.funnel.relationship.extendOptions}
          </StyledButton>
        </Flex>
      )}
    </Row>
  );
};

export default DeceasedLocationStep;
