import { Loader } from 'components';
import React from 'react';
import { Page, templates } from 'templates';

const LoadingPage = () => (
  <Page {...templates.loadingPageTemplate}>
    <Loader />
  </Page>
);

export default LoadingPage;
