import { formatPrice } from 'helpers/formatters';
import { Product, Service, Entity } from 'types';
import { useSelector, useBasicServices } from './index';

const getItems = (
  ids: string[],
  entities: Entity<Service | Product>,
  fallback?: Service | Product,
  priceFunc?: () => string,
) =>
  ids.reduce((result, id) => {
    const foundEntity = entities[id];
    if (foundEntity) {
      return result.concat({
        id,
        name: foundEntity.translations.shortname,
        price: priceFunc ? priceFunc() : formatPrice(foundEntity.price),
        quantity: foundEntity.quantity,
        sortOrder: foundEntity.sortOrder || 0,
        totalPrice: foundEntity.totalPrice ? formatPrice(foundEntity.totalPrice) : undefined,
      });
    }

    return fallback
      ? result.concat({
          id,
          name: fallback.translations?.shortname,
          price: formatPrice(fallback.price),
          quantity: fallback.quantity,
          totalPrice: fallback.totalPrice ? formatPrice(fallback.totalPrice) : undefined,
        })
      : result;
  }, [] as { id: string; name: string; price: string; quantity?: number; totalPrice?: string; sortOrder?: number }[]);

export default () => {
  const { basicServices, basicProducts, optionalServices, optionalProducts } = useSelector(
    ({ proposal }) => proposal,
  );
  const basic = useBasicServices();
  const services = useSelector(({ entities }) => entities.services);
  const products = useSelector(({ entities }) => entities.products);
  const extras =
    useSelector(({ proposal }) => proposal.extras)?.map(
      ({ id, translations: { shortname }, price, quantity, totalPrice, sortOrder }) => ({
        id,
        name: shortname,
        price: formatPrice(price),
        quantity,
        sortOrder: sortOrder || 0,
        totalPrice: totalPrice ? formatPrice(totalPrice) : undefined,
      }),
    ) || [];

  const selectedServices = basic
    ? getItems(basicServices, { [basic.id]: basic }, basic, () => formatPrice(basic.price))
    : [];

  const selectedProducts = getItems(basicProducts, products);
  const selectedOptionalServices = getItems(optionalServices, services);
  const selectedOptionalProducts = getItems(optionalProducts, products);

  return [
    ...selectedServices,
    ...selectedProducts,
    ...selectedOptionalServices,
    ...selectedOptionalProducts,
    ...extras,
  ];
};
