import actions from 'actions';
import { TrackingState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: TrackingState = {
  clientId: '',
  clientidGclid: '',
  landingPageUrl: '',
  oppLocation: '',
  oppOwner: '',
  sessionID: '',
  sourceCookie: '',
  userID: '',
  utmCampaign: '',
  utmContent: '',
  utmId: '',
  utmKeyword: '',
  utmMedium: '',
  utmSource: '',
};

const { setTrackingParams, setTrackingCookies } = actions.tracking;
const { fetchProposal } = actions.proposal;

export default createReducer(initialState)
  .handleAction([setTrackingParams, setTrackingCookies], (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction([fetchProposal], (state, { payload }) => ({
    ...state,
    oppLocation: payload.result.proposal.oppLocation || '',
  }));
