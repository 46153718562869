import {
  DL_INIT,
  trackLocation,
  activateGoogleOptimize,
  initializeDataLayer,
} from 'actions/tracking';
import config from 'config';
import { getConsentFromCookies } from 'helpers';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useDispatch } from './index';

const { cookieSiteSlug } = config;

const getVirtualPath = (eventName?: string) => {
  switch (eventName) {
    case 'create.contact_layer':
      return '/offer/contact_layer';

    case 'saved.offer':
      return '/offer/saved';

    case 'saved.accept_layer':
      return '/offer/saved/accept_layer';

    default:
      return undefined;
  }
};

export default (name?: string, group = 'offer-page') => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const path = getVirtualPath(name) || pathname;
  const [isGTMLoaded, setGTMLoaded] = useState(false);
  const [cookies] = useCookies();

  useEffect(() => {
    if (isGTMLoaded) {
      dispatch(trackLocation({ contentGroup1: group, name: name || pathname, path }));
      dispatch(activateGoogleOptimize());
    }
  }, [dispatch, path, name, group, pathname, isGTMLoaded]);

  useEffect(() => {
    const handleGtmLoadState = () => {
      const consent = getConsentFromCookies(cookies, cookieSiteSlug);

      const dlInitIndex = window.dataLayer?.findIndex(({ event }) => event === DL_INIT);
      if (dlInitIndex === -1) {
        dispatch(initializeDataLayer(consent));
      }

      setGTMLoaded(true);
    };

    if (!!window.google_tag_manager) {
      handleGtmLoadState();
    } else {
      window.addEventListener('gtm_loaded', handleGtmLoadState, { once: true });
    }
    return () => document.removeEventListener('gtm_loaded', handleGtmLoadState);
  }, [cookies, dispatch]);
};
