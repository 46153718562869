import { styled } from 'mymoria-ui';
import { Col, Grid, Row } from 'mymoria-ui/components';
import React, { FunctionComponent, ReactNode } from 'react';

interface OfferPageLayoutProps {
  children: {
    mainCol?: ReactNode;
    stickyCol?: ReactNode;
    body?: ReactNode;
  };
}

const Sticky = styled.div(
  ({ theme: { space } }) => `
    top: 0;
    position: sticky;
    margin-bottom: ${space[3]};
  `,
);

const StyledGrid = styled(Grid)({
  maxWidth: '1482px',
});

const OfferPageLayout: FunctionComponent<OfferPageLayoutProps> = ({
  children: { mainCol, stickyCol, body },
}) => (
  <StyledGrid px={[0, 0, 0, 0]}>
    <Row>
      <Col width={[1, 1, 15 / 24]} data-testid="price" px={[0, 6]}>
        {mainCol}
      </Col>
      <Col width={[1, 1, 9 / 24]} px={[0, 6]} display={['none', null, 'block']}>
        <Sticky>{stickyCol}</Sticky>
      </Col>
    </Row>
    {body}
  </StyledGrid>
);

export default OfferPageLayout;
