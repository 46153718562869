import { Card } from 'mymoria-ui/components';
import { styled, mq } from 'mymoria-ui/utils';

const OfferPageCard = styled(Card)(
  ({
    theme: {
      components: { cards },
    },
  }) => mq({ borderRadius: [0, cards.borderRadius] }),
);

export default OfferPageCard;

OfferPageCard.defaultProps = {
  variant: 'small',
};
