import { funnelFields } from 'actions/proposal';
import { GTM_INIT_DATA_LAYER, trackFunnelData } from 'actions/tracking';
import { STEP_TO_TRACKING_NAME } from 'helpers/funnelSteps';
import { isEmpty, pick, difference } from 'lodash';
import { Middleware } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { FUNNEL_STEPS } from 'types';

const addGTMEvent = ((cache: any[]) => {
  const interval = setInterval(() => {
    if (typeof dataLayer !== 'undefined') {
      cache.forEach(event => dataLayer.push(event));
      cache.splice(0);
      clearInterval(interval);
    }
  }, 500);

  return (event: any) => {
    if (typeof dataLayer !== 'undefined' && isEmpty(cache)) {
      dataLayer.push(event);
    } else {
      cache.push(event);
    }
  };
})([]);

let data: any = {};
const googleTagMiddleware: Middleware = () => next => action => {
  if (action.meta?.track) {
    addGTMEvent(action.payload);
  }

  if (action.type === REHYDRATE) {
    data = pick(action.payload?.proposal, [...funnelFields, 'steps']);
  }

  if (!isEmpty(data) && action.type === GTM_INIT_DATA_LAYER) {
    if (data.steps) {
      const skippedSteps = difference(FUNNEL_STEPS, data.steps);
      data['skipped_steps'] = skippedSteps.map(step => STEP_TO_TRACKING_NAME[step]);
      delete data.steps;
    }
    addGTMEvent(trackFunnelData(data).payload);
    data = {};
  }

  return next(action);
};

export default googleTagMiddleware;
