import { setFunnelData } from 'actions/proposal';
import { trackCtaClick, trackFunnelData } from 'actions/tracking';
import { Flex, Row, Text } from 'components';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { FunnelRadioButtonGroup, FunnelRadioButton } from 'mymoria-ui/components/Button';
import React, { FunctionComponent } from 'react';
import { FuneralPlan } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const FuneralPlanStep: FunctionComponent<FunnelStepProps> = ({ next, onStepLoad }) => {
  const { funeralPlan, funeralType } = useSelector(({ proposal }) => proposal);
  const dispatch = useDispatch();
  onStepLoad();

  const handleSubmit = async (funeralPlan: FuneralPlan) => {
    dispatch(setFunnelData({ funeralPlan }));
    dispatch(trackCtaClick(funeralPlan));
    dispatch(trackFunnelData({ funeralPlan }));
    await sleep(ANIMATION_DELAY);
    next();
  };

  const options =
    funeralType === 'sea' ? t.funnel.funeralPlan.seaOptions : t.funnel.funeralPlan.options;

  return (
    <Row flexWrap="wrap" justifyContent="center" width={1}>
      <FunnelRadioButtonGroup
        name="funeralPlan"
        defaultValue={funeralPlan}
        onChange={e => handleSubmit(e.target.value as FuneralPlan)}
      >
        {options.map(({ value, label }) => (
          <Flex key={value} width={[1, 2 / 5]} my={1} px={['unset', 1]}>
            <FunnelRadioButton value={value} padding={['1.5rem', '1.75rem 1rem']}>
              <Text
                fontSize="normal"
                fontWeight="bold"
                textAlign="center"
                whiteSpace="break-spaces"
              >
                {label}
              </Text>
            </FunnelRadioButton>
          </Flex>
        ))}
      </FunnelRadioButtonGroup>
    </Row>
  );
};

export default FuneralPlanStep;
