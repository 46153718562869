import { productAPI } from 'api';
import config from 'config';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { parseItems } from 'store/helpers';
import { EntitiesState, FuneralType, ProductCategory, GetState } from 'types';
import { createAction } from 'typesafe-actions';

interface FetchProductAction {
  entities: Pick<EntitiesState, 'products'>;
  result: {
    [key in ProductCategory]: string;
  };
}

interface FetchProductsAction {
  entities: Pick<EntitiesState, 'products'>;
  result: string[];
}

const product = new schema.Entity(
  'products',
  {},
  { idAttribute: 'sku', processStrategy: parseItems },
);

const fetchProductAction = createAction('FETCH_PRODUCT')<FetchProductAction>();
const fetchAlternativeProductsAction = createAction(
  'FETCH_ALTERNATIVE_PRODUCTS',
)<FetchProductsAction>();

export default {
  fetchAlternativeProducts: fetchAlternativeProductsAction,
  fetchProduct: fetchProductAction,
};

export const fetchProduct = (id: string, pricebookId: string) => async (dispatch: Dispatch) => {
  const data = await productAPI.fetch(id, pricebookId);

  return dispatch(
    fetchProductAction(normalize({ [data.category]: data }, { [data.category]: product })),
  );
};

/*
 * Fetch products for given category which then can be presented in product details modal window
 * */
export const fetchAlternativeProducts =
  (category: ProductCategory, type?: FuneralType) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { salesforce },
    } = getState();

    const pricebookId = salesforce?.pricebookId;

    const products = await productAPI.fetchAll({
      category,
      type,
      ...(pricebookId ? { pricebookId } : { pricebookId: config.defaultPricebook }),
    });

    return dispatch(
      fetchAlternativeProductsAction(
        normalize(
          { [type || 'all']: { [category]: products } },
          { [type || 'all']: { [category]: [product] } },
        ),
      ),
    );
  };
