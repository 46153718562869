import { BusinessAccountContext } from 'context/BusinessAccountProvider';
import { FunnelContext } from 'context/Funnel';
import { PhoneDirectoryContext } from 'context/PhoneProvider';
import { UserLocationNearbyContext } from 'context/UserLocationNearbyProvider';
import { WizardContext } from 'context/WizardContextProvider';
import { useStorage } from 'mymoria-ui/hooks';
import { useContext } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux';

import { RootAction } from 'reducers/types';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from 'types';

export { default as useAllOfferItems } from './useAllOfferItems';

export { default as useBasicServices } from './useBasicServices';

export const useBusinessAccount = () => useContext(BusinessAccountContext);
export { default as useCMSSelector } from './useCMSSelector';

export const useDispatch = () =>
  useDispatchBase<ThunkDispatch<ApplicationState, any, RootAction>>();

export { useDispatchBase };

export { default as useErrorMessage } from './useErrorMessage';

export const useFunnel = () => useContext(FunnelContext);

export { default as useHistory } from './useHistory';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLocalStorage = useStorage('localStorage');

export const useLocationNearby = () => useContext(UserLocationNearbyContext);

export { default as useMountLayoutEffect } from './useMountLayoutEffect';

export const usePhoneDirectory = () => useContext(PhoneDirectoryContext);

export { default as usePreventWindowUnload } from './usePreventWindowUnload';

export { default as useScrollRestoration } from './useScrollRestoration';

export const useSelector: TypedUseSelectorHook<ApplicationState> = useSelectorBase;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useSessionStorage = useStorage('sessionStorage');

export { default as useTrackingLocation } from './useTrackingLocation';
export const useWizard = () => useContext(WizardContext);
