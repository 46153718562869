import actions from 'actions';
import * as t from 'data';
import { filter, map } from 'lodash';
import { getValidPhoneFormat } from 'mymoria-ui';
import {
  ApplicationState,
  OfferItemType,
  ProposalState,
  ProductCategory,
  FUNNEL_STEPS,
} from 'types';
import { createReducer } from 'typesafe-actions';

const {
  fetchProposal,
  fetchProposalAdditionalData,
  changeContactInformation,
  changeContactUsInformation,
  changeBasicInformation,
  update,
  acceptProposal,
  addOfferItem,
  lockOfferItem,
  removeOfferItem,
  removeOfferMultiSelectedItem,
  changeProduct,
  changeFuneralSite,
  changeBasicService,
  createProposal,
  setFunnelData,
  switchSeaBasicService,
  setFormSubmissionTime,
  omitAppendFunnelStep,
} = actions.proposal;
const initialState = {
  basicProducts: [],
  basicServices: [],
  optionalProducts: [],
  optionalServices: [],
  salesforce: {},
  steps: Array.from(FUNNEL_STEPS),
} as unknown as ProposalState;

const getOptionalKey = (type: OfferItemType) =>
  type === 'services' ? 'optionalServices' : 'optionalProducts';

const updateCollection = (collection: string[], removedId: string, addedId: string) =>
  map(collection, id => (id === removedId ? addedId : id));

export default createReducer(initialState)
  .handleAction([fetchProposalAdditionalData, changeBasicInformation], (state, { payload }) => ({
    ...state,
    ...payload.result.proposal,
  }))
  .handleAction([createProposal, fetchProposal], (state, { payload }) => ({
    ...payload.result.proposal,
    formSubmissionTime: undefined,
    steps: state.steps,
  }))
  .handleAction(
    [
      acceptProposal,
      changeFuneralSite,
      update,
      changeContactInformation,
      changeContactUsInformation,
      setFunnelData,
      lockOfferItem,
    ],
    (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  )
  .handleAction(addOfferItem, (state, { payload: { id, type, price } }) => ({
    ...state,
    price,
    [getOptionalKey(type)]: [...state[getOptionalKey(type)], id],
  }))
  .handleAction(
    [removeOfferItem, removeOfferMultiSelectedItem],
    (state, { payload: { id, type, price } }) => ({
      ...state,
      price,
      [getOptionalKey(type)]: filter(state[getOptionalKey(type)], itemId => itemId !== id),
    }),
  )
  .handleAction(changeBasicService, (state, { payload: { addedId, removedId, price } }) => ({
    ...state,
    basicServices: updateCollection(state.basicServices, removedId, addedId),
    price,
  }))
  .handleAction(
    switchSeaBasicService,
    (
      state,
      { payload: { addedServices, removedServices, price, funeralPlan, graveType, funeralSite } },
    ) => ({
      ...state,
      funeralPlan,
      graveType,
      optionalServices: [
        ...state.optionalServices.filter(id => !removedServices.includes(id)),
        ...addedServices.filter(id => !state.optionalServices.includes(id)),
      ],
      price,
      ...(funeralSite ? { funeralSite } : {}),
    }),
  )
  .handleAction(changeProduct, (state, { payload: { addedId, removedId, price } }) => ({
    ...state,
    basicProducts: updateCollection(state.basicProducts, removedId, addedId),
    optionalProducts: updateCollection(state.optionalProducts, removedId, addedId),
    price,
  }))
  .handleAction(setFormSubmissionTime, (state, { payload: { time } }) => ({
    ...state,
    formSubmissionTime: time,
  }))
  .handleAction(omitAppendFunnelStep, (state, { payload: { step, action } }) => ({
    ...state,
    steps:
      action === 'OMIT'
        ? state.steps.filter(id => id !== step)
        : FUNNEL_STEPS.filter(id => state.steps.includes(id) || id === step),
  }));

export const isOfferEditable =
  () =>
  ({ proposal: { state } }: ApplicationState) =>
    state !== 'customized';

export const isProductSelected =
  (id: string) =>
  ({ proposal: { basicProducts, optionalProducts } }: ApplicationState) =>
    [...basicProducts, ...optionalProducts].includes(id);

export const isOptionalProductSelected =
  (id: string) =>
  ({ proposal: { optionalProducts } }: ApplicationState) =>
    optionalProducts.includes(id);

export const isOptionalServiceSelected =
  (id: string) =>
  ({ proposal: { optionalServices } }: ApplicationState) =>
    optionalServices.includes(id);

export const getCustomerFullName = ({
  proposal: { salutation, firstname, lastname },
}: ApplicationState) => `${t.form.salutation[salutation]} ${firstname} ${lastname}`;

export const getCustomerPhone = ({ proposal: { phone } }: ApplicationState) =>
  getValidPhoneFormat({ formatType: 'national', phone, withSlash: true });

export const getSelectedProductByCategory =
  (category: ProductCategory) =>
  ({ proposal: { basicProducts, optionalProducts }, entities }: ApplicationState) =>
    [...basicProducts, ...optionalProducts]
      .map(id => entities.products[id])
      .find(product => product?.category === category);

export const getSelectedProductsByCategoryAndId =
  (category: ProductCategory, selectedId: string) =>
  ({ proposal: { basicProducts, optionalProducts }, entities }: ApplicationState) =>
    [...basicProducts, ...optionalProducts]
      .map(id => entities.products[id])
      .filter(product => product?.category === category)
      .find(({ id }) => id === selectedId);
