import { GoogleMap } from '@react-google-maps/api';
import { fetchClosestFuneralSite } from 'actions/funeralSites';
import { changeFuneralSite, switchSeaBasicService } from 'actions/proposal';
import { funeralSiteAPI } from 'api';
import { FormFooter, Loader, Section } from 'components';
import config from 'config';
import { errors, global, googleMap } from 'data';
import {
  useDispatch,
  useSelector,
  useTrackingLocation,
  useBusinessAccount,
  useHistory,
} from 'hooks';
import { debounce } from 'lodash';
import { useToast } from 'mymoria-ui/hooks';
import { mq, styled, css } from 'mymoria-ui/utils';
import React, { useCallback, useRef, useState } from 'react';
import { getFuneralSiteById } from 'reducers/entities';
import { Page } from 'templates';
import { FuneralSiteMarker as IFuneralSiteMarker, Place, iconTypes, FuneralSite } from 'types';
import FuneralSiteInfoBox from './FuneralSiteInfoBox';
import FuneralSiteMarker from './FuneralSiteMarker';
import styles from './FuneralSiteStyles';

const StyledMapContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;

  ${mq({
    bottom: [60, '5rem'],
    top: ['8rem', '12rem'],
  })}

  button.gm-ui-hover-effect {
    visibility: hidden;
  }
`;

const StyledSection = styled(Section)(
  ({ theme: { components } }) => css`
    && {
      h1 {
        ${mq({ fontSize: components.headings.h2.fontSize })};
      }
    }
  `,
);

const {
  googleMaps: { center: defaultCenter, mapOptions, balticSea, northSea },
} = config;

const headerStyles = css({ right: 0, width: 'unset' });
const TREE_RECOMMENDATION_ORGANISATION = 'Friedwald';

const mainStyles = css(mq({ paddingTop: [0, '3.5rem', null, 1] }));

const FuneralSitePage = () => {
  useTrackingLocation('Funeral site Map');
  const dispatch = useDispatch();
  const { handleGoBack, handlePush } = useHistory();
  const { addToast } = useToast();
  const { logoUrl: opcoLogo } = useBusinessAccount();
  const mapRef = useRef<any>(null);
  const funeralSite = useSelector(({ proposal }) => proposal.funeralSite);
  const funeralPlan = useSelector(({ proposal }) => proposal.funeralPlan);
  const offerId = useSelector(({ proposal }) => proposal.id);
  const [selectedMarker, setSelectedMarker] = useState(funeralSite); //pickedFs
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const graveType = useSelector(({ proposal }) => proposal.graveType);
  const lat = useSelector(({ proposal }) => proposal.lat);
  const lon = useSelector(({ proposal }) => proposal.lon);
  const funeralSiteEntity = useSelector(getFuneralSiteById(funeralSite));
  const [zoom, setZoom] = useState(config.googleMaps.zoom);
  const [markers, setMarkers] = useState<IFuneralSiteMarker[]>([]);
  const [selectedGraveType, setSelectedGraveType] = useState(graveType);
  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState(() => {
    if (funeralSiteEntity) {
      return funeralSiteEntity.address.gps;
    } else if (lat && lon) {
      return { lat, lng: lon };
    } else {
      return defaultCenter;
    }
  });
  const [treeClosestFS, setTreeClosestFS] = useState<FuneralSite>();

  // Update center state only when it changed. Prevent re-renders and additional fetches.
  const changeCenter = ({ lat, lng }: { lat: number; lng: number }) => {
    if (center.lat !== lat || center.lng !== lng) {
      setCenter({ lat, lng });
    }
  };

  // TODO: Get rid of eslint warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMarkers = useCallback(
    debounce(() => {
      if (mapRef.current) {
        return funeralSiteAPI
          .fetchMarkers(funeralType, funeralPlan, mapRef.current.getBounds())
          .then(markers => {
            setMarkers(markers);
            if (markers.length < 3) {
              setZoom(zoom - 1);
            }
          })
          .catch(() => {
            setZoom(zoom - 1);
          });
      }
    }, 250),
    [funeralType, funeralPlan, zoom],
  );

  const selectMarker = (id: string) => {
    setSelectedMarker(id);
    if (id === funeralSite) {
      setSelectedGraveType(graveType);
    } else {
      setSelectedGraveType('');
    }
  };

  const deselectMarker = () => {
    setSelectedMarker('');
    setSelectedGraveType(graveType);
  };

  const deleteFuneralSite = () => {
    setSelectedMarker('');
    setSelectedGraveType('');
  };

  const handlePlaceChange = useCallback(
    (place: Place) => place && setCenter({ lat: place.lat, lng: place.lon }),
    [],
  );

  const handleFuneralSiteChange = useCallback(() => {
    setIsLoading(true);
    if (funeralType === 'sea') {
      return dispatch(switchSeaBasicService(selectedGraveType === 'SU', selectedMarker))
        .then(handlePush(`/offer/${offerId}`, { restoreScrollPosition: true }))
        .catch(() => addToast(errors.general))
        .finally(() => setIsLoading(false));
    } else {
      return dispatch(changeFuneralSite(selectedMarker, selectedGraveType))
        .then(handlePush(`/offer/${offerId}`, { restoreScrollPosition: true }))
        .catch(() => addToast(errors.general))
        .finally(() => setIsLoading(false));
    }
  }, [addToast, dispatch, funeralType, handlePush, offerId, selectedGraveType, selectedMarker]);

  const onRecommendationSelect = (iconType?: iconTypes, funeralSite?: FuneralSite) => {
    if (funeralSite) {
      changeCenter({ lat: funeralSite.address.gps.lat, lng: funeralSite.address.gps.lng });
      setZoom(config.googleMaps.zoom);
      setSelectedMarker(funeralSite.id);
    } else {
      switch (iconType) {
        case 'balticSea':
          changeCenter(balticSea);
          setZoom(config.googleMaps.zoomFar);
          break;
        case 'northSea':
          changeCenter(northSea);
          setZoom(config.googleMaps.zoomFar);
          break;
        case 'nearBy':
          if (lat && lon) {
            changeCenter({ lat, lng: lon });
            setZoom(config.googleMaps.zoom);
          }
          break;
        case 'treeClosestFS':
          if (!treeClosestFS && lat && lon) {
            dispatch(
              fetchClosestFuneralSite(lat, lon, funeralType, TREE_RECOMMENDATION_ORGANISATION),
            ).then(data => {
              changeCenter({
                lat: data.address.gps.lat,
                lng: data.address.gps.lng,
              });
              setZoom(config.googleMaps.zoom);
              setTreeClosestFS(data);
              setSelectedMarker(data.id);
            });
          } else if (treeClosestFS) {
            changeCenter({
              lat: treeClosestFS.address.gps.lat,
              lng: treeClosestFS.address.gps.lng,
            });
            setZoom(config.googleMaps.zoom);
            setSelectedMarker(treeClosestFS.id);
          }
          break;
        default:
          break;
      }
    }
  };

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  return (
    <Page footer="none" headerType="ghost" mainStyles={mainStyles} headerStyles={headerStyles}>
      <StyledSection
        title={googleMap.funeralSiteTitle}
        onGoBack={handleGoBack}
        goBackLabel={global.labels.offer}
        headlineWidth={opcoLogo ? ['65%', '65%', '100%'] : '100%'}
      >
        <StyledMapContainer>
          <GoogleMap
            center={center}
            zoom={zoom}
            mapContainerStyle={{ height: '100%' }}
            onZoomChanged={() => {
              if (mapRef.current) {
                setZoom(mapRef.current.getZoom());
              }
            }}
            onLoad={ref => {
              mapRef.current = ref;
            }}
            onIdle={fetchMarkers}
            onUnmount={onUnmount}
            options={{
              ...mapOptions,
              mapTypeId: google.maps.MapTypeId[config.googleMaps.mapType],
              styles,
              zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
            }}
          >
            {isLoading && <Loader overlay />}
            {markers.map(({ id, city, name, latitude, longitude }) => (
              <FuneralSiteMarker
                key={`${id}-${city}`}
                id={id}
                name={name}
                lat={latitude}
                lng={longitude}
                funeralType={funeralType}
                selected={id === selectedMarker}
                origined={id === funeralSite}
                onClick={selectMarker}
                imageExt="svg"
              />
            ))}
            <FuneralSiteInfoBox
              selectedFuneralSite={selectedMarker}
              funeralType={funeralType}
              originFuneralSite={funeralSite}
              funeralPlan={funeralPlan}
              selectedGraveType={selectedGraveType}
              onClose={deselectMarker}
              onPlaceChange={handlePlaceChange}
              onFuneralSiteDelete={deleteFuneralSite}
              onGraveTypeChange={setSelectedGraveType}
              onRecommendationSelect={onRecommendationSelect}
              treeClosestFuneralSite={treeClosestFS}
            />
          </GoogleMap>
        </StyledMapContainer>
      </StyledSection>
      <FormFooter
        labelCancel={global.labels.cancel}
        labelSubmit={global.labels.submit}
        onCancel={handleGoBack}
        onSubmit={handleFuneralSiteChange}
        disabledSubmit={
          isLoading ||
          (selectedMarker === funeralSite && selectedGraveType === graveType) ||
          (funeralType === 'sea' && !selectedMarker)
        }
      />
    </Page>
  );
};

export default FuneralSitePage;
