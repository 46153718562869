import { css, mq, styled } from 'mymoria-ui';
import { Main, SimpleFooter } from 'mymoria-ui/components';
import { display, layout, space } from 'styled-system';

export const FunnelMain = styled(Main)`
  flex-grow: 1;
  margin-bottom: auto;
  align-items: center;
  padding: 2rem 22px 0;
  overflow-anchor: none;
  min-height: 100vh;
  background-image: linear-gradient(
    56deg,
    rgba(237, 213, 219, 0.76) 3%,
    rgba(255, 245, 219, 0) 72%
  );
`;

export const FunnelFooter = styled(SimpleFooter)(
  () => css`
    ${mq({
      padding: ['0 1rem 4rem', null, '1rem'],
    })};

    && {
      height: unset;
    }
  `,
  space,
);

export const Address = styled.address(
  ({ theme: { colors } }) => css`
    margin-top: auto;
    text-align: center;

    ${mq({
      backgroundColor: [colors.primary, 'unset'],
      marginBottom: ['unset', 'auto'],
      padding: ['1rem 0', '2rem 0'],
      width: ['calc(100% + 44px)', 'unset'],
    })};

    && {
      * {
        ${mq({
          color: [colors.secondary, colors.primary],
        })};
      }
    }
  `,
  display,
  layout,
);

export const MobileBackButton = styled.div(
  ({ theme: { colors } }) => css`
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    background: ${colors.white};
    bottom: 0;
    position: fixed;
    height: 55px;
    ${mq({ display: ['block', null, 'none'] })};
  `,
);
