import { trackCtaClick } from 'actions/tracking';
import { Button, Icon, ButtonProps } from 'components';
import config from 'config';
import { useDispatch, useSelector } from 'hooks';
import { css, styled } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';

const StyledButton = styled(Button)(
  ({ theme: { colors } }) => css`
    &:hover {
      path {
        fill: ${colors.white};
      }
    }
  `,
);

const DownloadPDFIcon = styled(Icon.Download)`
  vertical-align: text-bottom;
  margin-right: 0.5rem;
`;

const DownloadPDFButton: FunctionComponent<ButtonProps> = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const proposalId = useSelector(({ proposal }) => proposal.id);

  const handleClick = () => {
    dispatch(trackCtaClick('download_offer'));
    const pdfURL = new URL(`/pdf/proposal/sf/${proposalId}`, config.api.endpoint);
    window.open(pdfURL.toString());
  };

  return proposalId ? (
    <StyledButton outline onClick={handleClick} {...props}>
      <DownloadPDFIcon color="#153255" />
      {children}
    </StyledButton>
  ) : null;
};

export default DownloadPDFButton;
