import { FieldConfig, useField } from 'formik';
import SelectLight, { SelectProps, SelectOption } from 'mymoria-ui/components/Form/Select';
import React, { FunctionComponent } from 'react';

const SelectField: FunctionComponent<
  Omit<FieldConfig, 'validate'> & Omit<SelectProps, 'onChange' | 'value'>
> = props => {
  const { name, options } = props;
  const [field, , helpers] = useField(name);

  return (
    <SelectLight
      {...props}
      options={options}
      name={field.name}
      value={options.find((option: SelectOption) => option.value === field.value)?.value || ''}
      onChange={value => helpers.setValue(value)}
      onBlur={field.onBlur}
    />
  );
};

export default SelectField;
