import { ReactComponent as ConcernTypeIcon } from 'assets/stepIcons/concern_type_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import { useSelector } from 'hooks';
import React from 'react';
import { Relationship } from 'types';
import { sprintf } from 'utils';

const DeceasedLocationTitle = () => {
  const relationship = useSelector(({ proposal }) => proposal.relationship) as Exclude<
    Relationship,
    'myself'
  >;
  const city = useSelector(({ proposal }) => proposal.city);

  return (
    <StepTitle
      icon={<ConcernTypeIcon />}
      title={sprintf(t.funnel.deceasedLocation.title, {
        city,
        relationship:
          t.relationship.akkusativeWithPossessivePronounBusinessPerspective[relationship],
      })}
    />
  );
};

export default DeceasedLocationTitle;
