import { setFunnelData } from 'actions/proposal';
import { trackCtaClick, trackFunnelData } from 'actions/tracking';
import { Row, Button, Text, Flex } from 'components';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { css, styled } from 'mymoria-ui';
import { FunnelRadioButtonGroup, FunnelRadioButton } from 'mymoria-ui/components/Button';
import React, { useState, FunctionComponent } from 'react';
import { Relationship } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const visibleOptions = 6;

const StyledButton = styled(Button)(
  ({ theme: { fontSizes, fontWeights } }) => css`
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.normal};
  `,
);

const RelationshipStep: FunctionComponent<FunnelStepProps> = ({ next, onStepLoad }) => {
  const { concernType, relationship } = useSelector(({ proposal }) => proposal);
  const dispatch = useDispatch();
  const options =
    concernType === 'coming'
      ? [...t.funnel.relationship.options, t.funnel.relationship.optionMyself]
      : t.funnel.relationship.options;
  const [isExtended, setIsExtended] = useState(
    options.findIndex(({ value }) => value === relationship) >= visibleOptions,
  );
  onStepLoad();

  const handleSubmit = async (value: Relationship) => {
    dispatch(setFunnelData({ relationship: value }));
    dispatch(trackFunnelData({ relationship: value }));
    await sleep(ANIMATION_DELAY);
    next();
  };

  return (
    <Row flexWrap="wrap">
      <FunnelRadioButtonGroup
        name="relationShip"
        defaultValue={relationship}
        onChange={e => handleSubmit(e.target.value as Relationship)}
      >
        {(isExtended ? options : options.slice(0, visibleOptions)).map(({ value, label }) => (
          <Flex key={value} width={[1, 1 / 3]} my={1} px={['unset', 1]}>
            <FunnelRadioButton value={value} padding={['normal', 'large']}>
              <Text fontWeight="bold" fontSize="normal" lineHeight="normal" textAlign="center">
                {label}
              </Text>
            </FunnelRadioButton>
          </Flex>
        ))}
      </FunnelRadioButtonGroup>
      {!isExtended && (
        <Flex width={1} my={2} px={['unset', 1]} fontSize="tiny">
          <StyledButton
            block
            onClick={() => {
              dispatch(trackCtaClick('show_more_relationships'));
              setIsExtended(true);
            }}
          >
            {t.funnel.relationship.extendOptions}
          </StyledButton>
        </Flex>
      )}
    </Row>
  );
};

export default RelationshipStep;
