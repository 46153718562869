import { WizardPages } from 'components';
import { Wizard } from 'context';
import React from 'react';
import { ContactUsWizardStepsType } from 'types';

import Options from './Options';
import Result from './Result';

interface Props {
  setWizardType: (WizardType: ContactUsWizardStepsType) => void;
}

const Callback = ({ setWizardType }: Props) => (
  <Wizard steps={2}>
    <WizardPages>
      <Options onBackClick={() => setWizardType('Intro')} />
      <Result />
    </WizardPages>
  </Wizard>
);

export default Callback;
