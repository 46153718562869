import { fetchAlternativeProducts } from 'actions/products';
import { Col, Grid, NotFound, Row, Section, Form, Flex } from 'components';
import * as t from 'data';
import { funeralTypes, getQueryParams, productCategories } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { includes, isEmpty, startCase } from 'lodash';
import { styled, mq } from 'mymoria-ui';
import { useToast, useSessionStorage } from 'mymoria-ui/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Page, templates } from 'templates';
import { ProductCategory, ProductCategoryGerman } from 'types';
import * as yup from 'yup';
import CatalogProductsListItem from './CatalogProductsListItem';
import { categoriesMap, funeralTypesMap } from './utils';

interface FuneralTypeQueryParams {
  typ?: string;
}

const queryParamsValidationSchema = yup.object().shape({
  typ: yup.string().oneOf(Object.keys(funeralTypesMap)),
});

const { Select } = Form;

const StyledSelect = styled(Select)`
]  ${mq({
  width: ['100%', '50%', '30%', '30%'],
})};
`;

const TitleContainer = styled.div(
  ({ theme: { space } }) => `
    padding: ${space[3]};
    margin: ${space[3]} auto ${space[6]};
    width: fit-content;
  `,
);

const CatalogProductsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { category: categoryDE } = useParams<{ category: ProductCategoryGerman }>();
  const category = categoriesMap[categoryDE];
  const { addToast } = useToast();
  const { url } = useRouteMatch();
  const products = useSelector(
    ({ products }) => category && products.alternatives['all'][category],
  );

  const history = useHistory();
  const [funeralType, setFuneralType] = useSessionStorage<ProductCategory | '' | undefined>(
    `productCatalog-funeralType-${category}`,
    '',
  );
  const productsEntities = useSelector(({ entities }) => entities.products);

  useEffect(() => {
    try {
      const queryParams = getQueryParams<FuneralTypeQueryParams>(
        search,
        queryParamsValidationSchema,
      );
      if (queryParams.typ) {
        const funeralType = funeralTypesMap[queryParams.typ];
        setFuneralType(funeralType);
        history.replace(url);
      }
    } catch (e: any) {
      setFuneralType(undefined);
      // eslint-disable-next-line no-console
      console.error(e.message);
      history.replace(url);
      setIsError(true);
    }
  }, [history, search, setFuneralType, url]);

  if (funeralType && !includes(funeralTypes, funeralType)) {
    addToast(t.errors.general);
    setIsError(true);
  }

  useEffect(() => {
    if (!includes(productCategories, category)) {
      setIsError(true);
    }
    if (isEmpty(products) && category) {
      setIsLoading(true);
      dispatch(fetchAlternativeProducts(category))
        .catch(() => {
          addToast(t.errors.general);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [addToast, category, dispatch, products]);

  const typeOptions = Object.entries({ Alle: '', ...funeralTypesMap }).map(([key, value]) => ({
    label: startCase(key),
    value,
  }));
  const filteredProducts = products
    ?.map(id => productsEntities[id])
    .filter(
      product =>
        (funeralType === '' || product.funeralType?.includes(funeralType)) && product.funeralType,
    );

  return (
    <Page {...templates.catalogPageTemplate} isLoading={isLoading} withHeader={false}>
      <Section
        title={category && t.global.titleByCategory[category]}
        staticHeading={true}
        suffixElement={
          <StyledSelect
            value={typeOptions.find(({ value }) => value === funeralType)?.value || ''}
            options={typeOptions}
            onChange={value => {
              setIsError(false);
              setFuneralType(value);
            }}
            label=""
          />
        }
      >
        <Grid>
          {isError ? (
            <NotFound />
          ) : !filteredProducts || filteredProducts?.length === 0 ? (
            <Row justifyContent="center">
              <Col width={[1, 5 / 6, 2 / 3]}>
                <TitleContainer>
                  <h1>{t.global.catalogNotFound}</h1>
                </TitleContainer>
              </Col>
            </Row>
          ) : (
            <Flex flexWrap="wrap">
              {filteredProducts!.map(({ id }) => (
                <Col key={id} width={[1 / 2, 1 / 3, 1 / 4, 1 / 5]} p={1}>
                  <CatalogProductsListItem id={id} />
                </Col>
              ))}
            </Flex>
          )}
        </Grid>
      </Section>
    </Page>
  );
};

export default CatalogProductsList;
