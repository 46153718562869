import { fetchAlternativeProducts } from 'actions/products';
import { Col, Grid, NotFoundPage, Row, Section } from 'components';
import { global } from 'data';
import { getSortedSelectedProducts } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { isEmpty } from 'lodash';
import { useToast } from 'mymoria-ui/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page, templates } from 'templates';
import { ProductCategory } from 'types';
import Product from './Product';

interface UrlParams {
  category: ProductCategory;
}

const ProductsPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const { category } = useParams<UrlParams>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const basicProducts = useSelector(({ proposal }) => proposal.basicProducts);
  const optionalProducts = useSelector(({ proposal }) => proposal.optionalProducts);
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const products = useSelector(
    ({ products }) => (products.alternatives[funeralType] || {})[category],
  );
  const offerId = useSelector(({ proposal }) => proposal.id);
  const code = useSelector(({ proposal }) => proposal.code);
  const salesforce = useSelector(({ proposal }) => proposal.salesforce);

  useEffect(() => {
    if (isEmpty(products) && funeralType) {
      setIsLoading(true);
      dispatch(fetchAlternativeProducts(category, funeralType))
        .catch(error => {
          addToast(error.message);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, category, products, addToast, funeralType, code, salesforce]);

  if (isError) {
    return <NotFoundPage />;
  }

  return (
    <Page {...templates.catalogPageTemplate} isLoading={isLoading} withHeader={false}>
      <Section
        title={global.titleByCategory[category]}
        staticHeading={true}
        onGoBack={handlePush(offerId ? `/offer/${offerId}` : '/offer/create', {
          restoreScrollPosition: true,
        })}
        goBackLabel={global.labels.offer}
      >
        <Grid>
          <Row flexWrap="wrap">
            {getSortedSelectedProducts(products || [], basicProducts, optionalProducts).map(id => (
              <Col key={id} width={[1 / 2, 1 / 3, 1 / 4, 1 / 5]} p={1}>
                <Product id={id} />
              </Col>
            ))}
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default ProductsPage;
