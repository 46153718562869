import Textarea, { TextareaProps } from 'mymoria-ui/components/Form/Textarea';
import { styled, css } from 'mymoria-ui/utils';
import { FunctionComponent } from 'react';

const StyledTextarea = styled(Textarea)(
  ({ theme: { space } }) => css`
    text-align: center;

    div {
      justify-content: center;
    }

    textarea {
      border-radius: 12px;
      border: solid 1px rgba(151, 151, 151, 0.24);
      margin-top: ${space[3]};
    }

    label {
      margin: 0;
    }
  `,
);

const NoteComponent: FunctionComponent<TextareaProps> = props => (
  <StyledTextarea rows={4} {...props} />
);

export default NoteComponent;
