import { findBasicService } from 'helpers';
import { pick } from 'lodash';
import { useSelector } from './index';

export default () => {
  const servicesEntities = useSelector(({ entities }) => entities.services);

  // basic services saved in proposal
  const selectedBasicServicesIds = useSelector(({ proposal }) => proposal.basicServices);
  const selectedBasicServices = pick(servicesEntities, selectedBasicServicesIds);

  // most recent basic services based on postal code and funeral type
  const basicServicesIds = useSelector(({ services }) => services.basic);
  const basicServices = pick(servicesEntities, basicServicesIds);

  const selectedBasic = findBasicService(selectedBasicServices) || findBasicService(basicServices);

  return selectedBasic;
};
