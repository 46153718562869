/* eslint-disable import/order */
import 'core-js/stable';
import { Loader, GoogleAndCookies } from 'components';
import config from 'config';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import { CMSProvider, FunnelProvider, PhoneProvider, BusinessAccountProvider } from 'context';
import { pick } from 'lodash';
import { UIProvider, Theme } from 'mymoria-ui';
import { GoogleMapsWrapper } from 'mymoria-ui/components';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore, { history } from 'store';
import * as serviceWorker from './serviceWorker';

// this import is required in order to augment emotion builtin Theme interface
import 'mymoria-ui/styles/theme';
import 'url-search-params-polyfill';
import 'regenerator-runtime/runtime';

const { store, persistor } = createStore();
const { api } = config;

const theme = {
  config: {
    api: pick(api, 'endpoint'),
  },
};

ReactDOM.render(
  <FunnelProvider>
    <UIProvider theme={theme as Theme}>
      <CookiesProvider>
        <StoreProvider store={store}>
          <CMSProvider>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <ConnectedRouter history={history}>
                <PhoneProvider>
                  <BusinessAccountProvider>
                    <GoogleMapsWrapper apiKey={config.googleMaps.key}>
                      <GoogleAndCookies />
                      <App />
                    </GoogleMapsWrapper>
                  </BusinessAccountProvider>
                </PhoneProvider>
              </ConnectedRouter>
            </PersistGate>
          </CMSProvider>
        </StoreProvider>
      </CookiesProvider>
    </UIProvider>
  </FunnelProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
