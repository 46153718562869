import { MobileStickyButton, ContactUsModal } from 'components';
import * as t from 'data';
import { useSelector } from 'hooks';
import { Icon } from 'mymoria-ui/components';
import { useModal } from 'mymoria-ui/hooks';
import theme from 'mymoria-ui/styles/theme';
import { styled } from 'mymoria-ui/utils';
import React from 'react';

const SpeechBubble = styled(Icon.SpeechBubble)`
  vertical-align: middle;
  margin-right: 0.5rem;
`;

const StyledMobileStickyButton = styled(MobileStickyButton)`
  z-index: 9;
`;

const ContactUsFormStickyButton = () => {
  const { showModal, hideModal, Modal } = useModal();
  const state = useSelector(({ proposal }) => proposal.state);

  if (state === 'customized') {
    return null;
  }

  return (
    <>
      <StyledMobileStickyButton onClick={showModal}>
        <SpeechBubble />
        {t.global.labels.contactUs}
      </StyledMobileStickyButton>

      <Modal
        justifyContent="unset"
        height={['100%', 'unset']}
        maxHeight="100%"
        borderRadius={['unset', `${theme.radii[1]}px`]}
      >
        <ContactUsModal hideModal={hideModal} />
      </Modal>
    </>
  );
};

export default ContactUsFormStickyButton;
