import { changeContactInformation } from 'actions/proposal';
import { Col, Loader, Row, Text, Box, Button, Heading } from 'components';
import * as t from 'data';
import { form } from 'data';
import { Form, Formik } from 'formik';
import { InputField, RadioGroupField, PhoneField } from 'forms/fields';
import { salutationOptions } from 'forms/options';
import { contactDetailsSchema } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import useErrorMessage from 'hooks/useErrorMessage';
import useToast from 'mymoria-ui/hooks/useToast';
import { FlexboxProps, LayoutProps, SpaceProps } from 'mymoria-ui/interfaces';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { templates, Page } from 'templates';
import { ContactDetails } from 'types';

const rowProps: LayoutProps | SpaceProps | FlexboxProps = {
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  mx: [0, -3],
};

const formColProps: LayoutProps | SpaceProps = {
  px: 3,
  py: 1,
  width: [1, 1 / 2],
};

const ContactDetailsPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush, push } = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const { error, setError } = useErrorMessage({ messages: t.errors });
  const { salutation, firstname, lastname, email, phone } = useSelector(({ proposal }) => proposal);

  const handleSubmit = (data: ContactDetails) => {
    if (error) {
      setError(null);
    }

    return dispatch(changeContactInformation(data))
      .then(() => {
        addToast(t.offer.contactDetails.successMsg, 'success', { autoDismiss: false });
        push(`/offer/${id}`);
      })
      .catch(setError);
  };

  return (
    <Page {...templates.formPageTemplate}>
      <Box maxWidth={800} width={[1, 3 / 4, null, 1 / 2]} marginX="auto" px={0}>
        <Row {...rowProps} px={3}>
          <Heading.Heading1 withLine fontWeight="extraBold" mb={[4, 6]}>
            {t.offer.contactDetails.header}
          </Heading.Heading1>
          <Text mb={3}>{t.offer.contactDetails.description}</Text>
        </Row>
        <Formik
          initialValues={{ email, firstname, lastname, phone, salutation }}
          validationSchema={contactDetailsSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitForm, dirty, isValid }) => (
            <>
              <Form noValidate>
                <Row {...rowProps}>
                  {isSubmitting && <Loader overlay />}
                  <Col {...formColProps} width={1}>
                    <RadioGroupField name="salutation" values={salutationOptions} />
                  </Col>
                  <Col {...formColProps}>
                    <InputField name="firstname" label={form.firstname} />
                  </Col>
                  <Col {...formColProps}>
                    <InputField name="lastname" label={form.lastname} />
                  </Col>
                  <Col {...formColProps}>
                    <PhoneField name="phone" label={form.phone} />
                  </Col>
                  <Col {...formColProps}>
                    <InputField name="email" label={form.email} />
                  </Col>
                </Row>
              </Form>
              <Row {...rowProps} mt={[6, 8]}>
                <Col {...formColProps}>
                  <Button
                    onClick={handlePush(`/offer/${id}`, { restoreScrollPosition: true })}
                    block
                    outline
                  >
                    {t.global.labels.cancel}
                  </Button>
                </Col>
                <Col {...formColProps}>
                  <Button
                    disabled={!dirty || !isValid}
                    onClick={submitForm}
                    block
                    variant="secondary"
                  >
                    {t.global.labels.submit}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default ContactDetailsPage;
