import {
  BasicInformation,
  ContactDetails,
  ContactUsDetails,
  FuneralSite,
  OfferItemType,
  Proposal,
  RawProposal,
  SaveProposalResponse,
} from 'types';
import { ProductItem, ServiceItem } from 'types/api';
import { api } from 'utils';

const SERVICE_ROUTE = 'proposalsv2';

const calculatePrice = (
  products: ProductItem[] = [],
  services: ServiceItem[] = [],
  funeralSite?: FuneralSite,
) =>
  [...products, ...services]
    .map(({ price }) => price)
    .reduce((totalPrice, price) => totalPrice + price, funeralSite?.price || 0);

type ChangeBasicInformationResponse = {
  proposal: RawProposal;
  services: ServiceItem[];
  products: ProductItem[];
};
const changeBasicInformation = (id: string, data: BasicInformation) =>
  api
    .post<ChangeBasicInformationResponse>(`/${SERVICE_ROUTE}/${id}/basic-information`, data)
    .then(({ data }) => data);

const changeContactInformation = (id: string, data: ContactDetails) =>
  api.post(`/${SERVICE_ROUTE}/${id}/contact-information`, data);

const changeContactUsInformation = (id: string, data: ContactUsDetails) =>
  api.post(`/${SERVICE_ROUTE}/${id}/contactUs-information`, data);

const accept = (id: string) => api.put(`/${SERVICE_ROUTE}/accept/${id}`);

type AddChangeDeleteItemResponse = { price: number };
const addItem = (id: string, type: OfferItemType, body: { sku: string }) =>
  api.put<AddChangeDeleteItemResponse>(`/${SERVICE_ROUTE}/${id}/${type.slice(0, -1)}`, body);

const deleteItem = (id: string, type: OfferItemType, body: { sku: string }) =>
  api.delete<AddChangeDeleteItemResponse>(`/${SERVICE_ROUTE}/${id}/${type.slice(0, -1)}`, {
    data: body,
  });

const changeItem = (
  id: string,
  type: OfferItemType,
  body: { addedId: string; removedId: string },
) => api.post<AddChangeDeleteItemResponse>(`/${SERVICE_ROUTE}/${id}/${type.slice(0, -1)}`, body);

const changeFuneralSite = (id: string, body: { funeralSiteId: string; graveType: string }) =>
  api.put<AddChangeDeleteItemResponse>(`/${SERVICE_ROUTE}/${id}/funeralSite`, body);

const save = (
  body: Partial<Omit<Proposal, 'extras'>> & {
    pricebookId: string;
    services: string[];
    products: string[];
    extras?: string[];
  },
) => api.post<SaveProposalResponse>(`${SERVICE_ROUTE}/save`, body);

const fetch = (id: string) =>
  api.get<RawProposal & { salesforce: { pricebookId: string } } & { account: any }>(
    `${SERVICE_ROUTE}/${id}`,
  );

const switchBasicService = (
  id: string,
  body: { funeralSite: string | undefined; anonymous: boolean },
) => api.post(`${SERVICE_ROUTE}/${id}/switchBasicService`, body);

export default {
  accept,
  addItem,
  calculatePrice,
  changeBasicInformation,
  changeContactInformation,
  changeContactUsInformation,
  changeFuneralSite,
  changeItem,
  deleteItem,
  fetch,
  save,
  switchBasicService,
};
