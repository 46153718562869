import { AxiosResponse, AxiosError } from 'axios';
import { isObject, merge } from 'lodash';
import moment from 'moment';
import { Customization } from 'types';
import { RawItem } from 'types/api';
import uuid from 'uuid/v4';

export { default as api } from './api';

export const customize = <T extends RawItem>(entities: T[], customizations?: Customization[]) =>
  !customizations
    ? entities
    : entities.map(entity => {
        const custom = customizations.find(item => entity.id === item.product2Id);
        return custom ? merge(entity, custom) : entity;
      });

export const generateCookiesSettings = () => ({
  id: uuid(),
  options: {
    domain: window.location.hostname === 'localhost' ? '' : '.mymoria.de',
    expires: moment().add(6, 'month').toDate(),
    path: '/',
  },
});

export const getAxiosData = <T>({ data }: AxiosResponse<T>) => data;

export const getLastURLPath = (path: string) => {
  const parts = path.split('/');

  return parts[parts.length - 1];
};

export const isAxiosError = (e: any): e is AxiosError => {
  return e.isAxiosError === true && e.response;
};

//TODO: Fix
export const parseDataLayerError = ({ response }: any) => ({
  endpoint: `${response?.config?.method?.toUpperCase()} ${response?.config?.url}`,
  params: Object.entries(response?.config?.params || {}).reduce(
    (acc, [key, value]) => acc.concat(`${key}: ${value};`),
    '',
  ),
  response: `${response?.data?.statusCode} ${response?.data?.code} ${response?.data?.message}`,
});

export const sleep = (ms: number = 5000) => new Promise(res => setTimeout(res, ms));

// @ts-ignore
export const sprintf = (template, params) => {
  if (!isObject(params) || !template) {
    return template;
  }

  let output = template;
  Object.keys(params).forEach(p => {
    // @ts-ignore
    output = output.replace(new RegExp(`%\\(${p}\\)`, 'g'), params[p]);
  });
  return output;
};
