import config from 'config';
import React, { createContext, FunctionComponent, useEffect, useState } from 'react';
import { api } from 'utils';

const {
  googleMaps: {
    geoLocation: { url: geoLocationUrl },
  },
  api: { endpoint },
} = config;

const COUNT = 3;
const DISTANCE = 30;

interface UserLocationParams {
  nearBy: City[];
  userLocation: UserLocation;
}

interface CitiesData {
  cities: City;
  $: {
    distance: number;
  };
}

interface City {
  lat: number;
  lng: number;
  name: string;
  population: number;
  distance: number;
}

interface UserLocation {
  location: {
    lat: number;
    lng: number;
  };
  accuracy: number;
}

const initialContextData: UserLocationParams = {
  nearBy: [],
  userLocation: {
    accuracy: 0,
    location: {
      lat: 0,
      lng: 0,
    },
  },
};

export const UserLocationNearbyContext = createContext<UserLocationParams>(initialContextData);

const UserLocationProvider: FunctionComponent = ({ children }) => {
  const [nearBy, setNearby] = useState<City[]>([]);
  const [userLocation, setUserLocation] = useState<UserLocation>({
    accuracy: 0,
    location: {
      lat: 0,
      lng: 0,
    },
  });

  useEffect(() => {
    api.post(geoLocationUrl).then(({ data }) => setUserLocation(data));
  }, []);

  useEffect(() => {
    if (userLocation.accuracy > 0) {
      api
        .get<CitiesData[]>(
          `${endpoint}/cities/nearBy/${userLocation.location.lat}/${userLocation.location.lng}?count=${COUNT}&distance=${DISTANCE}`,
        )
        .then(({ data }) => {
          const cities = data.map(
            ({ cities: { name, lat, lng, population }, $: { distance } }) => ({
              distance,
              lat,
              lng,
              name,
              population,
            }),
          );
          setNearby(cities);
        });
    }
  }, [userLocation]);

  return (
    <UserLocationNearbyContext.Provider value={{ nearBy, userLocation }}>
      {children}
    </UserLocationNearbyContext.Provider>
  );
};

export default UserLocationProvider;
