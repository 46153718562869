import { styled } from 'mymoria-ui';
import { ActionButtonProps, ActionButton } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { display } from 'styled-system';

interface StickyActionButtonProps extends ActionButtonProps {}

const StyledButton = styled(ActionButton)(
  `
    width: 100%;
    border-radius: 0;
    position: fixed;
    bottom: 35px;
    z-index: 1000;
    font-size: 1.5rem;
  `,
  display,
);

const StickyActionButton: FunctionComponent<StickyActionButtonProps> = ({ ...props }) => (
  <StyledButton display={['block', 'block', 'none']} mx="small" mb="small" {...props} />
);

export default StickyActionButton;
