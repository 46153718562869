import { Caption, Flex, Markdown } from 'components';
import { funnel as t } from 'data';
import { mq, styled } from 'mymoria-ui/utils';
import React from 'react';

const StyledMarkdown = styled(Markdown)`
  p:before {
    content: '';
    display: inline-block;
    padding-right: 1.8rem;
    height: 1.4rem;
    background: url('/img/check-mark.svg') no-repeat left bottom;
    vertical-align: bottom;
  }
`;

const Wrapper = styled(Flex)<{ display: string[] }>(({ display }) => mq({ display }));

const IntroCheckPoints = ({ display }: { display: string[] }) => (
  <Wrapper display={display} mt={['unset', 'normal']} flexDirection={['column', 'row']}>
    {t.intro.checkPoints.map(({ label, value }) => (
      <Flex
        key={value}
        width={['100%', null, '40%', '20%']}
        display="flex"
        flexDirection="column"
        mr={['3rem', null, 'unset', '3rem']}
        mb={['1.5rem', 'unset']}
      >
        <StyledMarkdown children={value} key={value} fontWeight="bold" />
        <Caption mt="0.2rem" paddingLeft={['28px', 'unset']}>
          {label}
        </Caption>
      </Flex>
    ))}
  </Wrapper>
);

export default IntroCheckPoints;
