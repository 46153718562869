import { OptionalItemPreview } from 'components/Offer';
import { offer } from 'data';
import { getItemsByCategory } from 'helpers';
import { useSelector } from 'hooks';
import { pick } from 'lodash';
import { styled, css } from 'mymoria-ui';
import React from 'react';
import OfferPageCard from './OfferPageCard';

const StyledCard = styled(OfferPageCard)(
  ({ title }) => css`
    //TODO: Can we have another card variant?
    && {
      padding: 1.5rem 0;
    }

    ${title &&
    `
      & > div:first-of-type {
        padding: 0 1.5rem;
        margin-bottom: 1rem;
      }
    `}
  `,
);

const OptionalItemsCard = () => {
  const services = useSelector(({ services: { optional } }) => optional);
  const products = useSelector(({ products: { optional } }) => optional);
  const allProducts = useSelector(({ entities: { products } }) => products);
  const optionalProducts = pick(allProducts, products);

  const flowers = getItemsByCategory(optionalProducts, 'flower');
  const cards = getItemsByCategory(optionalProducts, 'card');
  const notice = getItemsByCategory(optionalProducts, 'notice');
  const graveGoods = getItemsByCategory(optionalProducts, 'grave_goods');
  const graveCross = getItemsByCategory(optionalProducts, 'graveCross');
  const keepsake = getItemsByCategory(optionalProducts, 'keepsake');
  const items = [
    ...flowers.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...services.slice(0, 3).map(id => <OptionalItemPreview key={id} id={id} type="services" />),
    ...cards.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...notice.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...keepsake.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...services.slice(3, 4).map(id => <OptionalItemPreview key={id} id={id} type="services" />),
    ...graveCross.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...graveGoods.map(({ id }) => <OptionalItemPreview key={id} id={id} type="products" />),
    ...services.slice(4).map(id => <OptionalItemPreview key={id} id={id} type="services" />),
  ];

  if (items.length === 0) {
    return null;
  }

  return (
    <StyledCard title={offer.offerDetails.othersTitle} variant="small">
      {items}
    </StyledCard>
  );
};

export default OptionalItemsCard;
