import { global as t } from 'data';
import { FieldConfig, useField } from 'formik';
import ReactPhoneInput, {
  InputProps,
  Value,
  isPossiblePhoneNumber,
} from 'mymoria-ui/components/Form/PhoneInput';
import React, { FunctionComponent, useCallback, useRef } from 'react';

const PhoneField: FunctionComponent<Omit<FieldConfig, 'validate'> & InputProps> = ({
  onChange,
  onBlur,
  validation = true,
  defaultCountry = 'DE',
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);
  const { current: setFieldValue } = useRef(setValue);

  const handleChange = useCallback(
    (phoneNumber?: Value) => {
      setFieldValue(phoneNumber);
    },
    [setFieldValue],
  );

  const handleBlur = useCallback(
    e => {
      field.onBlur(e);
      onBlur && onBlur(e);
    },
    [field, onBlur],
  );

  // if input is prefilled and has no error allow validation other wise check error state
  const prefilled = (field.value && !meta.error) || !!meta.error;
  // check phone number length for each country and return error msg
  const isPossible = !isPossiblePhoneNumber(field.value) ? t.validators.wrongPhoneNumber : '';

  return (
    <ReactPhoneInput
      {...field}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      defaultCountry={defaultCountry}
      validation={validation && meta.touched && prefilled}
      error={meta.touched ? meta.error || isPossible : ''}
    />
  );
};

export default PhoneField;
