import { FieldConfig, useField } from 'formik';
import PlaceSearch, { PlaceSearchProps } from 'mymoria-ui/components/Form/PlaceSearch';
import React, { FunctionComponent } from 'react';

const PlaceSearchField: FunctionComponent<PlaceSearchProps & FieldConfig> = props => {
  const [field, meta, { setValue }] = useField(props.name);

  return (
    <PlaceSearch
      {...field}
      {...props}
      error={meta.touched ? meta.error : ''}
      onChange={props.onChange || setValue}
    />
  );
};

export default PlaceSearchField;
