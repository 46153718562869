import { InfoBanner, Markdown } from 'components';
import { useCMSSelector } from 'hooks';
import React from 'react';

const AdditionalCostsBanner = () => {
  const { Title, Content } = useCMSSelector(({ OfferPageInfoboxes }) => OfferPageInfoboxes['3']);

  return (
    <InfoBanner title={Title}>
      <Markdown source={Content} />
    </InfoBanner>
  );
};

export default AdditionalCostsBanner;
