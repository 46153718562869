import config from 'config';
import { get, head, keyBy } from 'lodash';
import React, { createContext, FunctionComponent, useEffect, useState } from 'react';
import { api } from 'utils';

const {
  cms: { endpoint },
} = config;

const collections = [
  'ContactModal',
  'OfferPageInfoboxes',
  'FuneralSites',
  'OfferPageSettings',
  'Ratings',
  'AccompaniedFuneral',
];

type Collection = {
  [key: string]: any;
};

export type ContextData = {
  ContactModal: Collection;
  OfferPageInfoboxes: Collection;
  FuneralSites: Collection;
  OfferPageSettings: Collection;
  Ratings: Collection;
  AccompaniedFuneral: Collection;
};

const initialContextData = {
  AccompaniedFuneral: {},
  ContactModal: {},
  FuneralSites: {},
  OfferPageInfoboxes: {},
  OfferPageSettings: {},
  Ratings: {},
};

/* todo: improve typing */

export const CMSContext = createContext<ContextData>(initialContextData);

const CMSProvider: FunctionComponent = ({ children }) => {
  const [, setError] = useState(null);
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState<ContextData>(initialContextData);

  useEffect(() => {
    setIsLoading(true);

    Promise.all(
      collections.map(collection =>
        api.get(`${endpoint}/${collection}`, { params: { simple: 1 } }),
      ),
    )
      .then(responses => {
        collections.forEach((collectionName, index) => {
          setData(prevData => ({
            ...prevData,
            [collectionName]: get(head(responses[index].data), 'Id')
              ? keyBy(responses[index].data, 'Id')
              : head(responses[index].data),
          }));
          setIsLoading(false);
        });
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  /*
   * todo: what to do in case of an error? Should we have static defaults texts?
   * */

  return <CMSContext.Provider value={data}>{children}</CMSContext.Provider>;
};

export default CMSProvider;
