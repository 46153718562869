import { changeBasicInformation } from 'actions/proposal';
import { Col, Loader, RadioHint, Row, Text, Heading, Button, Box } from 'components';
import * as t from 'data';
import { Form, Formik, FormikHelpers } from 'formik';
import { PlaceSearchField, RadioGroupField } from 'forms/fields';
import { placeSchema } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import useErrorMessage, { Error } from 'hooks/useErrorMessage';
import { css, mq, styled } from 'mymoria-ui';
import useToast from 'mymoria-ui/hooks/useToast';
import { LayoutProps, SpaceProps, FlexboxProps } from 'mymoria-ui/interfaces';
import theme from 'mymoria-ui/styles/theme';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Page, templates } from 'templates';
import { FuneralType, Place } from 'types';
import * as yup from 'yup';

const rowProps: LayoutProps | SpaceProps | FlexboxProps = {
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  px: [3, 0],
};

const formColProps: LayoutProps | SpaceProps = {
  px: 3,
  py: 1,
  width: [1, 1 / 2],
};

const radioGroupColStyle = css(
  mq({ paddingBottom: theme.space[1], paddingTop: theme.space[1], width: ['100%', '50%'] }),
);

const StyledRadioGroupField = styled(RadioGroupField)(({ theme: { space } }) => {
  const alignmentShift = `-${space[1]}`;

  return {
    '&&': {
      width: 'unset',
    },
    marginLeft: alignmentShift,
    marginRight: alignmentShift,
    marginTop: alignmentShift,
  };
});

interface FormValues {
  place: Place;
  funeralType: FuneralType;
}

const BasicInformationPage = () => {
  const dispatch = useDispatch();
  const { handlePush, push } = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const { addToast } = useToast();
  const { error, setError } = useErrorMessage({ messages: t.errors });
  const { code, city, lat, lon, country, funeralType } = useSelector(({ proposal }) => proposal);

  const handleSubmit = (
    { place, funeralType }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    if (error) {
      setError(null);
    }

    return dispatch(changeBasicInformation({ ...place, funeralType }))
      .then(() => {
        setSubmitting(false);
        addToast(t.offer.basicInformationDetails.successMsg, 'success', { autoDismiss: false });
        push(`/offer/${id}`);
      })
      .catch((err: Error) => {
        setSubmitting(false);
        setError(err);
      });
  };

  return (
    <Page {...templates.formPageTemplate}>
      <Box maxWidth={800} width={[1, 3 / 4, null, 1 / 2]} marginX="auto" px={0}>
        <Row {...rowProps}>
          <Heading.Heading1 withLine fontWeight="extraBold" mb={[4, 6]}>
            {t.offer.basicInformationDetails.formTitle}
          </Heading.Heading1>
          <Text>{t.offer.basicInformationDetails.description}</Text>
        </Row>
        <Formik
          initialValues={{
            funeralType,
            place: {
              city,
              code,
              country,
              lat,
              lon,
            } as Place,
          }}
          validationSchema={yup.object().shape({
            funeralType: yup.string().required(t.global.validators.required),
            place: placeSchema,
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitForm, dirty, isValid }) => (
            <>
              <Form noValidate>
                {isSubmitting && <Loader overlay />}
                <Row {...rowProps} mt={[4, 6]}>
                  <Heading.Heading3 my={3}>
                    {t.offer.basicInformationDetails.funeralType}
                  </Heading.Heading3>
                  <StyledRadioGroupField
                    name="funeralType"
                    singleColStyle={radioGroupColStyle}
                    values={[
                      {
                        label: t.form.funeralType.burial,
                        suffix: <RadioHint>{t.form.funeralType.burialHint}</RadioHint>,
                        value: 'burial',
                      },
                      {
                        label: t.form.funeralType.cremation,
                        suffix: <RadioHint>{t.form.funeralType.cremationHint}</RadioHint>,
                        value: 'cremation',
                      },
                      {
                        label: t.form.funeralType.sea,
                        suffix: <RadioHint>{t.form.funeralType.seaHint}</RadioHint>,
                        value: 'sea',
                      },
                      {
                        label: t.form.funeralType.tree,
                        suffix: <RadioHint>{t.form.funeralType.treeHint}</RadioHint>,
                        value: 'tree',
                      },
                    ]}
                  />
                </Row>
                <Row {...rowProps} mt={[6, 8]}>
                  <Heading.Heading3 width={1} my={3}>
                    {t.offer.basicInformationDetails.place}
                  </Heading.Heading3>
                  <PlaceSearchField
                    name="place"
                    label={t.form.place}
                    containerStyle={css(mq({ flexBasis: ['100%', '75%'] }))}
                  />
                </Row>
              </Form>
              <Row {...rowProps} mt={[6, 8]} mx={-3}>
                <Col {...formColProps}>
                  <Button
                    onClick={handlePush(id ? `/offer/${id}` : '/offer/create', {
                      restoreScrollPosition: true,
                    })}
                    block
                    outline
                  >
                    {t.global.labels.cancel}
                  </Button>
                </Col>
                <Col {...formColProps}>
                  <Button
                    disabled={!dirty || !isValid}
                    onClick={submitForm}
                    block
                    variant="secondary"
                  >
                    {t.global.labels.submit}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default BasicInformationPage;
