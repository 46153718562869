import { NotFound } from 'components';
import React from 'react';
import { Page, templates } from 'templates';

const NotFoundPage = () => (
  <Page {...templates.notFoundPageTemplate}>
    <NotFound py="4rem" px={6} />
  </Page>
);

export default NotFoundPage;
