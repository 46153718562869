import { fetchProduct } from 'actions/products';
import { addProduct, changeProduct } from 'actions/proposal';
import { DataRowProps, NotFoundPage, ProductDetails, Section } from 'components';
import * as t from 'data';
import { formatPrice, multiProducts } from 'helpers';
import { useDispatch, useSelector, useHistory } from 'hooks';
import { Grid } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSelectedProductsByCategoryAndId,
  getSelectedProductByCategory,
} from 'reducers/proposal';
import { Page, templates } from 'templates';
import { ProductCategory, ProductProperty } from 'types';

interface URLParams {
  id: string;
  category: ProductCategory;
}

type SupportedProperties = {
  name: ProductProperty;
  label: string;
};

const supportedProperties: SupportedProperties[] = [
  { label: t.global.productDetails.color, name: 'color' },
  { label: t.global.productDetails.size, name: 'size' },
  { label: t.global.productDetails.weight, name: 'weight' },
];

const ProductPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const { id, category } = useParams<URLParams>();
  const offerId = useSelector(({ proposal }) => proposal.id);
  const product = useSelector(({ entities }) => entities.products[id]);
  const isMultiProduct = multiProducts.includes(category);

  // return object if new item is already selected
  const newSelectedProduct = useSelector(getSelectedProductsByCategoryAndId(category, id));

  // return object for the old selected item of the current category
  const oldSelectedProduct = useSelector(getSelectedProductByCategory(category));

  const isSelected = newSelectedProduct?.id === id;

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const offerPageURL = offerId ? `/offer/${offerId}` : '/offer/create';
  const productsPageURL = offerId
    ? `/offer/${offerId}/products/${category}`
    : `/offer/create/products/${category}`;
  const salesforce = useSelector(({ proposal }) => proposal.salesforce);

  useEffect(() => {
    if ((!product || !product.translations.properties) && salesforce.pricebookId) {
      setIsLoading(true);
      dispatch(fetchProduct(id, salesforce.pricebookId))
        .catch(error => {
          addToast(error.message);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, category, product, addToast, id, salesforce.pricebookId]);

  const handleSubmit = () => {
    // add multi item
    if (!newSelectedProduct && isMultiProduct) {
      setIsLoading(true);
      dispatch(addProduct(id, isMultiProduct))
        .then(handlePush(offerPageURL))
        .catch(() => addToast(t.errors.general))
        .finally(() => setIsLoading(false));
    } else {
      // change old item
      if (oldSelectedProduct) {
        setIsLoading(true);
        dispatch(changeProduct(id, oldSelectedProduct.id))
          .then(handlePush(offerPageURL))
          .catch(() => addToast(t.errors.general))
          .finally(() => setIsLoading(false));
      }
    }
  };

  if (!product) {
    return null;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  const details: DataRowProps[] = [];

  supportedProperties.forEach(({ name, label }) => {
    if ((product.translations.properties || {})[name]) {
      details.push({ label, value: (product.translations.properties || {})[name] });
    }
  });

  if (product?.articleNumber) {
    details.push({
      label: t.global.products.articleNumber,
      value: product.articleNumber,
    });
  }

  const placeholder = product.placeholder ? [product.placeholder] : [];

  return (
    <Page {...templates.catalogPageTemplate} isLoading={isLoading} withHeader={false}>
      <Section
        onGoBack={handlePush(productsPageURL, { restoreScrollPosition: true })}
        goBackLabel={t.global.backToCategory[category]}
      >
        <Grid padding={['0', null, '0 24px', '0 32px']}>
          <ProductDetails
            name={product.translations.shortname}
            actionButtonOnClick={handleSubmit}
            actionButtonLabel={
              isSelected ? t.global.products.selectedLabel : t.global.products.buttonLabel
            }
            isSelected={isSelected}
            price={formatPrice(product.price)}
            description={product.translations.description}
            pictures={product.pictures.length > 0 ? product.pictures : placeholder}
            details={details}
            detailsLabel={t.global.products.productDetails}
          />
        </Grid>
      </Section>
    </Page>
  );
};

export default ProductPage;
