import actions from 'actions';
import { uniq } from 'lodash';
import { FuneralSitesState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: FuneralSitesState = [];

const { fetchFuneralSite } = actions.funeralSites;
const { fetchProposalAdditionalData } = actions.proposal;

export default createReducer(initialState)
  .handleAction(fetchFuneralSite, (state, { payload }) => uniq([...state, payload.id]))
  .handleAction(fetchProposalAdditionalData, (state, { payload }) =>
    uniq([...state, payload.result.proposal.funeralSite]),
  );
