import {
  Proposal,
  TrackingCookies,
  TrackingParams,
  CookiesConsent,
  FunnelTrackingData,
} from 'types';
import { TypeConstant, createAction } from 'typesafe-actions';

type GTMEventConstant =
  | 'genericEvent'
  | 'virtualPageView'
  | 'buttonClick'
  | 'dl_init'
  | 'funnelData'
  | 'optimize.activate'
  | 'originalUtmParametersOnEntry'
  | 'gtm.js'
  | 'creationError';

interface GTMMeta {
  track: boolean;
}

export interface GTMEvent {
  event: GTMEventConstant;
}

interface GTMTrackLocation {
  name: string;
  path: string;
  contentGroup1: string;
}

interface GTMGenericEvent {
  eCat: string;
  eAct: string;
  eLab: string;
}

interface GTMCtaClick {
  buttonId: string;
}

export interface GTMFunnelData {
  funnelData: Partial<Proposal>;
}

interface GTMInitDataLayer {
  consent: CookiesConsent;
}

interface IGTMLoad {
  'gtm.start': number;
}

interface GTMErrorData {
  errorData: { endpoint: string; params: string; response: string };
}

export const GTM_JS = 'gtm.js';
export const DL_INIT = 'dl_init';
export const CONSENT_UPDATE = 'consent_update';
export const GTM_INIT_DATA_LAYER = 'GTM_INIT_DATA_LAYER';

export const GTMLoad = createAction<TypeConstant, GTMEvent & IGTMLoad, GTMMeta>(
  'GTM_LOAD',
  () => ({
    event: GTM_JS,
    'gtm.start': new Date().getTime(),
  }),
  () => ({ track: true }),
)();

export const trackGenericEvent = createAction<
  TypeConstant,
  GTMEvent & GTMGenericEvent,
  GTMMeta,
  [GTMGenericEvent]
>(
  'GTM_TRACK_GENERIC_EVENT',
  payload => ({ event: 'genericEvent', ...payload }),
  () => ({ track: true }),
)();

export const trackCtaClick = createAction<TypeConstant, GTMEvent & GTMCtaClick, GTMMeta, [string]>(
  'GTM_TRACK_CTA_CLICK',
  payload => ({ buttonId: payload, event: 'buttonClick' }),
  () => ({ track: true }),
)();

export const trackLocation = createAction<
  TypeConstant,
  GTMEvent & GTMTrackLocation,
  GTMMeta,
  [GTMTrackLocation]
>(
  'GTM_TRACK_LOCATION',
  payload => ({ event: 'virtualPageView', ...payload }),
  () => ({ track: true }),
)();

export const activateGoogleOptimize = createAction<TypeConstant, GTMEvent, GTMMeta>(
  'OPTIMIZE_ACTIVATE',
  () => ({ event: 'optimize.activate' }),
  () => ({ track: true }),
)();

export const trackUrlParams = createAction<TypeConstant, GTMEvent, GTMMeta>(
  'URL_PARAMS',
  payload => ({
    event: 'originalUtmParametersOnEntry',
    utmFromStorage: { ...payload },
  }),
  () => ({ track: true }),
)();

export const trackFunnelData = createAction<
  TypeConstant,
  GTMEvent & GTMFunnelData,
  GTMMeta,
  [FunnelTrackingData]
>(
  'GTM_TRACK_FUNNEL_DATA',
  payload => ({ event: 'funnelData', funnelData: payload }),
  () => ({ track: true }),
)();

export const initializeDataLayer = createAction<
  TypeConstant,
  GTMEvent & GTMInitDataLayer,
  GTMMeta,
  [CookiesConsent]
>(
  GTM_INIT_DATA_LAYER,
  payload => ({ consent: payload, event: DL_INIT }),
  () => ({ track: true }),
)();

export const trackCreationError = createAction<
  TypeConstant,
  GTMEvent & GTMErrorData,
  GTMMeta,
  [{ endpoint: string; params: string; response: string }]
>(
  'GTM_TRACK_CREATION_ERROR',
  payload => ({ errorData: payload, event: 'creationError' }),
  () => ({ track: true }),
)();

export const setTrackingParams = createAction('SET_TRACKING_PARAMS')<Partial<TrackingParams>>();
export const setTrackingCookies = createAction('SET_TRACKING_COOKIES')<Partial<TrackingCookies>>();

export default {
  GTMLoad,
  activateGoogleOptimize,
  initializeDataLayer,
  setTrackingCookies,
  setTrackingParams,
  trackCreationError,
  trackCtaClick,
  trackFunnelData,
  trackGenericEvent,
  trackLocation,
  trackUrlParams,
};

export const trackUserInteraction = (eAct: string, eLab: string) =>
  trackGenericEvent({ eAct, eCat: 'User Interaction', eLab });
