/* eslint-disable no-console */
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

interface LocationState {
  restoreScrollPosition?: boolean;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (enabled = true, storagePrefix = 'scroll-position:') => {
  const { path } = useRouteMatch();
  const { action } = useHistory();
  const location = useLocation<LocationState>();
  const { restoreScrollPosition: shouldRestoreScrollPosition = false } = location.state || {};

  useEffect(() => {
    if (enabled) {
      try {
        const item = window.sessionStorage.getItem(storagePrefix + path);
        const scrollPosition = item ? JSON.parse(item) : 0;
        const saveScrollPosition = debounce(() => {
          try {
            window.sessionStorage.setItem(storagePrefix + path, JSON.stringify(window.scrollY));
          } catch (error: any) {
            console.error(error.message);
          }
        }, 500);

        window.scrollTo(0, shouldRestoreScrollPosition || action === 'POP' ? scrollPosition : 0);
        window.addEventListener('scroll', saveScrollPosition);

        return () => {
          saveScrollPosition.flush();
          window.removeEventListener('scroll', saveScrollPosition);
        };
      } catch (error: any) {
        console.error(error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, path, shouldRestoreScrollPosition, enabled]);
};
