import { Row, Flex, Markdown, ActionButton, StickyActionButton } from 'components';
import * as t from 'data';
import { useSelector, useTrackingLocation, useBusinessAccount, usePhoneDirectory } from 'hooks';
import { styled, mq, css, applyCyAttribute } from 'mymoria-ui';
import { ContactDesktop } from 'mymoria-ui/components/Header';
import { LayoutProps } from 'mymoria-ui/interfaces';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { layout, display } from 'styled-system';
import { FUNNEL_STEPS } from 'types';

import IntroHeader from './IntroHeader';
import { FunnelFooter, Address } from '../layout';

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  ${mq({
    display: ['none', 'none', 'block'],
  })};
`;

const MobileImage = styled.img(
  mq({
    display: ['block', 'block', 'none'],
    width: ['100%', 'auto', '100%'],
  }),
);

const StyledActionButton = styled(ActionButton)<LayoutProps>(
  ({ theme: { fontSizes } }) => css`
    font-size: ${fontSizes.large};
    width: 370px;
  `,
  layout,
  display,
);

const StyledTitle = styled(Markdown)(
  ({ theme: { fontSizes, fontWeights, fonts, space } }) => css`
    font-family: ${fonts.tertiary};
    font-weight: ${fontWeights.bold};
    margin-bottom: ${space[6]};

    ${mq({
      display: ['block', null, 'flex'],
      flexDirection: ['row', null, 'column'],
      textAlign: ['center', null, 'right'],
      whiteSpace: ['initial', null, 'break-spaces'],
    })};
    strong {
      line-height: 1.2;
      font-weight: ${fontWeights.extraBold};
      ${mq({
        padding: ['0 5px 0', null, 'inherit'],
      })};
    }
    ${mq({
      fontSize: [fontSizes.large, null, '3.25rem'],
    })};
  `,
);

const StyledIntroWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  svg {
    ${mq({
      width: ['120px', '120px', '200px'],
    })};
  }
`;

const StyledMainWrapper = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

const StyledFlex = styled(Flex)`
  ${mq({
    alignItems: ['center', 'center', 'unset'],
    boxShadow: ['unset', 'unset', '0px 0 10px 5px rgb(0 0 0 / 20%)'],
  })};
`;

const ClickableFlex = styled(Flex)`
  cursor: pointer;
`;

const IntroStep = ({ history }: RouteComponentProps) => {
  useTrackingLocation('create.intro', 'funnelpage');
  const steps = useSelector(({ proposal }) => proposal.steps) || FUNNEL_STEPS;
  const nextStep = () => history.replace(`/create/${steps[0]}`);
  const { phone, phoneText } = usePhoneDirectory();
  const { phone: opcoPhone } = useBusinessAccount();

  return (
    <>
      <Row
        width="100%"
        height={['90vh', '100vh', '100vh']}
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        alignItems={['center', 'center', 'unset']}
        justifyContent={['space-between', 'space-between', 'unset']}
        marginBottom={['12rem', 'unset']}
      >
        <ClickableFlex
          onClick={nextStep}
          width={[1, 1, 1 / 2]}
          justifyContent={['center', 'center', 'unset']}
        >
          <Image src="/img/intro.webp" {...applyCyAttribute('introImage')} />
          <MobileImage src="/img/intro-mobile.webp" {...applyCyAttribute('introImageMobile')} />
        </ClickableFlex>
        <StyledFlex
          padding={['60px 0 0 0', '60px 0 0 0', '30px 65px 0 0', '60px 120px 0 0']}
          width={[1, 1, 1 / 2]}
          flexDirection="column"
        >
          <StyledIntroWrapper>
            <IntroHeader />
          </StyledIntroWrapper>
          <StyledMainWrapper>
            <div>
              <StyledTitle source={t.funnel.intro.title} {...applyCyAttribute('introTitle')} />
              <StyledActionButton display={['none', 'none', 'block']} onClick={nextStep}>
                {t.funnel.intro.buttonLabel}
              </StyledActionButton>
            </div>
          </StyledMainWrapper>
          <StickyActionButton onClick={nextStep}>{t.funnel.intro.buttonLabel}</StickyActionButton>
        </StyledFlex>
      </Row>
      <Address display={['block', 'none']} width="100%">
        <ContactDesktop
          label={t.global.header.phoneText}
          phone={opcoPhone || phoneText || t.global.header.phoneNumber}
          phoneHref={opcoPhone || phone}
        />
      </Address>
      <FunnelFooter
        links={t.footer.secondaryLinks}
        copyright={t.footer.copyright}
        padding={['0 1rem 8rem', null, '1rem']}
      />
    </>
  );
};

export default IntroStep;
