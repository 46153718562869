import config from 'config';
import { round } from 'lodash';

const { germanTax, currency } = config;

export const formatPrice = (price: number = 0, { gross = false, fractionDigit = 0 } = {}) =>
  round(gross ? +price * germanTax : +price).toLocaleString('de-DE', {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: fractionDigit,
    style: 'currency',
  });

export const formatPostalCode = (code: number) => code.toString().padStart(5, '0');
