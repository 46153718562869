import { SerializedStyles } from '@emotion/react';
import { Header, Main, Icon, Loader, Footer, SimpleFooter } from 'components';
import config from 'config';
import * as t from 'data';
import { useBusinessAccount, useCMSSelector, usePhoneDirectory, useScrollRestoration } from 'hooks';
import { styled, css, mq, getValidPhoneFormat } from 'mymoria-ui';
import { colors } from 'mymoria-ui/styles';
import React, { FunctionComponent } from 'react';
import { Rating } from 'types';

export interface PageProps {
  withHeader?: boolean;
  withHeaderContact?: boolean;
  headerType?: 'ghost' | 'block';
  className?: string;
  isLoading?: boolean;
  footer?: 'default' | 'simple' | 'none';
  withScrollRestoration?: boolean;
  backgroundColor?: string;
  headerStyles?: SerializedStyles;
  mainStyles?: SerializedStyles;
}

const StyledHeader = styled(Header)<{ headerStyles?: SerializedStyles; opcoLogo?: boolean }>(
  ({ headerStyles, opcoLogo, theme: { space, colors } }) => css`
    ${headerStyles};

    ${mq({
      background: [colors.secondary, 'none'],
      padding: [0, `${space[6]} 0 0 0`, null, opcoLogo ? '2rem 0 0 0' : `4rem 0 0 0`],
    })};
  `,
);

const StyledSimpleFooter = styled(SimpleFooter)`
  z-index: 1;
  width: 'auto';
`;

const StyledMain = styled(Main)<Pick<PageProps, 'mainStyles'> & { background?: string }>(
  ({ mainStyles, background }) => css`
    && {
      ${mainStyles};
      background: ${background};
    }
  `,
);

const OpcoLogo = styled.img(
  mq({
    height: ['60px', '100%'],
    width: ['auto', '250px'],
  }),
);

const Page: FunctionComponent<PageProps> = ({
  children,
  withHeader = true,
  withHeaderContact = false,
  headerType = 'block',
  withScrollRestoration = true,
  isLoading = false,
  headerStyles,
  mainStyles,
  backgroundColor = colors.secondary,
  className,
  footer = 'default',
}) => {
  useScrollRestoration(!isLoading && withScrollRestoration);
  const ratingData: Rating = useCMSSelector(({ Ratings }) => Ratings);

  const partners = config.ratedBusinessPartners.map(partner => {
    if (ratingData) {
      if (partner.name === 'Google') {
        partner.rating = +ratingData.GoogleRating;
        partner.caption = ratingData.GoogleText;
      }
      if (partner.name === 'Trusted Shops') {
        partner.rating = +ratingData.TrustedShopRating;
        partner.caption = ratingData.TrustedShopText;
      }
    }
    return partner;
  });

  const { phone, phoneText } = usePhoneDirectory();
  const { phone: opcoPhone, logoUrl: opcoLogo, name: opcoName } = useBusinessAccount();

  const logo = opcoLogo ? (
    <OpcoLogo src={opcoLogo} alt={opcoName} />
  ) : (
    <Icon.MymoriaLogoDark href={config.web.url} />
  );

  return (
    <>
      {withHeader &&
        (withHeaderContact ? (
          <StyledHeader
            logo={logo}
            variant={headerType}
            opcoLogo={!!opcoLogo}
            contactLabel={t.global.header.phoneText}
            contactPhone={
              getValidPhoneFormat({
                formatType: 'national',
                phone: opcoPhone || phoneText,
                withSlash: true,
              }) || t.global.header.phoneNumber
            }
            contactPhoneHref={opcoPhone || phone}
            headerStyles={headerStyles}
          />
        ) : (
          <StyledHeader
            logo={logo}
            variant={headerType}
            headerStyles={headerStyles}
            opcoLogo={!!opcoLogo}
          />
        ))}
      <StyledMain
        backgroundColor={backgroundColor}
        className={className}
        pt={withHeader ? [0, 0, 6] : 0}
        pb={footer === 'none' ? [6, 12] : 0}
        mainStyles={mainStyles}
      >
        {isLoading ? <Loader delay={1000} /> : children}
      </StyledMain>
      {footer === 'default' && (
        <Footer
          copyright={t.footer.copyright}
          primaryLinks={t.footer.primaryLinks}
          secondaryLinks={t.footer.secondaryLinks}
          // @ts-ignore
          contactLinks={t.footer.contactLinks}
          // @ts-ignore
          socialMediaLinks={t.footer.socialMediaLinks}
          partners={partners}
          mb={[56, 0]}
        />
      )}
      {footer === 'simple' && (
        <StyledSimpleFooter links={t.footer.secondaryLinks} copyright={t.footer.copyright} />
      )}
    </>
  );
};

export default Page;
