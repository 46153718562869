import { partition, sortBy, toString } from 'lodash';
import { Customizations, FuneralPlan, FuneralType } from 'types';
import { ServiceItem } from 'types/api';
import { api, getAxiosData, customize } from 'utils';

const SERVICE_ROUTE = 'servicesv2';

interface DefaultServicesResponse {
  basic: ServiceItem[];
  optional: ServiceItem[];
}

const split = (services: ServiceItem[]) =>
  partition<ServiceItem>(sortBy(services, 'identifier'), ({ optional }) => !optional);

const parse = (selectedServices: ServiceItem[], allServices: ServiceItem[]) => {
  const [selectedBasicServices, selectedOptionalServices] = split(selectedServices);
  const [allBasicServices, allOptionalServices] = split(allServices);

  return {
    all: {
      basic: allBasicServices,
      optional: allOptionalServices,
    },
    selected: {
      basicServices: selectedBasicServices,
      optionalServices: selectedOptionalServices,
    },
  };
};

const fetchDefault = (pricebookId: string, plan: FuneralPlan, type: FuneralType) =>
  api
    .get<DefaultServicesResponse>(`/${SERVICE_ROUTE}/default`, {
      params: { plan, pricebookId, type },
    })
    .then(({ data: { basic, optional } }) => ({ basic, optional }));

const parseServicesAlongStore = (
  selectedServices: ServiceItem[],
  defaultServices: DefaultServicesResponse,
  customizations?: Customizations,
) =>
  parse(
    selectedServices,
    customize([...defaultServices.basic, ...defaultServices.optional], customizations?.services),
  );

interface interfaceFetchSelected {
  pricebookId: string;
  plan: FuneralPlan;
  type: FuneralType;
  SKUs: string[];
  customizations?: Customizations;
}
const fetchSelected = ({ pricebookId, plan, type, SKUs, customizations }: interfaceFetchSelected) =>
  Promise.all([
    SKUs.length > 0
      ? api
          .get<ServiceItem[]>(`/${SERVICE_ROUTE}`, {
            params: { SKUs: toString(SKUs), pricebookId },
          })
          .then(getAxiosData)
      : [],
    fetchDefault(pricebookId, plan, type),
  ]).then(([selectedServices, defaultServices]) =>
    parse(
      selectedServices,
      customize([...defaultServices.basic, ...defaultServices.optional], customizations?.services),
    ),
  );

export default {
  fetchDefault,
  fetchSelected,
  parse,
  parseServicesAlongStore,
  split,
};
