import { trackUserInteraction } from 'actions/tracking';
import { ProductListItem } from 'components';
import { formatPrice } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';

interface CatalogProductsListItemProps {
  id: string;
}

const CatalogProductsListItem: FunctionComponent<CatalogProductsListItemProps> = ({ id }) => {
  const product = useSelector(({ entities }) => entities.products[id]);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  if (!product) {
    return null;
  }

  const handleClick = () => {
    dispatch(trackUserInteraction('Catalog: Product Details', product.translations.shortname));
    push(`${url}/${id}`);
  };

  return (
    <ProductListItem
      name={product.translations.shortname}
      image={`${product.pictures[0] || product.placeholder}?width=300&height=200`}
      hoverImage={product.pictures[1] ? `${product.pictures[1]}?width=300&height=200` : undefined}
      price={formatPrice(product.price, { gross: false })}
      onClick={handleClick}
    />
  );
};

export default CatalogProductsListItem;
