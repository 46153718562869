import { googleMap } from 'data';
import { getEstimatedGravePrice } from 'helpers';
import { useSelector } from 'hooks';
import { Button, Col, EditButton, Icon, Row, Text } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { FuneralSite, FuneralType } from 'types';

interface FuneralSiteDetailsProps {
  funeralSite: FuneralSite;
  funeralType: FuneralType;
  onEdit: () => void;
  onDelete: () => void;
  graveType?: string;
}

const FuneralSiteDetails: FunctionComponent<FuneralSiteDetailsProps> = ({
  funeralSite,
  funeralType,
  onEdit,
  onDelete,
  graveType = '',
}) => {
  const grave = useSelector(({ entities }) => entities.graveTypes[graveType]);

  return (
    <Row my={5} flexWrap="wrap">
      <Col width={1}>
        {grave ? (
          <>
            <Text fontWeight="bold" mb={1}>{`${grave.translations.name} ${getEstimatedGravePrice(
              funeralSite,
              graveType,
            )}`}</Text>
            <Text>{grave.translations.description}</Text>
          </>
        ) : (
          <Text fontWeight="bold">{googleMap.infoBox.noGrave}</Text>
        )}
      </Col>
      <Row justifyContent="space-between" flexWrap="wrap" mt={6} px={1}>
        <EditButton onClick={onEdit} mr={6} mb={3}>
          {googleMap.infoBox.editGraveType}
        </EditButton>
        {funeralType !== 'sea' && (
          <Button onClick={onDelete}>
            <Icon.Delete /> {googleMap.infoBox.deleteFuneralsite}
          </Button>
        )}
      </Row>
    </Row>
  );
};

export default FuneralSiteDetails;
