import { Icon, Heading, Markdown } from 'components';
import config from 'config';
import { global, offer } from 'data';
import { useHistory, useSelector } from 'hooks';
import { styled, css } from 'mymoria-ui/utils';
import { stringify } from 'qs';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getCustomerPhone } from 'reducers/proposal';
import { sprintf } from 'utils';

import MobileButton from '../../MobileButton';

const StyledMarkdown = styled(Markdown)(
  ({ theme: { fontWeights } }) => css`
    a {
      text-decoration: underline;
      font-weight: ${fontWeights.bold};
    }
  `,
);

const Result = () => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const phone = useSelector(getCustomerPhone);
  const contactUsType = useSelector(({ proposal }) => proposal.contactUsType) || 'whatsapp';
  const proposalNumber = useSelector(({ proposal }) => proposal.number);
  const city = useSelector(({ proposal }) => proposal.city);
  const funeralType = useSelector(({ proposal }) => proposal.funeralType);
  const firstname = useSelector(({ proposal }) => proposal.firstname);
  const lastname = useSelector(({ proposal }) => proposal.lastname);
  const email = useSelector(({ proposal }) => proposal.email);

  const handleWhatsappClick = () => {
    const url = `${config.whatsApp.url}?${stringify({
      text: sprintf(global.contactUs.contact.result[contactUsType].text, {
        city,
        firstname,
        funeralType: offer.funeralType[funeralType],
        lastname,
        proposalNumber,
      }),
    })}`;

    window.open(url, '_blank');
  };

  return (
    <>
      <Icon.GreenCheck width="40px" height="40px" />
      <Heading.Heading3 mt="normal" fontWeight="extraBold" mb="small" textAlign="center">
        {global.contactUs.contact.result[contactUsType].title}
      </Heading.Heading3>

      <StyledMarkdown textAlign="center" fontWeight="medium" mb="small">
        {contactUsType === 'whatsapp'
          ? sprintf(global.contactUs.contact.result[contactUsType].msg, {
              proposalNumber,
              whatsappUrl: `${config.whatsApp.url}?${stringify({
                text: sprintf(global.contactUs.contact.result[contactUsType].text, {
                  city,
                  firstname,
                  funeralType: offer.funeralType[funeralType],
                  lastname,
                  proposalNumber,
                }),
              })}:_blank`,
            })
          : sprintf(global.contactUs.contact.result[contactUsType].msg, {
              customerEmail: email,
              proposalNumber,
            })}
      </StyledMarkdown>
      <StyledMarkdown
        fontWeight="medium"
        textAlign="center"
        mb="small"
        children={sprintf(global.contactUs.contact.result[contactUsType].phone, {
          customerPhone: phone,
          url: `${url}/contact-details`,
        })}
      />
      {contactUsType === 'whatsapp' ? (
        <MobileButton
          mt="2rem"
          label={global.contactUs.contact.result.whatsapp.buttonTitle}
          onClick={handleWhatsappClick}
        />
      ) : (
        <MobileButton
          mt="2rem"
          label={global.contactUs.contact.result[contactUsType].buttonTitle}
          onClick={() => window.open(global.contactUs.contact.result[contactUsType].text, '_self')}
        />
      )}
      <MobileButton
        mt="2rem"
        label={global.contactUs.editInfoButton}
        onClick={() => push(`${url}/contact-details`)}
      />
    </>
  );
};
export default Result;
