import { PageProps } from './Page';

const offerPage: PageProps = {
  withHeaderContact: true,
};

const formPage: PageProps = {
  footer: 'none',
  withHeaderContact: true,
};

const catalogPage: PageProps = {
  footer: 'none',
};

const loadingPage: PageProps = {
  footer: 'none',
};

const notFoundPage: PageProps = {
  footer: 'none',
  withScrollRestoration: false,
};

export default {
  catalogPageTemplate: catalogPage,
  formPageTemplate: formPage,
  loadingPageTemplate: loadingPage,
  notFoundPageTemplate: notFoundPage,
  offerPageTemplate: offerPage,
};
