import { css, applyCyAttribute, mq, styled } from 'mymoria-ui';
import { Flex, HintBanner, Icon, Markdown } from 'mymoria-ui/components';
import React, { useState, FunctionComponent, ReactNode } from 'react';

const Wrapper = styled.div(css`
  user-select: none;
  width: 100vw;
`);

const StyledOpenHint = styled(Icon.OpenHint)(
  ({ theme: { space } }) => css`
    cursor: pointer;
    vertical-align: middle;

    ${mq({ paddingLeft: [0, space.extraTiny] })};
  `,
);

const StyledCloseHint = styled(Icon.CloseHint)(
  ({ theme: { space } }) => css`
    cursor: pointer;
    vertical-align: middle;
    ${mq({ paddingLeft: [0, space.extraTiny] })};
  `,
);

const ImageWrapper = styled.div(css`
  min-width: 67px;
  min-height: 67px;
  max-width: 67px;
  max-height: 67px;
  align-self: flex-start;
  margin-right: 0.75rem;
`);

const StyledMarkdown = styled(Markdown)(
  ({ theme: { fontSizes } }) => css`
    text-align: left;
    ${mq({ fontSize: [fontSizes.large, '2.25rem'] })}
  `,
);

interface StepTitleProps {
  icon?: ReactNode;
  title: string;
  hint?: string;
}

const StepTitle: FunctionComponent<StepTitleProps> = ({ title, icon, hint }) => {
  const [isHintHidden, setIsHintHidden] = useState(true);

  return (
    <Wrapper>
      <Flex justifyContent="center" alignItems="center" mb="small" p="0px 26px" width="100%">
        {icon ? <ImageWrapper {...applyCyAttribute('stepTitleIcon')}>{icon}</ImageWrapper> : null}
        <StyledMarkdown
          fontWeight="medium"
          letterSpacing="0.05rem"
          source={title}
          textAlign="center"
          suffixElement={
            hint ? (
              isHintHidden ? (
                <StyledOpenHint
                  onClick={() => setIsHintHidden(false)}
                  {...applyCyAttribute('hint', 'iconInfo')}
                />
              ) : (
                <StyledCloseHint
                  onClick={() => setIsHintHidden(true)}
                  {...applyCyAttribute('hint', 'iconClose')}
                />
              )
            ) : null
          }
        />
      </Flex>
      <HintBanner hidden={isHintHidden} text={`${hint}`} />
    </Wrapper>
  );
};
export default StepTitle;
