import { funeralSiteAPI } from 'api';
import { Dispatch } from 'redux';
import { FuneralSite, FuneralType } from 'types';
import { createAction } from 'typesafe-actions';

const fetchFuneralSiteAction = createAction('FETCH_FUNERAL_SITE')<FuneralSite>();
const fetchClosestFuneralSiteAction = createAction('FETCH_FUNERAL_SITE')<FuneralSite>();

export default {
  fetchClosestFuneralSite: fetchClosestFuneralSiteAction,
  fetchFuneralSite: fetchFuneralSiteAction,
};

export const fetchFuneralSite = (id: string, graveType?: string) => (dispatch: Dispatch) =>
  funeralSiteAPI
    .fetch(id, graveType)
    .then(funeralSite => dispatch(fetchFuneralSiteAction(funeralSite)))
    .then(({ payload }) => payload);

export const fetchClosestFuneralSite =
  (lat: number, lon: number, type: FuneralType, name: string) => (dispatch: Dispatch) =>
    funeralSiteAPI
      .fetchClosest(lat, lon, type, name)
      .then(funeralSite => dispatch(fetchClosestFuneralSiteAction(funeralSite)))
      .then(({ payload }) => payload);
