import { css, styled } from 'mymoria-ui';
import Button, { ButtonProps } from 'mymoria-ui/components/Button/Button';
import React, { FunctionComponent } from 'react';

interface FunnelButtonProps extends ButtonProps {
  selected?: boolean;
}

const StyledButton = styled(Button)<FunnelButtonProps>(
  ({ selected = false, theme: { colors } }) =>
    selected &&
    css`
      && {
        border: 4px solid ${colors.primary};
        line-height: 45px;
      }
    `,
);

const FunnelButton: FunctionComponent<FunnelButtonProps> = ({
  children,
  selected = false,
  ...props
}) => (
  <StyledButton block selected={selected} {...props}>
    {children}
  </StyledButton>
);

export default FunnelButton;
