import { Col, Row } from 'components';
import { useSelector } from 'hooks';
import React, { useState } from 'react';
import { ContactUsWizardStepsType } from 'types';

import Callback from './Coming/Callback';
import Contact from './Coming/Contact';
import ComingIntro from './Coming/Intro';
import OccuredIntro from './Occured/Intro';

interface Props {
  hideModal: () => void;
}

const ContactUsModal = ({ hideModal }: Props) => {
  const [wizardType, setWizardType] = useState<ContactUsWizardStepsType>('Intro');
  const concernType = useSelector(({ proposal }) => proposal.concernType);

  const renderWizardPage = (type: ContactUsWizardStepsType) => {
    switch (type) {
      case 'Callback':
        return <Callback setWizardType={setWizardType} />;

      case 'Contact':
        return <Contact setWizardType={setWizardType} />;

      default:
        return concernType === 'coming' ? (
          <ComingIntro setWizardType={setWizardType} />
        ) : (
          <OccuredIntro hideModal={hideModal} />
        );
    }
  };

  return (
    <>
      <Row flexDirection="column" justifyContent="center" alignItems="center">
        <Col
          width={[1, '80%', '70%']}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {renderWizardPage(wizardType)}
        </Col>
      </Row>
    </>
  );
};

export default ContactUsModal;
