import { CustomOfferItemPreviewV2 } from 'components';
import { OptionalItemPreview } from 'components/Offer';
import { offer } from 'data';
import { formatPrice } from 'helpers/formatters';
import { useSelector } from 'hooks';
import { keyBy } from 'lodash';
import { styled } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';
import { isOfferEditable } from 'reducers/proposal';
import { CustomizedItemsOrder } from 'types';

import OfferPageCard from './OfferPageCard';

const StyledCard = styled(OfferPageCard)(
  `
    && {
      padding: 1.5rem 0;
    }

    & > div:first-of-type {
      padding: 0 1.5rem;
      margin-bottom: 1rem;
      & + hr {
        display: none;
      }
    }

    & > hr {
      & + hr {
        display: none;
      }
      &:last-child {
        display: none;
      }
    }
  `,
);

const SelectedOptionalItemsCard: FunctionComponent<{ customized?: boolean }> = ({
  customized = false,
}) => {
  const isEditable = useSelector(isOfferEditable());
  const products = useSelector(({ proposal }) => [
    ...proposal.optionalProducts,
    ...proposal.basicProducts,
  ]);
  const services = useSelector(({ proposal }) => [
    ...proposal.optionalServices,
    ...proposal.basicServices,
  ]);
  const extras = useSelector(({ proposal }) => proposal.extras);
  const allItemsDetails = {
    ...useSelector(({ entities }) => entities['products']),
    ...useSelector(({ entities }) => entities['services']),
  };
  const extrasMapped = keyBy(extras, 'id');

  const sortedItemsForCustomizedState = customized
    ? [
        ...products.map(product => {
          return {
            entity: 'product',
            id: product,
            sortOrder: allItemsDetails[product]?.sortOrder || 0,
          } as CustomizedItemsOrder;
        }),
        ...services.map(service => {
          return {
            entity: 'service',
            id: service,
            sortOrder: allItemsDetails[service]?.sortOrder || 0,
          } as CustomizedItemsOrder;
        }),
        ...(extras
          ? extras.map(extra => {
              return {
                entity: 'extra',
                id: extra.id,
                sortOrder: extra.sortOrder || 0,
              } as CustomizedItemsOrder;
            })
          : []),
      ].sort((a, b) => a.sortOrder - b.sortOrder)
    : [];

  const items = [
    ...(!customized
      ? [
          ...products.map(id => <OptionalItemPreview key={id} id={id} type="products" />),
          ...services.map(id => <OptionalItemPreview key={id} id={id} type="services" />),
          ...(extras || [])
            .filter(({ category }) => category !== 'serviceDiscount')
            .map(
              ({
                id,
                translations: { shortname, description },
                price,
                pictures,
                quantity,
                placeholder,
              }) => {
                return (
                  <CustomOfferItemPreviewV2
                    id={id}
                    key={id}
                    selected={isEditable}
                    isEditable={isEditable}
                    name={shortname}
                    price={price}
                    priceFormatter={formatPrice}
                    image={pictures[0] || placeholder}
                    description={description}
                    quantity={quantity}
                  />
                );
              },
            ),
        ]
      : [
          ...sortedItemsForCustomizedState!.map(({ id, entity }) => {
            switch (entity) {
              case 'product':
                return <OptionalItemPreview key={id} id={id} type="products" />;
              case 'service':
                return <OptionalItemPreview key={id} id={id} type="services" />;
              // extra
              default:
                const {
                  translations: { shortname, description },
                  price,
                  pictures,
                  quantity,
                  placeholder,
                } = extrasMapped[id];
                return (
                  <CustomOfferItemPreviewV2
                    id={id}
                    key={id}
                    selected={isEditable}
                    isEditable={isEditable}
                    name={shortname}
                    price={price}
                    priceFormatter={formatPrice}
                    image={pictures[0] || placeholder}
                    description={description}
                    quantity={quantity}
                  />
                );
            }
          }),
        ]),
  ].flatMap((node, index, array) =>
    index === array.length - 1 ? node : [node, <hr key={`separator-${index}`} />],
  );

  if (items.length === 0) {
    return null;
  }

  return <StyledCard title={offer.offerDetails.othersTitle}>{items}</StyledCard>;
};

export default SelectedOptionalItemsCard;
