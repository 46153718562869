import { parseQueryParams } from 'helpers';
import { useSelector } from 'hooks';
import noop from 'lodash/noop';
import React, { createContext, FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BusinessAccount } from 'types';
import { api } from 'utils';

const initBusinessAccount = {} as BusinessAccount;
export const BusinessAccountContext = createContext<BusinessAccount>(initBusinessAccount);

const BusinessAccountProvider: FunctionComponent = ({ children }) => {
  const { search } = useLocation();
  const [businessAccount, setBusinessAccount] = useState<BusinessAccount>(initBusinessAccount);
  const oppLocation = useSelector(({ tracking }) => tracking.oppLocation);

  useEffect(() => {
    const { opp_location } = parseQueryParams(search, 'opp_location');
    const opcoLocationId = opp_location || oppLocation;

    if (window.location.pathname.split('/')[1] !== 'offer' && !opcoLocationId) {
      return;
    }

    api
      .get(`/salesforce/business-account/${opcoLocationId}`)
      .then(result => setBusinessAccount(result.data))
      .catch(noop);
  }, [oppLocation, search]);

  return (
    <BusinessAccountContext.Provider value={businessAccount}>
      {children}
    </BusinessAccountContext.Provider>
  );
};

export default BusinessAccountProvider;
