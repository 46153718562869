import { setFunnelData } from 'actions/proposal';
import { trackFunnelData } from 'actions/tracking';
import { MobileDataStepImage, DesktopDataStepImage } from 'assets';
import { ActionButton, Box, Col, Flex, Markdown, Message, Row, StepProgressBar } from 'components';
import PrefetchImage from 'components/PrefetchImage';
import * as t from 'data';
import { Form, Formik } from 'formik';
import { InputField, EmailField, SelectField, PhoneField, RadioGroupField } from 'forms/fields';
import { salutationOptions, callbackTimeOptions } from 'forms/options';
import { contactDetailsSchema } from 'helpers';
import { useDispatch, useHistory, useSelector } from 'hooks';
import { styled, css, mq } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';
import { ContactDetails } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const StyledActionButton = styled(ActionButton)`
  width: 100%;
`;

const StyledImg = styled.img(css`
  ${mq({
    height: ['200px', null, '100%'],
    objectFit: 'cover',
    objectPosition: ['2% 15%', null, 'top'],
    width: ['100vw', null, '100%'],
  })};
`);

const Terms = styled(Markdown)`
  a {
    text-decoration: underline;
  }

  text-align: center;
`;

const ImageBoxWrapper = styled(Box)`
  ${mq({
    marginLeft: ['calc(50% - 50vw)', null, '0'],
    marginRight: ['calc(50% - 50vw)', null, '0'],
  })};
`;

const ContactDetailsStep: FunctionComponent<FunnelStepProps> = ({ onStepLoad }) => {
  const { push: historyPush } = useHistory();
  const dispatch = useDispatch();
  const {
    salutation,
    firstname = '',
    lastname = '',
    email = '',
    phone = '',
    concernType,
  } = useSelector(({ proposal }) => proposal);
  const oppOwner = useSelector(({ tracking }) => tracking.oppOwner);
  onStepLoad();

  const handleSubmit = async (contactDetails: ContactDetails) => {
    dispatch(setFunnelData(contactDetails));
    dispatch(trackFunnelData(contactDetails));

    await sleep(ANIMATION_DELAY);
    // clean landing page url cookie
    historyPush('/offer/prepare', { contactDetails, fromFunnel: true });
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          email,
          firstname,
          lastname,
          phone,
          salutation,
        }}
        validationSchema={contactDetailsSchema}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form noValidate>
            <Flex
              mr={['0', '0', '-130px', '-90px']}
              ml={['0', '0', '-130px', '-90px']}
              flexDirection={['column', 'column', 'row', 'row']}
            >
              <Col
                flexWrap="wrap"
                justifyContent="center"
                width={['100%', '100%', '50%', '50%']}
                mr={7}
                order={[2, 2, 1, 1]}
              >
                <Col width="100%">
                  <Row py={3}>
                    <RadioGroupField
                      name="salutation"
                      values={salutationOptions}
                      label={t.form.salutationLabel}
                    />
                  </Row>

                  <Row flexWrap="wrap" mx={-2}>
                    <Col width={1} py={1} px={2}>
                      <InputField name="firstname" label={t.form.firstname} />
                    </Col>
                    <Col width={1} py={1} px={2}>
                      <InputField name="lastname" label={t.form.lastname} />
                    </Col>
                  </Row>

                  <Row flexWrap="wrap" mx={-2}>
                    <Col width={1} py={1} px={2}>
                      <EmailField
                        name="email"
                        label={t.form.email}
                        type="email"
                        inputMode="email"
                        hint={t.form.emailHint}
                        placeholder={t.form.emailPlaceholder}
                      />
                    </Col>
                    <Col width={1} py={1} px={2}>
                      <PhoneField
                        name="phone"
                        label={t.form.phone}
                        hint={t.form.phoneHint}
                        placeholder={t.form.phonePlaceholder}
                      />
                    </Col>
                  </Row>
                  {concernType === 'coming' && (
                    <Row flexWrap="wrap" mx={-2} justifyContent="center">
                      <Col width={[1, 1, 1]} py={1} px={2}>
                        <SelectField
                          name="callbackTime"
                          label={t.form.callbackTime.label}
                          placeholder={t.form.callbackTime.placeholder}
                          options={callbackTimeOptions}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Col>
              <Col
                width={['100%', '100%', '50%', '50%']}
                ml={[null, null, '2.5rem', '2.5rem']}
                order={[1, 1, 2, 2]}
                display="flex"
                flexDirection={['column-reverse', null, 'column']}
              >
                <Col py={3} px={0}>
                  <StepProgressBar
                    step={
                      Object.values(values).filter(str => {
                        return str !== '' && str;
                      }).length
                    }
                    stepsNumber={Object.keys(values).length}
                    label="Ihr Angebot wird erstellt (%(percentage)%)"
                  />
                </Col>
                <ImageBoxWrapper display={['none', null, 'flex']}>
                  <StyledImg src={DesktopDataStepImage} />
                </ImageBoxWrapper>
                <ImageBoxWrapper display={['flex', null, 'none']}>
                  <StyledImg src={MobileDataStepImage} />
                </ImageBoxWrapper>
              </Col>
            </Flex>

            <Flex alignItems="center" flexDirection="column">
              {oppOwner && (
                <Message mt={[3, 6]}>{t.funnel.contactDetails.noAutomaticEmailSent}</Message>
              )}
              <Row width={[1, 1, 0.7, 0.7]} mt={4} alignSelf="center">
                <Terms m={0}>{t.funnel.contactDetails.terms}</Terms>
              </Row>
              <Row width={[1, 1, 0.7, 0.7]} mt={4} mb={1} display="flex" justifyContent="center">
                <StyledActionButton onClick={submitForm} disabled={isSubmitting} shadow={true}>
                  {t.funnel.contactDetails.submit}
                </StyledActionButton>
              </Row>
            </Flex>
          </Form>
        )}
      </Formik>
      <PrefetchImage alt="AnimationScreenImage-1" src={t.funnelWaitingScreens.steps[0].asset} />
    </div>
  );
};

export default ContactDetailsStep;
