import { setFunnelData } from 'actions/proposal';
import { trackFunnelData } from 'actions/tracking';
import { StepProps } from 'components/StepWizard';
import { getQueryParams, funnelSchema } from 'helpers';
import { STEP_TO_FUNNEL_KEY, STEP_TO_TRACKING_NAME } from 'helpers/funnelSteps';
import { useDispatch, useSelector } from 'hooks';
import { identity, invert, isEmpty, omit } from 'lodash';
import { useLayoutEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import {
  FunnelAdditionalData,
  FunnelStep,
  FunnelTrackingData,
  FUNNEL_STEPS,
  Proposal,
} from 'types';

export const useStepValidation = ({
  step: currentStep,
  steps,
  replace,
}: Omit<StepProps<FunnelStep>, 'onStepLoad'>) => {
  const proposal = useSelector(({ proposal }) => proposal);
  const [entryStep] =
    Object.entries(STEP_TO_FUNNEL_KEY).find(
      ([step, funnelKey]) => isEmpty(proposal[funnelKey]) && steps.includes(step as FunnelStep),
    ) || [];

  useLayoutEffect(() => {
    if (
      entryStep &&
      FUNNEL_STEPS.indexOf(currentStep) > FUNNEL_STEPS.indexOf(entryStep as FunnelStep)
    ) {
      replace(steps[0]);
    }
  }, [currentStep, entryStep, replace, steps]);
};

export const useSkipSteps = ({ history, match, location }: RouteComponentProps) => {
  const { search } = useLocation();
  const params = getQueryParams<Partial<Proposal> & FunnelAdditionalData>(search, funnelSchema);
  const dispatch = useDispatch();
  const steps = useSelector(({ proposal }) => proposal.steps);

  useLayoutEffect(() => {
    let availableSteps = [...FUNNEL_STEPS];
    if (!isEmpty(params)) {
      let trackingData = omit(params, 'skip');
      let funnelData = { ...params, steps };
      let trackingSkippedSteps = [''];

      if (params.skip) {
        const invertedMap = invert(STEP_TO_FUNNEL_KEY);
        const skippedSteps = Object.keys(params)
          .map(param => invertedMap[param])
          .filter(identity) as FunnelStep[];

        // if skip === true, skip deceased-location step
        skippedSteps.push('deceased-location');

        trackingSkippedSteps = skippedSteps.map(step => STEP_TO_TRACKING_NAME[step]);
        (trackingData as FunnelTrackingData)['skipped_steps'] = trackingSkippedSteps;

        availableSteps = availableSteps.filter(step => !skippedSteps.includes(step));
        if (!isEmpty(availableSteps)) {
          funnelData['steps'] = availableSteps;
        }
      }

      if (params.skipIntro) {
        (trackingData as FunnelTrackingData)['skipped_steps'] = [
          'create.intro',
          ...trackingSkippedSteps,
        ];
      }

      history.replace(`${match.url}/${params.skipIntro ? availableSteps[0] : '/intro'}`, {
        search: undefined,
      });
      dispatch(setFunnelData(funnelData));
      dispatch(trackFunnelData(trackingData));
    } else if (location.pathname === '/create') {
      history.replace(`${match.url}/intro`, { search: undefined });
    }
  }, [dispatch, history, location.pathname, match.url, params, steps]);
};
