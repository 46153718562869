import { ReactComponent as Loader1 } from 'assets/loader/loader1.svg';
import { ReactComponent as Loader2 } from 'assets/loader/loader2.svg';
import { ReactComponent as Loader3 } from 'assets/loader/loader3.svg';
import { ReactComponent as Loader4 } from 'assets/loader/loader4.svg';
import { Col } from 'mymoria-ui/components';
import { styled } from 'mymoria-ui/utils';
import React from 'react';

const Container = styled(Col)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  svg {
    opacity: 0.1;
    animation: fadein 3s ease-in-out infinite forwards;
    -moz-animation: fadein 3s ease-in-out infinite forwards;
    // Firefox
    -webkit-animation: fadein 3s ease-in-out infinite forwards;
    // Safari and Chrome */
    -o-animation: fadein 3s ease-in-out infinite forwards;
    // Opera
    width: 72px;
    height: auto;
  }

  svg:nth-of-type(1) {
    animation-delay: 1s;
  }

  svg:nth-of-type(2) {
    animation-delay: 1.5s;
  }

  svg:nth-of-type(3) {
    animation-delay: 2s;
  }

  svg:nth-of-type(4) {
    animation-delay: 2.5s;
  }

  @keyframes fadein {
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 0.1;
    }
  }

  @-moz-keyframes fadein {
    /* Firefox */
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 0.1;
    }
  }

  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 0.1;
    }
  }

  @-o-keyframes fadein {
    /* Opera */
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 0.1;
    }
  }
`;

const Loader = () => (
  <Container px={0}>
    <Loader1 />
    <Loader4 />
    <Loader3 />
    <Loader2 />
  </Container>
);

export default Loader;
