import { omitAppendFunnelStep, setFunnelData } from 'actions/proposal';
import { trackFunnelData } from 'actions/tracking';
import { Col, Row, Text, Caption } from 'components';
import config from 'config';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { FunnelRadioButtonGroup, FunnelRadioButton } from 'mymoria-ui/components/Button';
import React, { FunctionComponent } from 'react';
import { ConcernType } from 'types';
import { sleep } from 'utils';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';

const ConcernTypeStep: FunctionComponent<FunnelStepProps> = ({ next, steps }) => {
  const dispatch = useDispatch();
  const defaultConcernType = useSelector(({ proposal }) => proposal.concernType);
  const financePartner = useSelector(({ proposal }) => proposal.fp);
  const salesPersonId = useSelector(({ proposal }) => proposal.vermittler);
  const shouldSkip = useSelector(({ proposal }) => proposal.skip);

  const handleSubmit = async (concernType: ConcernType) => {
    if (concernType === 'provision') {
      const url = new URL(config.provisionPortal.url);

      if (financePartner && salesPersonId) {
        url.searchParams.append('fp', financePartner);
        url.searchParams.append('vermittler', salesPersonId);
      }

      return window.open(url.toString(), '_self');
    }

    dispatch(setFunnelData({ concernType }));
    dispatch(trackFunnelData({ concernType }));

    // skip omits deceased-location step
    if (!shouldSkip) {
      dispatch(
        omitAppendFunnelStep({
          action: concernType === 'occured' ? 'APPEND' : 'OMIT',
          step: 'deceased-location',
        }),
      );
    }
    await sleep(ANIMATION_DELAY);
    next();
  };

  return (
    <Row flexWrap="wrap" justifyContent="center">
      <FunnelRadioButtonGroup
        name="concernType"
        defaultValue={defaultConcernType}
        onChange={e => handleSubmit(e.target.value as ConcernType)}
      >
        {t.funnel.concernType.options.map(({ value, label, hint }) => (
          <Col key={value} width={[1, 1 / 3]} px={[0, 'extraTiny']} my="extraTiny">
            <FunnelRadioButton
              value={value}
              key={`concernType-${value}`}
              justifyContent="center"
              padding={['1.25rem', 'small']}
            >
              <Text
                fontWeight="bold"
                fontSize="normal"
                lineHeight="0.94"
                textAlign="center"
                whiteSpace="break-spaces"
              >
                {label}
              </Text>
              <Caption mt="3px" lineHeight="0.94" fontSize="tiny" textAlign="center">
                {hint}
              </Caption>
            </FunnelRadioButton>
          </Col>
        ))}
      </FunnelRadioButtonGroup>
    </Row>
  );
};

export default ConcernTypeStep;
