import { Config } from 'types/config';
import { googleMap } from '../data';

const {
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_GATEWAY,
  REACT_APP_PROVISION_PORTAL_URL,
  REACT_APP_VOLLMACHT_PORTAL_URL,
  REACT_APP_GTM_PROPERTY_ID,
  REACT_APP_GOOGLE_OPTIMIZE_ID,
  REACT_APP_ANALYTICS_TRACKING_ID,
  REACT_APP_WEB,
} = process.env;

const googleMapsKey = REACT_APP_GOOGLE_MAPS_API_KEY || '';
const googleOptimizeId = REACT_APP_GOOGLE_OPTIMIZE_ID || '';

const config: Config = {
  additionalInfoMaxLength: 1000,
  analyticsTrackingId: REACT_APP_ANALYTICS_TRACKING_ID || '',
  animation: {
    durationIn: 4000,
    durationOut: 1500,
  },
  api: {
    endpoint: REACT_APP_GATEWAY || '',
    timeout: 60000,
  },
  app: {
    head: {
      defaultTitle: 'mymoria - Ihr persönliches Angebot',
      productCatalog: `%(name) - Produktkatalog`,
    },
  },
  businessPartners: [
    {
      img: '/img/partners/trustedShops.png',
      name: 'Trusted Shops',
      url: 'https://www.trustedshops.de/bewertung/info_X80F99922883DD5D04258CDF59E117845.html',
    },
  ],
  callbackTime: ['asap', 'morning', 'lunchtime', 'afternoon', 'evening'],
  cms: {
    endpoint: 'https://cms.mymoria.de/api/collections/get',
    origin: process.env.REACT_APP_ORIGIN || 'angebot.mymoria.de',
  },
  cookieSiteSlug: 'gatsby_',
  currency: 'EUR',
  dateFormat: {
    api: 'YYYY.MM.DD',
    pdf: 'DD-MM-YY',
    view: 'DD.MM.YYYY',
  },
  defaultGraveTypes: {
    burial: {
      basic: '',
      high: '',
      premium: '',
    },
    cremation: {
      basic: 'NW',
      high: 'NW',
      premium: 'NW',
    },
    sea: {
      basic: 'SU',
      high: 'SB',
      premium: 'SB',
    },
    tree: {
      basic: 'BBG',
      high: 'BBG',
      premium: 'BBG',
    },
  },
  defaultGraveTypesRegistration: {
    burial: {
      basic: 'ER',
      high: 'ER',
      premium: 'ER',
    },
    cremation: {
      basic: 'UR',
      high: 'UR',
      premium: 'UR',
    },
    sea: {
      basic: 'SU',
      high: 'SB',
      premium: 'SB',
    },
    tree: {
      basic: 'BBG',
      high: 'BBG',
      premium: 'BBG',
    },
  },
  defaultPricebook: '01s6N000000kL5iQAE',
  funeralTypes: [
    {
      description: 'Die Erdbestattung ...',
      graveType: false,
      icon: '/static/icons/funeralType/ic_burial.svg',
      id: 1,
      identifier: 'burial',
      name: 'Erdbestattung',
      supported: true,
      url: '/bestattungsarten/erdbestattung-beisetzung-des-verstorbenen-im-erdreich',
    },
    {
      description: 'Die Feuerbestattung ...',
      graveType: true,
      icon: '/static/icons/funeralType/ic_fire.svg',
      id: 2,
      identifier: 'cremation',
      name: 'Feuerbestattung',
      supported: true,
      url: '/bestattungsarten/feuerbestattung-kremation-des-verstorbenen',
    },
    {
      description: 'Die Seebestattung',
      graveType: true,
      icon: '/static/icons/funeralType/ic_sea.svg',
      id: 3,
      identifier: 'sea',
      name: 'Seebestattung',
      supported: true,
      url: '/seebestattung',
    },
    {
      description: 'Die Baumbestattung ...',
      graveType: true,
      icon: '/static/icons/funeralType/ic_tree.svg',
      id: 4,
      identifier: 'tree',
      name: 'Baumbestattung',
      supported: true,
      url: '/baumbestattung',
    },
    {
      description: 'Die Anonyme Bestattung ...',
      graveType: false,
      icon: '/static/icons/funeralType/ic_anonymous.svg',
      id: 5,
      identifier: 'anonymous',
      name: 'Anonyme Bestattung',
      supported: false,
      url: '/anonyme-bestattung',
    },
  ],
  germanTax: 1.19,
  googleMaps: {
    balticSea: { lat: 54.4839153, lng: 11.2543088 },
    center: { lat: 52.085366, lng: 9.42968 },
    geoLocation: {
      url: `https://www.googleapis.com/geolocation/v1/geolocate?key=${googleMapsKey}`,
    },
    key: googleMapsKey,
    mapOptions: {
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: false,
      zoomControl: true,
    },
    mapType: 'ROADMAP',
    northSea: { lat: 54.2, lng: 6.9533109 },
    recommendationStaticIcons: [
      {
        funeralType: 'sea',
        id: 'northSea',
        picture: '/img/ic_harbour.svg',
        title: googleMap.recommendationBox.northSea,
      },
      {
        funeralType: 'sea',
        id: 'balticSea',
        picture: '/img/ic_harbour.svg',
        title: googleMap.recommendationBox.balticSea,
      },
      {
        funeralType: 'cremation',
        id: 'nearBy',
        picture: '/img/ic-pin.svg',
        title: googleMap.recommendationBox.nearBy,
      },
      {
        funeralType: 'tree',
        id: 'nearBy',
        picture: '/img/ic-pin.svg',
        title: googleMap.recommendationBox.nearBy,
      },
      {
        funeralType: 'tree',
        id: 'treeClosestFS',
        picture: '/img/ic-pin.svg',
        title: googleMap.recommendationBox.treeClosestFS,
      },
    ],
    url: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleMapsKey}`,
    zoom: 13,
    zoomFar: 8,
  },
  googleOptimizeId,
  googleOptimizeUrl: `https://www.googleoptimize.com/optimize.js?id=${googleOptimizeId}`,
  gtmPropertyId: REACT_APP_GTM_PROPERTY_ID || '',
  intent: ['download', 'save', 'callback'],
  mandatoryProductCategories: ['coffin', 'urn'],
  mediaPartners: [
    { img: '/img/media-partners/das-erste.png', name: 'das-erste' },
    { img: '/img/media-partners/bild.png', name: 'bild' },
    { img: '/img/media-partners/wiwo.png', name: 'wiwo' },
    { img: '/img/media-partners/sueddeutsche.png', name: 'sueddeutsche' },
    { img: '/img/media-partners/mdr.png', name: 'mdr' },
    { img: '/img/media-partners/inforadio.png', name: 'inforadio' },
    { img: '/img/media-partners/swr.png', name: 'swr' },
  ],
  paymentPartners: [
    { img: '/img/paypal.svg', name: 'paypal' },
    { img: '/img/sepa.svg', name: 'sepa' },
    { img: '/img/visa.svg', name: 'visa' },
    { img: '/img/master.svg', name: 'master' },
  ],
  phonePattern: /^(0|\+?4)[+\d\s-/]{3,14}$/,
  productsOrder: {
    card: 4,
    coffin: 8,
    flower: 1,
    graveCross: 2,
    grave_goods: 3,
    keepsake: 6,
    notice: 5,
    urn: 7,
  },
  progressBarSections: [
    { name: 'Persönliche Fragen', stepsCount: 2 },
    { labelPosition: 'top', name: 'Bestattung', stepsCount: 3 },
    { name: 'Details zur Bestattung', stepsCount: 1 },
  ],
  provisionPortal: {
    url: REACT_APP_PROVISION_PORTAL_URL || 'https://vorsorge.mymoria.de/register',
  },
  ratedBusinessPartners: [
    {
      caption: 'sehr gut (4.77 / 5.00)',
      img: '/img/partners/trustedShops.png',
      name: 'Trusted Shops',
      rating: 4.7,
      url: 'https://www.trustedshops.de/bewertung/info_X80F99922883DD5D04258CDF59E117845.html',
    },
    {
      caption: 'sehr gut (233)',
      img: '/img/partners/google.svg',
      name: 'Google',
      rating: 4.9,
      url: 'https://www.mymoria.de/ueber-uns/bewertungen-das-sagen-unsere-kunden-ueber-uns/',
    },
  ],
  regex: {
    mymoriaDomain: /@mymoria\.de$/,
    mymoriaEmailRule: /^[\w]*(\+[\w.]*\.+|\.\w[\w.]*\+[\w.])[\w.]*@mymoria\.de$/,
    nameRule:
      /^[A-Za-zªµºÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĊċČčĎďĐđĒēĔĕĖėĘęĚěĞğĠġĢģĦħĨĩĪīĬĭĮįİıĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŞşŠšŢţŤťŦŧŨũŪūŮůŰűŲųŴŵŶŷŸŹźŻżŽžƏƠơƯưƷǍǎǏǐǑǒǓǔǞǟǤǥǦǧǨǩǪǫǬǭǮǯǰǴǵǺǻǼǽǾǿȘșȚțȞȟȪȫȮȯȰȱȲȳəʒḂḃḊḋḐḑḞḟḠḡḤḥḦḧḰḱṀṁṄṅṖṗṠṡṢṣṪṫẀẁẂẃẄẅẌẍẎẏẐẑẒẓẞẠạẢảẤấẦầẪẫẬẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỄễỆệỈỉỊịỌọỎỏỐốỒồỖỗỘộỚớỜờỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹ .-]+$/,
    noLatinChar: /^[A-Za-z-0-9+@._]+$/,
  },
  serverSideCookies: {
    customorigin: window.location.hostname === 'localhost' ? '' : window.location.origin,
    names: 'sessionID;userID',
  },
  trackingCookies: [
    { cookieName: 'sessionID', fieldName: 'sessionID' },
    { cookieName: 'userID', fieldName: 'userID' },
  ],
  trackingParams: [
    'utmMedium',
    'utm_medium',
    'utmSource',
    'utm_source',
    'utmCampaign',
    'utm_campaign',
    'utmContent',
    'utm_content',
    'utmId',
    'utm_id',
    'utm_keyword',
    'utmKeyword',
    'clientidGclid',
    'gclid',
    'gad_source',
    'gbraid',
    'wbraid',
    'msclkid',
    'sourceCookie',
    'landingPageUrl',
    'opp_owner',
    'opp_location',
    'opcocid',
    'msclkid',
    'fbclid',
  ],
  trialPeriod: 30,
  vollmachtPortal: {
    url: REACT_APP_VOLLMACHT_PORTAL_URL || 'https://vollmacht.mymoria.de',
  },
  web: {
    url: REACT_APP_WEB || 'https://mymoria.de/',
  },
  whatsApp: {
    url: 'https://wa.me/+4916093195238',
  },
  wundwPartner: { img: '/img/partners/wundw.svg', name: 'WUNDW' },
};

export default config;
