import { setFunnelData } from 'actions/proposal';
import { trackFunnelData } from 'actions/tracking';
import { Col, Row } from 'components';
import * as t from 'data';
import { Form, Formik } from 'formik';
import { PlaceSearchField } from 'forms/fields';
import { placeSchema } from 'helpers';
import { useDispatch, useSelector, usePhoneDirectory } from 'hooks';
import { isEmpty } from 'lodash';
import { styled, css } from 'mymoria-ui/utils';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Place, PlaceStepFormValues } from 'types';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { sleep } from 'utils';
import * as yup from 'yup';
import { ANIMATION_DELAY, FunnelStepProps } from '../Funnel';
import Button from '../FunnelButton';
import NearBy from '../NearBy';

const StyledButton = styled(Button)(
  ({ theme: { fontSizes, fontWeights } }) => css`
    font-size: ${fontSizes.normal};
    font-weight: ${fontWeights.bold};
  `,
);

const PlaceStep: FunctionComponent<FunnelStepProps> = ({ next, onStepLoad }) => {
  const code = useSelector(({ proposal }) => proposal.code);
  const city = useSelector(({ proposal }) => proposal.city);
  const lat = useSelector(({ proposal }) => proposal.lat);
  const lon = useSelector(({ proposal }) => proposal.lon);
  const country = useSelector(({ proposal }) => proposal.country);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [shouldShowNextButton, setShouldShowNextButton] = useState(!!code && !!city);
  const dispatch = useDispatch();
  const { fetchCity, setPhoneDirectory, city: phoneDirectoryCity } = usePhoneDirectory();
  onStepLoad();

  const handleUpdatePhoneDirectory = (city: string) => {
    if (isEmpty(city) || city === phoneDirectoryCity) return;

    fetchCity(city).then(data => {
      if (data.title === 'default') return;
      setPhoneDirectory(data);
    });
  };

  useEffect(() => {
    if (isKeyboardOpen) {
      document.querySelector('.place')?.scrollIntoView();
    }
  }, [isKeyboardOpen]);

  const handleSubmit = async ({ place }: PlaceStepFormValues) => {
    const data = { ...place, funeralSitePlace: { city: place.city } };
    dispatch(setFunnelData(data));
    dispatch(trackFunnelData(data));
    await sleep(ANIMATION_DELAY);
    next();
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        place: {
          city,
          code,
          country,
          lat,
          lon,
        } as Place,
      }}
      validateOnMount
      // TODO: Fix validation WEB-6396
      validationSchema={yup.object({
        place: placeSchema,
      })}
    >
      {({ values: { place }, setFieldValue, submitForm, initialValues }) => (
        <Form noValidate className="place">
          <Row flexWrap="wrap">
            <Col width={1} my={1} px={0}>
              <PlaceSearchField
                placeholder="z.B. Berlin"
                name="place"
                label={t.form.place}
                onChange={place => {
                  setFieldValue('place', place);

                  if (place.city && place.code) {
                    if (initialValues.place.city || initialValues.place.code) {
                      setShouldShowNextButton(true);
                    } else {
                      handleSubmit({ place });
                    }
                  }
                }}
              />
              <NearBy
                place={place}
                onClick={(city: string, lat: number, lon: number) => {
                  handleUpdatePhoneDirectory(city);
                  setFieldValue('place', {
                    city,
                    lat,
                    lon,
                  });
                }}
              />
              {shouldShowNextButton && (
                <Col width={1} px={0}>
                  <StyledButton
                    type="button"
                    mt={3}
                    onClick={submitForm}
                    disabled={!place?.city && !place?.code}
                  >
                    {t.funnel.place.next}
                  </StyledButton>
                </Col>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PlaceStep;
