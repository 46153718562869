import { FieldConfig, useField } from 'formik';
import Checkbox, { CheckboxProps } from 'mymoria-ui/components/Form/Checkbox';
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';

const CheckboxField: FunctionComponent<Omit<CheckboxProps, 'validate'> & FieldConfig> = ({
  onChange,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      onChange && onChange(e);
    },
    [field, onChange],
  );

  return (
    <Checkbox {...field} {...props} onChange={handleChange} error={meta.touched && meta.error} />
  );
};

export default CheckboxField;
