import { WizardPages } from 'components';
import { Wizard } from 'context';
import React from 'react';

import Options from './Options';
import Result from '../Coming/Callback/Result';

interface Props {
  hideModal: () => void;
}

const Intro = ({ hideModal }: Props) => (
  <Wizard steps={2}>
    <WizardPages>
      <Options onBackClick={hideModal} />
      <Result />
    </WizardPages>
  </Wizard>
);

export default Intro;
