import { Message } from 'components';
import {
  ContactUsFormStickyButton,
  AdditionalCostsBanner,
  BasicInformationCard,
  BasicItemsCard,
  CustomerCareBanner,
  FuneralSiteCard,
  OptionalItemsCard,
  SummaryCard,
} from 'components/Offer';
import * as t from 'data';
import { useTrackingLocation } from 'hooks';
import { Layout } from 'layouts';
import { Box } from 'mymoria-ui/components';
import React from 'react';
import { Page, templates } from 'templates';

const OfferEditPage = () => {
  useTrackingLocation('saved.offer', 'offer-page');

  return (
    <Page {...templates.offerPageTemplate}>
      <Layout
        children={{
          body: (
            <Box px={[1, '2rem']}>
              <AdditionalCostsBanner />
            </Box>
          ),
          mainCol: (
            <>
              <SummaryCard />
              <Message mx={[1, 0]} status="success">
                {t.offer.saved.message}
              </Message>
              <Box px={0} display={['block', null, 'none']}>
                <BasicInformationCard isMobileVersion />
              </Box>
              <BasicItemsCard />
              <FuneralSiteCard />
              <OptionalItemsCard />
            </>
          ),
          stickyCol: <BasicInformationCard />,
        }}
      />
      <CustomerCareBanner />
      <ContactUsFormStickyButton />
    </Page>
  );
};

export default OfferEditPage;
