import { Flex, Row, Text } from 'components';
import { useLocationNearby } from 'hooks';
import { FunnelRadioButtonGroup, FunnelRadioButton } from 'mymoria-ui/components/Button';
import React, { FunctionComponent } from 'react';
import { Place, City } from 'types';

interface Props {
  place: Place;
  onClick: (city: string, lat: number, lon: number) => void;
}

const NearBy: FunctionComponent<Props> = ({ place, onClick }) => {
  const { nearBy } = useLocationNearby();

  const handleCityClick = (cityKey: string) => {
    const { name, lat, lng } = nearBy.find(item => item.name === cityKey) as City;
    onClick && onClick(name, lat, lng);
  };

  return nearBy ? (
    <Row flexWrap="wrap" mt={['1.5rem', '2rem']}>
      <FunnelRadioButtonGroup
        name="place"
        defaultValue={place.city}
        onChange={e => handleCityClick(e.target.value)}
      >
        {nearBy.map(({ name }) => (
          <Flex key={name} width={[1, 1 / 3]} my={1} px={['unset', 1]}>
            <FunnelRadioButton value={name} padding={['1.5rem', '2rem']}>
              <Text fontWeight="bold" fontSize="normal" lineHeight="normal" textAlign="center">
                {name}
              </Text>
            </FunnelRadioButton>
          </Flex>
        ))}
      </FunnelRadioButtonGroup>
    </Row>
  ) : null;
};

export default NearBy;
