import { ReactComponent as PlaceStepIcon } from 'assets/stepIcons/place_step_icon.svg';
import StepTitle from 'containers/Funnel/StepTitle';
import * as t from 'data';
import { useSelector } from 'hooks';
import React from 'react';
import { sprintf } from 'utils';

const PlaceStepTitle = () => {
  const concernType = useSelector(({ proposal }) => proposal.concernType);
  const relationship = useSelector(({ proposal }) => proposal.relationship);

  const title =
    relationship === 'myself'
      ? t.funnel.place.title.provision
      : sprintf(t.funnel.place.title[concernType], {
          relationship:
            t.relationship.nominativeWithPossessivePronounBusinessPerspective[relationship],
        });
  return <StepTitle icon={<PlaceStepIcon />} hint={t.funnel.place.hint} title={title} />;
};

export default PlaceStepTitle;
