import config from 'config';
import { global as t } from 'data';
import { every, mapValues, isEmpty } from 'lodash';
import { Place } from 'types';
import * as yup from 'yup';

const {
  regex: { noLatinChar, mymoriaEmailRule, mymoriaDomain, nameRule },
} = config;

export const salutations = ['sir', 'madame'];
export const concernTypes = ['occured', 'coming'];
export const funeralTypes = ['burial', 'cremation', 'sea', 'tree'];
export const funeralPlans = ['basic', 'high', 'premium'];
export const deceasedLocations = ['home', 'hospital', 'hospice', 'mortician', 'forensic', 'other'];
export const productCategories = [
  'urn',
  'flower',
  'coffin',
  'card',
  'notice',
  'grave_goods',
  'graveCross',
  'keepsake',
];
export const multiProducts = ['flower', 'card', 'grave_goods', 'keepsake'];
export const relationships = [
  'mother',
  'father',
  'grandmother',
  'grandfather',
  'wife',
  'husband',
  'sister',
  'brother',
  'mother_in_law',
  'father_in_law',
  'close_friend_female',
  'close_friend_male',
  'friend_female',
  'friend_male',
  'aunt',
  'uncle',
  'son',
  'daughter',
  'relative_female',
  'relative_male',
  'stepmother',
  'stepfather',
  'not_submitted',
  'myself',
];

export const deceasedLocationSchema = yup.string().oneOf(deceasedLocations);
export const placeSchema = yup
  .mixed()
  .test(
    'place',
    t.validators.required,
    (place: Partial<Place>) =>
      !isEmpty(place) && every(place, value => typeof value !== 'undefined'),
  );

export const offerParamsSchema = yup.object({
  city: yup.string().required(),
  code: yup.number().required(),
  concernType: yup.string().oneOf(concernTypes).required(),
  deceasedLocation: yup.string().when('concernType', {
    is: 'occured',
    otherwise: yup.string().strip(true),
    then: deceasedLocationSchema.required(),
  }),
  funeralPlan: yup.string().oneOf(funeralPlans).required(),
  funeralSite: yup.string(),
  funeralType: yup.string().oneOf(funeralTypes).required(),
  relationship: yup.string().oneOf(relationships).required(),
});

//specialRule regex will check if string contains at least 1 dot and 1 plus
// it allow multiple dots and domain must be @mymoria.de
const validateEmail = (value: string) => mymoriaEmailRule.test(value);
const validateMymoriaDomain = (value: string) => mymoriaDomain.test(value);

export const contactDetailsSchema = yup.object({
  email: yup
    .string()
    .email(t.validators.email)
    .required(t.validators.required)
    .matches(noLatinChar, t.validators.noLatinChar)
    .test('is-cc-email', t.validators.wrongCCEmail, value => {
      if (value) {
        return validateMymoriaDomain(value) ? validateEmail(value) : !!value;
      }
      //ignore this test when value is not provided
      return true;
    }),
  firstname: yup.string().required(t.validators.required).matches(nameRule, t.validators.wrongName),
  lastname: yup.string().required(t.validators.required).matches(nameRule, t.validators.wrongName),
  phone: yup.string().required(t.validators.required),
  salutation: yup.string().oneOf(salutations).required(t.validators.required),
});

export const wundwSchema = yup.object({
  fp: yup.string().required(t.validators.required), // financePartner
  vermittler: yup.string().required(t.validators.required), // salesPersonId
});

export const funnelSchema = yup.object({
  ...mapValues(
    {
      ...offerParamsSchema.fields,
      ...contactDetailsSchema.fields,
      ...wundwSchema.fields,
    },
    (schema: yup.MixedSchema) => schema.notRequired(),
  ),
  deceasedLocation: yup.string().when('concernType', {
    is: 'occured',
    otherwise: yup.string().strip(true),
    then: deceasedLocationSchema,
  }),
  extra: yup.string(),
  skip: yup.boolean(),
  skipIntro: yup.boolean(),
});
