import { Flex, Icon } from 'components';
import config from 'config';
import { useBusinessAccount } from 'hooks';
import { mq, styled } from 'mymoria-ui';
import React from 'react';

const StyledHeader = styled('header')`
  position: relative;
  display: flex;
  height: 60px;
  justify-content: flex-end;

  a {
    display: flex;
    justify-content: center;
    ${mq({ alignItems: ['center', null, 'end'] })}
  }

  ${mq({
    paddingRight: [16, null, null, 119],
  })};

  & svg {
    width: unset;
    ${mq({
      height: [22, 40],
    })}
  }
`;

const OpcoLogo = styled.img({ maxHeight: '55px', width: 'auto' });

const FunnelHeader = () => {
  const { logoUrl: opcoLogo, name: opcoName } = useBusinessAccount();

  const logo = opcoLogo ? (
    <Flex alignItems="end">
      <OpcoLogo src={opcoLogo} alt={opcoName} />
    </Flex>
  ) : (
    <Icon.MymoriaLogo href={config.web.url} />
  );

  return <StyledHeader>{logo}</StyledHeader>;
};

export default FunnelHeader;
