import { groupBy } from 'lodash';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { EntitiesState, Grave, GraveTypesState } from 'types';
import { createAction } from 'typesafe-actions';
import { api } from 'utils';

const graveType = new schema.Entity('graveTypes');

interface FetchGraveTypesAction {
  entities: Pick<EntitiesState, 'graveTypes'>;
  result: GraveTypesState;
}
const graveTypesSchema = {
  burial: [graveType],
  cremation: [graveType],
  sea: [graveType],
  tree: [graveType],
};

const fetchGraveTypesAction = createAction('FETCH_GRAVE_TYPES')<FetchGraveTypesAction>();

export default {
  fetchGraveTypes: fetchGraveTypesAction,
};

export const fetchGraveTypes = () => (dispatch: Dispatch) =>
  api.get<Grave[]>(`/gravetypes/`).then(({ data }) => {
    const graveTypes = groupBy(data, 'funeralType');

    return dispatch(fetchGraveTypesAction(normalize(graveTypes, graveTypesSchema)));
  });
