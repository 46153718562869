import { Col, Markdown, Row, Text, Box } from 'components';
import { global } from 'data';
import { styled } from 'mymoria-ui';
import { SpaceProps } from 'mymoria-ui/interfaces';
import React from 'react';

interface NotFoundProps extends SpaceProps {
  title?: string;
  description?: string;
}

const TitleContainer = styled(Box)(
  ({ theme: { colors } }) => `
    border-bottom: 2px solid ${colors.inactive};
  `,
);

const NotFound = ({
  title = global.notFound,
  description = global.notFoundMessage,
  ...spaceProps
}: NotFoundProps) => (
  <Row {...spaceProps} justifyContent="center">
    <Col width={[1, 5 / 6, 2 / 3]}>
      <TitleContainer p="small" mt={3} mb={6} mx="auto" width="fit-content">
        <Text as="h1">{title}</Text>
      </TitleContainer>
      <Markdown textAlign="center" source={description} />
    </Col>
  </Row>
);

export default NotFound;
