import { Button, ButtonProps } from 'mymoria-ui/components/Button';
import { styled, mq } from 'mymoria-ui/utils';
import React from 'react';

const StyledButton = styled(Button)(mq({ display: ['block', 'none'], lineHeight: '50px' }));

interface Props extends ButtonProps {
  label: string;
  onClick: () => void;
}

const MobileButton = ({ label, onClick, ...props }: Props) => (
  <StyledButton outline block onClick={onClick} {...props}>
    {label}
  </StyledButton>
);

export default MobileButton;
