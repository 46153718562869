import { funeralSiteAPI, productAPI, proposalAPI, serviceAPI } from 'api';
import config from 'config';
import { getOptionalDefaultProductId } from 'helpers';
import { STEP_TO_TRACKING_NAME } from 'helpers/funnelSteps';
import { flatten, isEmpty, pick, round, values, omit } from 'lodash';
import PlaceSearchAPI from 'mymoria-ui/components/Form/PlaceSearch/api';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { parseItems } from 'store/helpers';
import {
  BasicInformation,
  ContactDetails,
  ContactUsDetails,
  EntitiesState,
  GetState,
  OfferItemType,
  Proposal,
  Service,
  FuneralPlan,
  ProductCategory,
  FunnelAdditionalData,
  FunnelStep,
  FUNNEL_STEPS,
} from 'types';
import { createAction } from 'typesafe-actions';
import { api, getAxiosData } from 'utils';
import { trackFunnelData } from './tracking';

const {
  api: { timeout },
} = config;

const product = new schema.Entity(
  'products',
  {},
  { idAttribute: 'sku', processStrategy: parseItems },
);
const service = new schema.Entity(
  'services',
  {},
  { idAttribute: 'sku', processStrategy: parseItems },
);

const funeralSite = new schema.Entity('funeralSites');
const proposalSchema = {
  products: {
    basic: [product],
    defaults: {
      basic: [product],
      optional: [product],
    },
    optional: [product],
  },
  proposal: {
    basicProducts: [product],
    basicServices: [service],
    funeralSite,
    optionalProducts: [product],
    optionalServices: [service],
  },
  services: {
    basic: [service],
    optional: [service],
  },
};

interface ProposalAction {
  entities: Pick<EntitiesState, 'products' | 'services' | 'funeralSites'>;
  result: {
    proposal: Proposal;
    products: {
      basic: string[];
      optional: string[];
      defaults: {
        basic: string[];
        optional: string[];
      };
    };
    services: {
      basic: string[];
      optional: string[];
    };
  };
}

interface ChangeOfferItem {
  addedId: string;
  removedId: string;
  price: number;
}

interface SwitchSeaBasicService {
  addedServices: string[];
  removedServices: string[];
  price: number;
  funeralPlan: FuneralPlan;
  graveType: string;
  funeralSite?: string;
}

interface OfferItemAction {
  /*
   * Offer item id to be set.
   * */
  id: string;

  /*
   * Type of the offer item (can be products or services).
   * */
  type: OfferItemType;

  /*
   * Recalculated total gross price of the offer.
   * */
  price: number;

  /*
   * Id of the default product, used when de-selecting one of picked from catalogue.
   * */
  defaultProductId?: string;

  /*
   * enable adding multi offer item.
   * */
  isMulti?: boolean;
}

interface LockOfferItemAction {
  /*
   * Offer change state is locked.
   * */
  isOfferLocked: boolean;
}

interface OmitAppendFunnelStepAction {
  step: FunnelStep;
  action: 'OMIT' | 'APPEND';
}

const createProposalAction = createAction('CREATE_PROPOSAL')<ProposalAction>();
const fetchProposalAction = createAction('FETCH_PROPOSAL')<ProposalAction>();
const fetchProposalAdditionalDataAction = createAction(
  'FETCH_PROPOSAL_ADDITIONAL_DATA',
)<ProposalAction>();
const changeContactInformationAction = createAction('CHANGE_CONTACT_INFORMATION')<ContactDetails>();
const changeContactUsInformationAction = createAction(
  'CHANGE_CONTACTUS_INFORMATION',
)<ContactUsDetails>();
const changeBasicInformationAction = createAction('CHANGE_BASIC_INFORMATION')<ProposalAction>();
const updateProposalAction = createAction('PROPOSAL_UPDATE')<Partial<Proposal>>();
const acceptProposalAction =
  createAction('ACCEPT_PROPOSAL')<Pick<Proposal, 'state' | 'acceptance'>>();
const setFunnelDataAction =
  createAction('SET_FUNNEL_DATA')<Partial<Proposal & FunnelAdditionalData>>();
const lockOfferItemAction = createAction('LOCK_OFFER_ITEM')<LockOfferItemAction>();
const addOfferItemAction = createAction('ADD_OFFER_ITEM')<OfferItemAction>();
const removeOfferItemAction = createAction('REMOVE_OFFER_ITEM')<OfferItemAction>();
const removeOfferMultiSelectedItemAction =
  createAction('REMOVE_OFFER_MULTI_ITEM')<OfferItemAction>();
const changeBasicServiceAction = createAction('CHANGE_BASIC_SERVICE')<ChangeOfferItem>();
const changeProductAction = createAction('CHANGE_PRODUCT')<ChangeOfferItem>();
const changeFuneralSiteAction =
  createAction('CHANGE_FUNERAL_SITE')<Pick<Proposal, 'funeralSite' | 'graveType' | 'price'>>();
const switchSeaBasicServiceAction = createAction('SWITCH_BASIC_SERVICE')<SwitchSeaBasicService>();
const setFormSubmissionTimeAction = createAction('SET_FORM_SUBMISSION_TIME')<{ time: string }>();
const omitAppendFunnelStepAction =
  createAction('OMIT_APPEND_FUNNEL_STEP')<OmitAppendFunnelStepAction>();

export default {
  acceptProposal: acceptProposalAction,
  addOfferItem: addOfferItemAction,
  changeBasicInformation: changeBasicInformationAction,
  changeBasicService: changeBasicServiceAction,
  changeContactInformation: changeContactInformationAction,
  changeContactUsInformation: changeContactUsInformationAction,
  changeFuneralSite: changeFuneralSiteAction,
  changeProduct: changeProductAction,
  createProposal: createProposalAction,
  fetchProposal: fetchProposalAction,
  fetchProposalAdditionalData: fetchProposalAdditionalDataAction,
  lockOfferItem: lockOfferItemAction,
  omitAppendFunnelStep: omitAppendFunnelStepAction,
  removeOfferItem: removeOfferItemAction,
  removeOfferMultiSelectedItem: removeOfferMultiSelectedItemAction,
  setFormSubmissionTime: setFormSubmissionTimeAction,
  setFunnelData: setFunnelDataAction,
  switchSeaBasicService: switchSeaBasicServiceAction,
  update: updateProposalAction,
};

export const funnelFields = [
  'concernType',
  'relationship',
  'funeralType',
  'funeralPlan',
  'funeralSite',
  'code',
  'city',
  'country',
  'lat',
  'lon',
  'deceasedLocation',
  'graveType',
  'funeralSitePlace',
] as const;

export const acceptProposal = () => async (dispatch: Dispatch, getState: GetState) => {
  const {
    proposal: { id },
  } = getState();

  return proposalAPI
    .accept(id)
    .then(({ data }) => dispatch(acceptProposalAction(pick(data, ['state', 'acceptance']))));
};

export const setFunnelData = setFunnelDataAction;
const placeSearchApi = PlaceSearchAPI.getInstance(config);

export const addOfferItem =
  (id: string, type: OfferItemType) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      entities,
      proposal: { id: proposalId, state, price, isOfferLocked },
    } = getState();

    if (isOfferLocked) {
      return;
    }

    const itemPrice = entities[type][id]?.price;
    const totalPrice = price + round(itemPrice, 2);

    if (state === 'postcheckout') {
      await Promise.resolve(dispatch(lockOfferItemAction({ isOfferLocked: true })));

      try {
        const {
          data: { price },
        } = await proposalAPI.addItem(proposalId, type, { sku: id });

        await Promise.resolve(dispatch(addOfferItemAction({ id, price, type })));
        return dispatch(lockOfferItemAction({ isOfferLocked: false }));
      } catch (e: any) {
        dispatch(lockOfferItemAction({ isOfferLocked: false }));
        throw new Error(e);
      }
    }

    return dispatch(addOfferItemAction({ id, price: totalPrice, type }));
  };

export const removeOfferMultiSelectedItem =
  (id: string, type: OfferItemType) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      entities,
      proposal: { id: proposalId, state, price, isOfferLocked },
    } = getState();

    if (isOfferLocked) {
      return;
    }

    const itemPrice = entities[type][id]?.price;
    const totalPrice = price - round(itemPrice, 2);

    if (state === 'postcheckout') {
      await Promise.resolve(dispatch(lockOfferItemAction({ isOfferLocked: true })));

      try {
        const {
          data: { price },
        } = await proposalAPI.deleteItem(proposalId, type, { sku: id });

        await Promise.resolve(
          dispatch(
            removeOfferMultiSelectedItemAction({
              id,
              price,
              type,
            }),
          ),
        );
        return dispatch(lockOfferItemAction({ isOfferLocked: false }));
      } catch (e: any) {
        return dispatch(lockOfferItemAction({ isOfferLocked: false }));
      }
    }

    return dispatch(
      removeOfferMultiSelectedItemAction({
        id,
        price: totalPrice,
        type,
      }),
    );
  };

export const removeOfferItem =
  (id: string, type: OfferItemType, category?: ProductCategory) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      entities,
      products,
      proposal: { id: proposalId, state, price, isOfferLocked },
    } = getState();

    if (isOfferLocked) {
      return;
    }

    const itemPrice = entities[type][id]?.price;
    const totalPrice = price - round(itemPrice, 2);

    let defaultProductId: string | undefined = undefined;
    if (type === 'products') {
      defaultProductId = getOptionalDefaultProductId(entities.products, products, category);
    }

    if (state === 'postcheckout') {
      await Promise.resolve(dispatch(lockOfferItemAction({ isOfferLocked: true })));

      try {
        const {
          data: { price },
        } = await proposalAPI.deleteItem(proposalId, type, { sku: id });

        await Promise.resolve(
          dispatch(
            removeOfferItemAction({
              id,
              price,
              type,
              ...(defaultProductId !== id ? { defaultProductId } : undefined),
            }),
          ),
        );
        return dispatch(lockOfferItemAction({ isOfferLocked: false }));
      } catch (e: any) {
        return dispatch(lockOfferItemAction({ isOfferLocked: false }));
      }
    }

    return dispatch(
      removeOfferItemAction({
        id,
        price: totalPrice,
        type,
        ...(defaultProductId !== id ? { defaultProductId } : undefined),
      }),
    );
  };

export const changeProduct =
  (addedId: string, removedId: string) => (dispatch: Dispatch, getState: GetState) => {
    const { proposal } = getState();

    return proposalAPI
      .changeItem(proposal.id, 'products', { addedId, removedId })
      .then(({ data: { price } }) => dispatch(changeProductAction({ addedId, price, removedId })));
  };

export const addProduct =
  (id: string, isMulti: boolean) => (dispatch: Dispatch, getState: GetState) => {
    const { proposal } = getState();

    return proposalAPI
      .addItem(proposal.id, 'products', { sku: id })
      .then(({ data: { price } }) =>
        dispatch(addOfferItemAction({ id, isMulti, price, type: 'products' })),
      );
  };

export const changeBasicService =
  (addedService: Service, removedService: Service) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { proposal } = getState();

    if (proposal.isOfferLocked) return;

    await Promise.resolve(dispatch(lockOfferItemAction({ isOfferLocked: true })));

    try {
      const {
        data: { price },
      } = await proposalAPI.changeItem(proposal.id, 'services', {
        addedId: addedService.id,
        removedId: removedService.id,
      });

      await Promise.resolve(
        dispatch(
          changeBasicServiceAction({
            addedId: addedService.id,
            price,
            removedId: removedService.id,
          }),
        ),
      );
      return dispatch(lockOfferItemAction({ isOfferLocked: false }));
    } catch (e: any) {
      return dispatch(lockOfferItemAction({ isOfferLocked: false }));
    }
  };

export const changeContactInformation =
  (data: ContactDetails) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { id },
    } = getState();

    return proposalAPI
      .changeContactInformation(id, data)
      .then(() => dispatch(changeContactInformationAction(data)));
  };

export const changeContactUsInformation =
  (data: ContactUsDetails) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { id },
    } = getState();

    return proposalAPI
      .changeContactUsInformation(id, data)
      .then(() => dispatch(changeContactUsInformationAction(data)));
  };

export const updateProposal =
  (payload: Partial<Proposal>) => (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { id },
    } = getState();

    return api
      .put<Proposal>(`/proposals/${id}`, { proposal: { ...payload } })
      .then(({ data }) => dispatch(updateProposalAction({ ...payload, ...data })));
  };

export const changeBasicInformation =
  (data: BasicInformation) => (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { id },
    } = getState();

    return proposalAPI
      .changeBasicInformation(id, data)
      .then(
        ({
          proposal: { products: selectedProducts, services: selectedServices, ...proposal },
          products: allProducts,
          services: allServices,
        }) => {
          const products = productAPI.parse(selectedProducts, allProducts);
          const services = serviceAPI.parse(selectedServices, allServices);

          return dispatch(
            changeBasicInformationAction(
              normalize(
                {
                  products: products.all,
                  proposal: {
                    ...proposal,
                    funeralSite: proposal.funeralSite || '',
                    graveType: proposal.graveType || '',
                    ...products.selected,
                    ...services.selected,
                    extras: [],
                  },
                  services: services.all,
                },
                proposalSchema,
              ),
            ),
          );
        },
      );
  };

export const changeFuneralSite =
  (funeralSiteId: string, newGraveType: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { id },
    } = getState();

    return proposalAPI
      .changeFuneralSite(id, { funeralSiteId, graveType: newGraveType })
      .then(({ data: { price } }) =>
        dispatch(
          changeFuneralSiteAction({
            funeralSite: funeralSiteId,
            graveType: newGraveType,
            price,
          }),
        ),
      );
  };

export const setFormSubmissionTime = (time: string) => (dispatch: Dispatch) =>
  dispatch(setFormSubmissionTimeAction({ time }));

export const fetchProposalAdditionalData = () => async (dispatch: Dispatch, getState: GetState) => {
  const {
    proposal: {
      city,
      code,
      country,
      lat,
      lon,
      funeralSite,
      funeralPlan,
      funeralType,
      basicProducts = [],
      optionalProducts = [],
      basicServices = [],
      optionalServices = [],
    },
  } = getState();
  const defaultGraveType = config.defaultGraveTypesRegistration[funeralType][funeralPlan];

  // in case of funnel skip mechanism , code might be missing but code is needed for fetching products and services
  const placeDetails = code
    ? { city, code, country, lat, lon }
    : await placeSearchApi
        .fetchAutoCompletions(city)
        .then(([[name, id]]) => placeSearchApi.fetchPlaceDetails(id, name));

  const pricebookId = await api
    .get<string>('/salesforce/pricebook', { params: { code: placeDetails.code } })
    .then(getAxiosData);

  return Promise.all([
    isEmpty(basicProducts)
      ? productAPI
          .fetchDefault(pricebookId, funeralPlan, funeralType)
          .then(({ basic, optional }) => productAPI.parse(basic, [...basic, ...optional]))
      : productAPI.fetchSelected({
          SKUs: [...basicProducts, ...optionalProducts],
          plan: funeralPlan,
          pricebookId,
          type: funeralType,
        }),
    isEmpty(basicServices)
      ? serviceAPI
          .fetchDefault(pricebookId, funeralPlan, funeralType)
          .then(({ basic, optional }) => serviceAPI.parse(basic, [...basic, ...optional]))
      : serviceAPI.fetchSelected({
          SKUs: [...basicServices, ...optionalServices],
          plan: funeralPlan,
          pricebookId,
          type: funeralType,
        }),
    isEmpty(funeralSite)
      ? funeralSiteAPI.fetchDefault(funeralPlan, funeralType)
      : funeralSiteAPI.fetch(funeralSite, defaultGraveType).then(funeralSiteEntity => ({
          funeralSite: funeralSiteEntity,
          graveType:
            typeof funeralSiteEntity.fees.prices[defaultGraveType] === 'undefined'
              ? undefined
              : defaultGraveType,
        })),
  ]).then(([products, services, { funeralSite, graveType }]) =>
    dispatch(
      fetchProposalAdditionalDataAction(
        normalize(
          {
            products: products.all,
            proposal: {
              funeralSite,
              graveType,
              ...placeDetails,
              ...products.selected,
              ...services.selected,
              price: proposalAPI.calculatePrice(
                flatten(values(products.selected)),
                flatten(values(services.selected)),
                funeralSite,
              ),
              salesforce: {
                pricebookId,
              },
            },
            services: services.all,
          },
          proposalSchema,
        ),
      ),
    ),
  );
};

export const createProposal =
  (contactDetails: ContactDetails, pricebookId: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      tracking,
      proposal: {
        basicProducts,
        optionalProducts,
        basicServices,
        optionalServices,
        formSubmissionTime,
        extra,
        ...proposal
      },
    } = getState();

    // Delay another request in case the previous one haven't finished yet
    const now = new Date().getTime();
    if (formSubmissionTime) {
      const timeDifference = now - new Date(formSubmissionTime).getTime();
      if (timeDifference < timeout) {
        await new Promise(resolve => setTimeout(resolve, timeout - timeDifference));
      }
    }
    dispatch(setFormSubmissionTimeAction({ time: new Date().toISOString() }));

    return proposalAPI
      .save({
        ...contactDetails,
        clientId: tracking.clientId,
        landingPageUrl: tracking.landingPageUrl,
        oppLocation: tracking.oppLocation,
        sessionID: tracking.sessionID,
        userID: tracking.userID,
        ...pick(proposal, funnelFields),
        pricebookId,
        products: [...basicProducts, ...optionalProducts],
        services: [...basicServices, ...optionalServices],
        ...(extra ? { extras: [extra] } : {}),
      })
      .then(({ data: { offerId } }) => {
        return proposalAPI.fetch(offerId).then(({ data: { services, products, ...proposal } }) => {
          const [basicProducts, optionalProducts] = productAPI.split(products);
          const [basicServices, optionalServices] = serviceAPI.split(services);

          return dispatch(
            createProposalAction(
              normalize(
                {
                  proposal: {
                    ...proposal,
                    basicProducts,
                    basicServices,
                    optionalProducts,
                    optionalServices,
                  },
                },
                proposalSchema,
              ),
            ),
          );
        });
      });
  };

export const fetchProposal =
  (id: string = '') =>
  async (dispatch: Dispatch) =>
    proposalAPI.fetch(id).then(async ({ data: { products, services, ...proposal } }) => {
      const {
        funeralPlan,
        funeralType,
        customizations,
        salesforce: { pricebookId },
      } = proposal;

      const [defaultProducts, defaultServices] = await Promise.all([
        productAPI.fetchDefault(pricebookId, funeralPlan, funeralType),
        serviceAPI.fetchDefault(pricebookId, funeralPlan, funeralType),
      ]);
      const parsedProducts = productAPI.parseProductsAlongStore(
        products,
        defaultProducts,
        customizations,
      );
      const parsedServices = serviceAPI.parseServicesAlongStore(
        services,
        defaultServices,
        customizations,
      );

      // If proposal doesn't have items show it in customized state
      const hasNoItems = isEmpty(products) && isEmpty(services) && isEmpty(proposal.extras);

      return dispatch(
        fetchProposalAction(
          normalize(
            {
              products: parsedProducts.all,
              proposal: {
                ...proposal,
                ...(hasNoItems ? { state: 'customized' } : {}),
                ...omit(proposal.account, 'id'),
                ...parsedProducts.selected,
                ...parsedServices.selected,
              },
              services: parsedServices.all,
            },
            proposalSchema,
          ),
        ),
      );
    });

export const switchSeaBasicService =
  (anonymous: boolean, funeralSite?: string) => async (dispatch: Dispatch, getState: GetState) => {
    const { proposal } = getState();
    if (proposal.isOfferLocked) return;
    await Promise.resolve(dispatch(lockOfferItemAction({ isOfferLocked: true })));

    try {
      const {
        data: { price, removedItems, addedItems },
      } = await proposalAPI.switchBasicService(proposal.id, { anonymous, funeralSite });

      await Promise.resolve(
        dispatch(
          switchSeaBasicServiceAction({
            addedServices: addedItems.services,
            funeralPlan: anonymous ? 'basic' : 'high',
            funeralSite: funeralSite || proposal.funeralSite,
            graveType: anonymous ? 'SU' : 'SB',
            price,
            removedServices: removedItems.services,
          }),
        ),
      );
      return dispatch(lockOfferItemAction({ isOfferLocked: false }));
    } catch (e: any) {
      return dispatch(lockOfferItemAction({ isOfferLocked: false }));
    }
  };

export const omitAppendFunnelStep =
  ({ step, action }: OmitAppendFunnelStepAction) =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      proposal: { steps },
    } = getState();
    let skippedSteps = FUNNEL_STEPS.filter(id => !steps.includes(id));

    if (action === 'APPEND') {
      if (steps.includes(step)) {
        return;
      } else {
        dispatch(omitAppendFunnelStepAction({ action, step }));
        dispatch(
          trackFunnelData({
            skipped_steps: skippedSteps
              .filter(id => id !== step)
              .map(id => STEP_TO_TRACKING_NAME[id]),
          }),
        );
      }
    }

    if (action === 'OMIT') {
      if (!steps.includes(step)) {
        return;
      } else {
        dispatch(omitAppendFunnelStepAction({ action, step }));
        dispatch(
          trackFunnelData({
            skipped_steps: [...skippedSteps, step].map(id => STEP_TO_TRACKING_NAME[id]),
          }),
        );
      }
    }
  };
