import { styled, css, mq } from 'mymoria-ui';
import { Box, Text, StatusTag } from 'mymoria-ui/components';
import { transparentize } from 'polished';
import React, { FunctionComponent } from 'react';

interface Props {
  onClick: () => void;
  title: string;
  tag?: string;
  picture: string;
  staticIcon?: boolean;
  selected?: boolean;
}

const Item = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 28px;
    background-color: ${colors.secondary};

    &:hover {
      background-color: ${transparentize(0.95, colors.black)};
    }
  `,
);

const Circle = styled.div<{ staticIcon?: boolean; selected: boolean }>(
  ({ staticIcon, selected, theme: { colors } }) => css`
    border-radius: 50%;
    background-color: ${selected ? colors.primary : colors.gray};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: ${staticIcon ? 'unset' : '50%'};

      ${mq({
        height: [staticIcon ? '16px' : '30px', staticIcon ? '32px' : '60px'],
        width: [staticIcon ? '12px' : '30px', staticIcon ? '23px' : '60px'],
      })};
    }

    ${mq({
      height: ['30px', '60px'],
      width: ['30px', '60px'],
    })};
  `,
);

const StyledStatusTag = styled(StatusTag)`
  width: 100%;
  box-sizing: border-box;
  text-align: center;

  ${mq({
    display: ['none', 'block'],
  })};
`;

const FuneralSiteItem: FunctionComponent<Props> = ({
  onClick,
  title,
  tag,
  picture,
  staticIcon,
  selected = false,
}) => (
  <Item onClick={onClick}>
    <Circle staticIcon={staticIcon} selected={selected}>
      <img src={picture} alt={title} />
    </Circle>
    <Box ml={3}>
      <Text fontSize={['normal', 'medium']} fontWeight="bold">
        {title}
      </Text>
      {tag && (
        <StyledStatusTag mt={1} status="recommended">
          {tag}
        </StyledStatusTag>
      )}
    </Box>
  </Item>
);

export default FuneralSiteItem;
