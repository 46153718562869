import {
  CONSENT_UPDATE,
  setTrackingCookies,
  setTrackingParams,
  trackUserInteraction,
  trackUrlParams,
} from 'actions/tracking';
import { CookiesConsent } from 'components';
import config from 'config';
import { global as t } from 'data';
import { getRootDomain, parseQueryParams } from 'helpers';
import { useDispatch } from 'hooks';
import { camelCase, head, isEmpty, map, mapKeys, kebabCase } from 'lodash';
import moment from 'moment';
import { useCMS } from 'mymoria-ui/hooks';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { matchPath, useLocation } from 'react-router-dom';
import { CMSCookieConsent, TrackingCookies, Cookie } from 'types';
import { getLastURLPath, sprintf } from 'utils';
import { categoriesMap } from './Catalog/utils';

const { cookieSiteSlug, googleOptimizeId, googleOptimizeUrl } = config;

const Head = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [{ data }] = useCMS<CMSCookieConsent[]>(
    `/CookiePopup?filter[Status]=publish&filter[Origins]=${config.cms.origin}&simple=1`,
  );
  const [cookies, setCookie] = useCookies();
  const [shouldShowCookieConsent, setShouldShowCookieConsent] = useState(false);
  const [optionalCookiesItems, setOptionalCookiesItems] = useState<Cookie[]>([]);

  const cookiesConsentData = head(data);

  useEffect(() => {
    if (cookiesConsentData) {
      const optionalCookiesObj = cookiesConsentData.OptionalCookies.map(({ value }) => {
        const cookieName = kebabCase(value.Name);
        const checkedValue = cookies[`${cookieSiteSlug}${cookieName}`];

        return {
          ...value,
          checked: checkedValue ? checkedValue === 'true' : true,
        };
      });

      setOptionalCookiesItems(optionalCookiesObj);
    }
  }, [cookiesConsentData, cookies]);

  const { gatsbyConsentMandatory } = cookies;
  const isCookiesAccepted = gatsbyConsentMandatory === 'true';

  useEffect(() => {
    if (!isCookiesAccepted) {
      setShouldShowCookieConsent(true);
    }
  }, [isCookiesAccepted]);

  useEffect(() => {
    // ga_clientId get notified once ga clientId is created at GA/GTM serverside
    const handleGaClientId = (event: CustomEventInit) =>
      dispatch(setTrackingCookies({ clientId: event?.detail?.clientId }));

    window.addEventListener('ga_clientId', handleGaClientId, { once: true });

    return () => document.removeEventListener('ga_clientId', handleGaClientId);
  }, [dispatch]);

  useEffect(() => {
    const trackingParams = mapKeys(
      parseQueryParams(search, config.trackingParams, ['landingPageUrl']),
      (v, k) => camelCase(k),
    );
    const trackingUrlParams = mapKeys(
      parseQueryParams(search, config.trackingParams, ['landingPageUrl']),
      (v, k) => k,
    );

    if (!isEmpty(trackingParams)) {
      dispatch(setTrackingParams(trackingParams));
      dispatch(trackUrlParams(trackingUrlParams));

      if (trackingUrlParams['landingPageUrl']) {
        dispatch(trackUserInteraction('Landing Page Url', trackingUrlParams['landingPageUrl']));
      }
    }
  }, [dispatch, search]);

  useEffect(() => {
    const trackingCookies = {} as TrackingCookies;

    config.trackingCookies.forEach(({ cookieName, fieldName }) => {
      trackingCookies[fieldName] = cookies[cookieName];
    });

    dispatch(setTrackingCookies(trackingCookies));
  }, [cookies, dispatch]);

  const handleCookieAccept = (cookieValue: boolean) => {
    let consent: { [key: string]: boolean | string } = {};

    const options = {
      domain: getRootDomain(),
      expires: moment().add(1, 'year').toDate(),
      path: '/',
      sameSite: true,
    };

    setCookie('gatsbyConsentMandatory', true, options);

    optionalCookiesItems.map(cookieItem => {
      let customCookieValue = cookieValue !== undefined ? cookieValue : cookieItem.checked;

      consent[kebabCase(cookieItem.Name)] = customCookieValue;

      return setCookie(
        `${cookieSiteSlug}${kebabCase(cookieItem.Name)}`,
        customCookieValue,
        options,
      );
    });

    if (window?.dataLayer) {
      window.dataLayer.push({ consent, event: CONSENT_UPDATE });
    }

    setShouldShowCookieConsent(false);
  };

  return (
    <>
      <Helmet
        {...(!!matchPath(pathname, { path: '/katalog' }) && {
          title: sprintf(config.app.head.productCatalog, {
            name: t.productCategory[categoriesMap[getLastURLPath(pathname)]!] || '',
          }),
        })}
        {...config.app.head}
      >
        {googleOptimizeId && <script src={googleOptimizeUrl} />}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/animejs/2.0.2/anime.min.js" />
      </Helmet>

      {shouldShowCookieConsent && cookiesConsentData && (
        <CookiesConsent
          title={cookiesConsentData.Headline}
          description={cookiesConsentData.MoreInfo}
          shortDescription={cookiesConsentData.Copy}
          lessInfoLabel={t.cookiesConsentModal.lessInfoLabel}
          moreInfoLabel={t.cookiesConsentModal.moreInfoLabel}
          submitAllLabel={t.cookiesConsentModal.submitAll}
          essentialCookiesTitle={t.cookiesConsentModal.essentialCookiesTitle}
          settingsText={t.cookiesConsentModal.settingsText}
          settingsButton={t.cookiesConsentModal.settingsButton}
          settingsTextEnd={t.cookiesConsentModal.settingsTextEnd}
          submitSelectedLabel={t.cookiesConsentModal.submitSelected}
          mandatoryCookiesTitle={t.cookiesConsentModal.mandatoryCookiesTitle}
          mandatoryCookiesDescription={t.cookiesConsentModal.mandatoryCookiesDescription}
          mandatoryCookies={map(cookiesConsentData.MandatoryCookies, ({ value }) => value)}
          optionalCookiesTitle={t.cookiesConsentModal.optionalCookiesTitle}
          optionalCookiesDescription={t.cookiesConsentModal.optionalCookiesDescription}
          optionalCookies={optionalCookiesItems}
          setOptionalCookies={setOptionalCookiesItems}
          detailPageInfo={cookiesConsentData.DetailPageInfo}
          links={t.cookiesConsentModal.links}
          showDisableLabel={t.cookiesConsentModal.disableCookiesLabel}
          onSubmit={handleCookieAccept}
        />
      )}
    </>
  );
};

export default Head;
