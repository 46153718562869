import { styled } from 'mymoria-ui';
import React from 'react';

interface PrefetchImageProps {
  alt: string;
  src: string;
}

const StyledImage = styled.img({
  display: 'none',
});

const PrefetchImage = ({ alt, src }: PrefetchImageProps) => <StyledImage alt={alt} src={src} />;

export default PrefetchImage;
