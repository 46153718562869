import { Row, Col, Text } from 'components';
import { styled, css, mq } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';

interface ReviewersQuotesProps {
  label: string;
  quote?: string;
  reviewer?: string;
  icon?: string;
  className?: string;
}

const Container = styled(Row)(
  ({ theme: { colors } }) => css`
    background: ${colors.secondary};
    flex-grow: 1;
  `,
  mq({
    display: ['none', 'flex'],
  }),
);

const CircledIcon = styled.img(
  ({ theme: { colors, space } }) => `
  border-radius: 50%;
  margin-top: ${space[1]};
  margin-right:${space[8]};
  min-width: 117px;
  min-height: 117px;
  width: 117px;
  height: 117px;
  background: ${colors.backgrounds.tertiary};
`,
);

const ReviewersQuotes: FunctionComponent<ReviewersQuotesProps> = ({
  label,
  quote,
  reviewer,
  icon,
  children,
  className,
}) => {
  return (
    <Container className={className}>
      <Col width={2 / 8} display="flex" justifyContent="flex-end">
        <Text
          fontFamily="secondary"
          fontWeight="bold"
          fontSize="1.375rem" // TODO: Add new font size to theme
          textAlign="right"
          mt="large"
          mb="small"
          whiteSpace="pre-line"
        >
          {label}
        </Text>
      </Col>
      <Col width={[3 / 4, null, 4 / 8]} mb="small">
        {children}
        <Row>
          {icon && <CircledIcon src={icon} alt="icon" />}
          {(quote || reviewer) && (
            <Col>
              <Text fontWeight="medium" textAlign="center" mt="1.25rem">
                {quote}
              </Text>
              <Text
                fontWeight="medium"
                textAlign="center"
                letterSpacing={0.39} // TODO: Consider add letter spacing values into theme
                mt={1}
              >
                {reviewer}
              </Text>
            </Col>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default ReviewersQuotes;
